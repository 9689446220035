export const globalSearchEnLangClient = {
  blabDefAddItemActionBtnTooltip: 'Create {{itemName}}',
  clearAll: `Clear all`,
  clearTooltip: 'Clear search',
  dialog: {
    clearSearchHistory: {
      confirmButtonLabel: `Clear`,
    },
    message: `This can’t be undone and you'll remove all your recent searches.`,
    title: `Clear all recent searches?`,
  },
  emptySearchLabel: `Try searching for records, people, or keywords`,
  noResults: `No results match your search`,
  notifications: {
    clearSearchHistorySuccess: `Cleared search history`,
  },
  placeholder: `Search Tape`,
  recentSearchLabel: `Recent searches`,
  removeHistoryItemTooltip: 'Remove recent search',
  showAllResultsFor: `Show all results for "{{searchQuery}}"`,
} as const;
