export const findMyOrganizationsEnLangClient = {
  enterEmail: {
    btnLabel: 'Next',
    inputPlaceholder: 'you@example.com',
    invalidEmailError: 'Please enter a valid email address',
    subtitle: 'Enter the email you use for work',
    title: 'Let’s find your Tape organization',
  },
  linkInfo: {
    linkLabel: 'Try another email address',
    subtitle: `Click on the link to confirm your email address {{email}} and get started. If you didn't receive an email, please also check your spam folder.`,
    title: 'We sent you a magic sign in link!',
  },
  pick: {
    createNewOrgLabel: 'Create a new organization',
    launchBtnLabel: 'Sign in',
    pickerBoxTitle: 'Your organizations',
    subtitle: 'Select the organization you want to sign in',
    title: 'You’re already a member of these Tape organizations',
    tryOtherEmailLabel: 'Try another email address',
  },
};
