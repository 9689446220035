import { Store } from '@ngrx/store';
import { I18n, I18nObservable, LANGUAGES_DICT, RUNTIME_I18N, RUNTIME_LANGUAGE } from '@t5s/client/util/i18n-token';
import { distinctUntilChangedSerialize } from '@t5s/shared/util/rxjs';
import { selectI18n } from '@t5s/web-client/store/internationalization';
import { combineLatest, Observable } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, tap } from 'rxjs/operators';
import { setGlobalI18n } from '@t5s/client/i18n/common';

export const RuntimeLanguageStoreProxy = {
  provide: RUNTIME_LANGUAGE,
  useFactory: (store$: Store) =>
    store$.select(selectI18n).pipe(
      map((i18n) => i18n.language),
      distinctUntilChanged(),
      shareReplay(1),
    ),
  deps: [Store],
};

export const RuntimeI18nStoreProxy = {
  provide: I18nObservable,
  useFactory: (store$: Store, languagesDict$: Observable<any>): Observable<I18n> => {
    const i18n$ = store$.select(selectI18n).pipe(distinctUntilChangedSerialize());

    return combineLatest([i18n$, languagesDict$]).pipe(
      map(([i18n, languagesDict]): I18n => ({ ...i18n, i18n, languagesDict })),
      tap((i18n) => {
        setGlobalI18n(i18n);
      }),
      shareReplay(1),
    );
  },
  deps: [Store, LANGUAGES_DICT],
};

export const RuntimeI18nLegacyProxy = {
  provide: RUNTIME_I18N,
  useExisting: I18nObservable,
};
