import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NetworkIsConnectedObservable } from '@t5s/mobile-client/provider-token/network-status';
import { Observable, throwError, timer } from 'rxjs';
import { mergeMap, retryWhen, withLatestFrom } from 'rxjs/operators';

/* eslint-disable no-console */

const NUM_RETRIES = 3;
const BASE_DELAY_MS = 50;

function checkIsEligibleForRetry(error: unknown): boolean {
  if (!(error instanceof HttpErrorResponse)) {
    return false;
  }

  // HTTP status code 0 and 503 are considered network failures
  return error.status !== 0 && error.status !== 503;
}

export const ifConnectedRetryExponentialDelayStrategy =
  (networkConnected$: NetworkIsConnectedObservable) => (attempts: Observable<unknown>) => {
    return attempts.pipe(
      withLatestFrom(networkConnected$),
      mergeMap(([error, isConnected], i) => {
        const retryAttempt = i + 1;

        const eligableForRetry = checkIsEligibleForRetry(error);

        // if this is not a network error or device is entirely offline, do not retry
        if (!isConnected || eligableForRetry) {
          return throwError(error);
        }

        // if maximum number of retries have been met
        if (retryAttempt > NUM_RETRIES) {
          console.log('[Network retry] Retries exceeded, request failed.');

          return throwError(error);
        }
        const delay = Math.pow(2, retryAttempt - 1) * BASE_DELAY_MS;

        console.log(`[Network retry] Attempt ${retryAttempt} (of ${NUM_RETRIES}) failed: retrying again in ${delay}ms`);

        // retry after delay
        return timer(delay);
      }),
    );
  };

@Injectable()
export class NetworkRetryHttpInterceptor implements HttpInterceptor {
  constructor(private readonly networkConnected$: NetworkIsConnectedObservable) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(retryWhen(ifConnectedRetryExponentialDelayStrategy(this.networkConnected$)));
  }
}
