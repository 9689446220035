import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { Store, StoreModule } from '@ngrx/store';
import { setGlobalI18n } from '@t5s/client/i18n/common';
import { I18n, I18nObservable } from '@t5s/client/util/i18n-token';
import { I18nObjectObservable } from '@t5s/mobile-client/provider-token/i18n';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { webClientLanguagesDict } from '@t5s/web-client/i18n/common';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { RuntimeI18nLegacyProxy } from '@t5s/web-client/store-adapter/i18n';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { ApplicationI18nEffects } from './application-i18n.effect';
import { applicationI18nReducer, applicationI18nStateKey } from './application-i18n.reducer';
import { selectApplicationI18n } from './application-i18n.selectors';

@NgModule({
  imports: [
    StoreModule.forFeature(applicationI18nStateKey, applicationI18nReducer),
    EffectsModule.forFeature([ApplicationI18nEffects]),
  ],
  providers: [
    RuntimeI18nLegacyProxy,
    {
      provide: I18nObjectObservable,
      useFactory: (store$: Store) => store$.select(selectApplicationI18n).pipe(map((i18n) => ({ i18n }))),
      deps: [Store],
    },
    {
      provide: I18nObservable,
      useFactory: (i18n$: I18nObjectObservable): Observable<I18n> =>
        i18n$.pipe(
          map(({ i18n }) => ({
            ...i18n,
            i18n,
            languagesDict: webClientLanguagesDict,
          })),
        ),
      deps: [I18nObjectObservable],
    },
    {
      provide: I18nObjectObservable,
      useFactory: (store$: Store) =>
        store$.select(selectApplicationI18n).pipe(
          tap((i18n) => setGlobalI18n(i18n)),
          map((i18n) => ({ i18n })),
        ),
      deps: [Store],
    },
  ],
})
export class BusinessLogicApplicationI18nModule {}
