import { FlimType } from '@t5s/shared/gql';

export const flimDefDeClientLang = {
  chooseAFlimType: 'Feld hinzufügen',
  changeChooseAFlimType: 'Feldtyp ändern',
  closeBtnTooltip: 'Schließen',
  deleteFlimDef: 'Feld löschen',
  duplicateFlimDef: 'Feld duplizieren',
  editBtnTooltip: 'Feld bearbeiten',
  flimDefName: 'Feld',
  hiddenIfEmptyTag: 'Verborgen wenn leer',
  alwaysHiddenTag: 'Immer verborgen',
  settingsBtnTooltip: 'Optionen',
  moveFlimDefTooltip: 'Bewegen',
  [FlimType.SINGLE_TEXT]: {
    label: 'Einzeiliger Text',
  },
  [FlimType.MULTI_TEXT]: {
    label: 'Mehrzeiliger Text',
  },
  [FlimType.SINGLE_CATEGORY]: {
    label: 'Einzel-Auswahl',
    layout: {
      LIST: 'Liste',
      INLINE: 'Reihe',
      DROPDOWN: 'Dropdown-Liste',
    },
  },
  [FlimType.MULTI_CATEGORY]: {
    label: 'Mehrfach-Auswahl',
    layout: {
      LIST: 'Liste',
      INLINE: 'Reihe',
      DROPDOWN: 'Dropdown-Liste',
    },
  },
  [FlimType.SINGLE_RELATION]: {
    label: 'Verbindung',
  },
  [FlimType.MULTI_RELATION]: {
    label: 'Verbindung',
  },
  [FlimType.SINGLE_USER]: {
    label: 'Mitglied',
    showInFocusTag: 'In Fokus zeigen',
  },
  [FlimType.MULTI_USER]: {
    label: 'Mitglied',
    showInFocusTag: 'In Fokus zeigen',
  },
  [FlimType.SINGLE_ATTACHMENT]: {
    label: 'Dateien',
  },
  [FlimType.MULTI_ATTACHMENT]: {
    label: 'Dateien',
  },
  [FlimType.SINGLE_DATE]: {
    label: 'Datum',
    isDueDateOfStatusFieldTag: 'Deadline für Status',
    showInCalendarTag: 'Im Kalender anzeigen',
  },
  [FlimType.RANGE_DATE]: {
    label: 'Datum',
    isDueDateOfStatusFieldTag: 'Deadline für Status',
    showInCalendarTag: 'Im Kalender anzeigen',
  },
  [FlimType.CHECKLIST]: {
    label: 'Checkliste',
    showInFocusTag: 'In Fokus zeigen',
  },
  [FlimType.MULTI_LINK]: {
    label: 'Link',
  },
  [FlimType.CREATED_BY]: {
    label: 'Erstellt von',
  },
  [FlimType.CREATED_AT]: {
    label: 'Erstellt am',
  },
  [FlimType.LAST_MODIFIED_BY]: {
    label: 'Zuletzt geändert von',
  },
  [FlimType.LAST_MODIFIED_AT]: {
    label: 'Zuletzt geändert am',
  },
  [FlimType.NUMBER]: {
    label: 'Zahl',
  },
  [FlimType.STATUS]: {
    defaultCompleteOptionLabel: 'Erledigt',
    defaultIncompleteOptionLabel: 'Unerledigt',
    label: 'Status',
    layout: {
      LIST: 'Liste',
      INLINE: 'Reihe',
      DROPDOWN: 'Dropdown-Liste',
      PROCESS: 'Prozess',
    },
  },
  [FlimType.CALCULATION]: {
    label: 'Berechnung',
  },
  [FlimType.MULTI_EMAIL]: {
    label: 'E-Mail',
  },
  [FlimType.MULTI_PHONE]: {
    label: 'Telefon',
  },
  [FlimType.SINGLE_LOCATION]: {
    label: 'Standort',
  },
  [FlimType.MULTI_IMAGE]: {
    label: 'Bild',
  },
  [FlimType.UNIQUE_ID]: {
    label: 'Einmalige ID',
  },
};
