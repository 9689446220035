export const userSetupEnLangClient = {
  notification: {
    allow: 'Get started now',
    skip: 'Skip for now',
    subtitle: `We are so glad you are here`,
    title: 'Welcome to Tape',
  },
  profile: {
    continue: 'Next',
    skip: 'Skip for now',
    subtitle: `This is how you'll appear in Tape`,
    title: 'Pick a profile picture',
  },
};
