function fromString(candidate: string): boolean {
  return candidate?.toLowerCase() === 'true';
}

function fromStringStrict(candidate: string): boolean | undefined {
  const lowerCaseCandidate = candidate?.toLowerCase();

  if (lowerCaseCandidate === 'true') {
    return true;
  }

  if (lowerCaseCandidate === 'false') {
    return false;
  }

  return undefined;
}

export const BooleanUtils = {
  fromString,
  fromStringStrict,
};
