export const blabDefFlimDefEditEnLangClient = {
  addFlimDefBtnTooltip: 'Add field',
  cancelBtn: 'Cancel',
  closeBtnTooltip: 'Close',
  placeholder: {
    title: '',
    subtitle: '',
  },
  saveBtn: 'Save',
  saveErrorToast: `Ouch, your changes could not be saved. Please try again.`,
  settingsMenuEntry: 'App settings',
  title: 'Customize fields',
};
