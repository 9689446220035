export const blabDefFlimDefEditDeLangClient = {
  addFlimDefBtnTooltip: 'Feld hinzufügen',
  cancelBtn: 'Abbrechen',
  closeBtnTooltip: 'Schließen',
  placeholder: {
    title: '',
    subtitle: '',
  },
  saveBtn: 'Speichern',
  saveErrorToast: `Oppala - die Änderungen konnten nicht gespeichert werden. Bitte erneut versuchen.`,
  settingsMenuEntry: 'App-Einstellungen',
  title: 'Felder anpassen',
};
