import { DateFormat, DecimalFormat, MeasurementSystem, FirstWeekday, Language, TimeFormat } from '@t5s/shared/gql';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { I18nKey } from './i18n-key';
import { I18nParams, I18nString } from './i18n-string.interface';

export function normalizeI18n(
  i18nInput: I18nKey | I18nString | undefined | null,
  params?: I18nParams,
): I18nString | undefined {
  if (!i18nInput) {
    return undefined;
  } else if (typeof i18nInput === 'string') {
    return { key: i18nInput, params };
  } else {
    return i18nInput;
  }
}

export function deriveTimeFormatFromLanguage(language: Language): TimeFormat {
  switch (language) {
    case Language.EN:
      return TimeFormat.HOURS_12;
    case Language.DE:
      return TimeFormat.HOURS_24;
  }
}

export function deriveDateFormatFromLanguage(language: Language): DateFormat {
  switch (language) {
    case Language.EN:
      return DateFormat.SLASH_MM_DD_YYYY;
    case Language.DE:
      return DateFormat.DOT_DD_MM_YYYY;
  }
}

export function deriveFirstWeekdayFromLanguage(language: Language): FirstWeekday {
  switch (language) {
    case Language.EN:
      return FirstWeekday.MONDAY;
    case Language.DE:
      return FirstWeekday.MONDAY;
  }
}

export function deriveDecimalFormatFromLanguage(language: Language): DecimalFormat {
  switch (language) {
    case Language.EN:
      return DecimalFormat.DECIMAL_SEPARATOR_DOT;
    case Language.DE:
      return DecimalFormat.DECIMAL_SEPARATOR_COMMA;
  }
}

export function deriveMeasurementSystemFromLanguage(language: Language): MeasurementSystem {
  switch (language) {
    case Language.EN:
      return MeasurementSystem.IMPERIAL_US;
    case Language.DE:
      return MeasurementSystem.METRIC;
  }
}

export function deriveI18nFromLanguage(_language: Language | undefined): SharedI18n {
  const language = _language ?? Language.EN;

  return {
    language,
    dateFormat: deriveDateFormatFromLanguage(language),
    timeFormat: deriveTimeFormatFromLanguage(language),
    firstWeekday: deriveFirstWeekdayFromLanguage(language),
    decimalFormat: deriveDecimalFormatFromLanguage(language),
    measurementSystem: deriveMeasurementSystemFromLanguage(language),
  };
}
