export const organizationProfileEnLangClient = {
  activity: 'Activity',
  channels: 'Channels',
  edit: {
    changeOrganizationUrl: {
      cancel: 'Cancel',
      changeLink: 'Edit organization URL',
      edit: 'Edit',
      inputNote: `Your organization URL can only contain lowercase letters, numbers and dashes and must start with a letter or number.`,
      modalSubtitle: `Any links using the existing URL won't work anymore, if you decide to change the workspace URL.`,
      modalTitle: 'Edit organization URL?',
      organizationUrl: 'Organization URL',
      organizationUrlPlaceholder: 'your-organization-url',
    },
    emailAddress: 'Email address',
    emailAddressPlaceholder: 'Add email address',
    location: 'Location',
    locationPlaceholder: 'Add location',
    organizationDescription: 'Description',
    organizationDescriptionPlaceholder: 'Add description',
    organizationName: 'Organization name',
    organizationNamePlaceholder: 'Add organization name',
    phoneNumber: 'Phone number',
    phoneNumberPlaceholder: 'Add phone number',
    uploadImageBtnTooltip: 'Add photo',
    website: 'Website',
    websitePlaceholder: 'Add website',
  },
} as const;
