import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { ClientServiceBrowserModule } from '@t5s/client/service/browser';
import { internationalizationStateKey } from './internationalization.app-state';
import { InternationalizationEffects } from './internationalization.effects';
import { internationalizationReducer } from './internationalization.reducer';

@NgModule({
  imports: [
    ClientServiceBrowserModule,
    StoreModule.forFeature(internationalizationStateKey, internationalizationReducer),
    EffectsModule.forFeature([InternationalizationEffects]),
  ],
})
export class WebClientStoreInternationalizationModule {}
