import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { BusinessLogicActiveUserSessionModule } from '@t5s/mobile-client/business-logic/active-user-session';
import { BusinessLogicApplicationI18nModule } from '@t5s/mobile-client/business-logic/application-i18n';
import { ApplicationStatusBarBusinessLogicModule } from '@t5s/mobile-client/business-logic/application-status-bar';
import { BusinessLogicApplicationThemeModule } from '@t5s/mobile-client/business-logic/application-theme';
import { ApplicationUrlBusinessLogicModule } from '@t5s/mobile-client/business-logic/application-url';
import { BlabItemActivityThreadBusinessLogicModule } from '@t5s/mobile-client/business-logic/blab-item-activity-thread';
import { BlabItemDisplayBusinessLogicModule } from '@t5s/mobile-client/business-logic/blab-item-display';
import { BlabItemNotificationPushBusinessLogicModule } from '@t5s/mobile-client/business-logic/blab-item-notification-push';
import { ChatPushNotificationBusinessLogicModule } from '@t5s/mobile-client/business-logic/chat-push-notification';
import { BusinessLogicDeviceModule } from '@t5s/mobile-client/business-logic/device';
import { DeviceMediaBusinessLogicModule } from '@t5s/mobile-client/business-logic/device-media';
import { DisableLongpressIosBusinessLogicModule } from '@t5s/mobile-client/business-logic/disable-longpress-ios';
import { BusinessLogicExceptionToastModule } from '@t5s/mobile-client/business-logic/exception-toast';
import { KeyboardBusinessLogicModule } from '@t5s/mobile-client/business-logic/keyboard';
import { BusinessLogicNetworkStatusModule } from '@t5s/mobile-client/business-logic/network-status';
import { PushNotificationBusinessLogicModule } from '@t5s/mobile-client/business-logic/push-notification';
import { SafeAreaBusinessLogicModule } from '@t5s/mobile-client/business-logic/safe-area';
import { ScreenOrientationBusinessLogicModule } from '@t5s/mobile-client/business-logic/screen-orientation';
import { ActiveActorContextStoreModule } from '@t5s/client/store/active-actor-context';

@NgModule({
  imports: [
    EffectsModule.forRoot([]),
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictActionTypeUniqueness: true,
        },
      },
    ),

    // Feature agnostic business logic
    BusinessLogicDeviceModule,
    BusinessLogicApplicationThemeModule,
    BusinessLogicApplicationI18nModule,
    PushNotificationBusinessLogicModule,
    BusinessLogicExceptionToastModule,
    BusinessLogicNetworkStatusModule,
    ScreenOrientationBusinessLogicModule,
    SafeAreaBusinessLogicModule,
    DisableLongpressIosBusinessLogicModule,
    ApplicationUrlBusinessLogicModule,
    ApplicationStatusBarBusinessLogicModule,
    KeyboardBusinessLogicModule,
    DeviceMediaBusinessLogicModule,

    // Core features that need to be bootstrapped initially
    BlabItemDisplayBusinessLogicModule,
    BlabItemActivityThreadBusinessLogicModule,
    BusinessLogicActiveUserSessionModule,
    ActiveActorContextStoreModule,

    // Push notification handling
    BlabItemNotificationPushBusinessLogicModule,
    ChatPushNotificationBusinessLogicModule,
  ],
})
export class MobileClientBusinessLogicModule {}
