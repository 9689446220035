import { Inject, Injectable } from '@angular/core';
import { WINDOW } from '@t5s/client/provider/window';
import { BooleanUtils } from '@t5s/shared/util/boolean';
import { parseInteger } from '@t5s/shared/util/number';

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  private readonly localStorage: Storage;

  constructor(@Inject(WINDOW) window: Window | undefined) {
    if (!window) {
      throw new Error('LocalStorageService cannot be used without a browser window object.');
    }

    this.localStorage = window.localStorage;
  }

  clear() {
    this.localStorage.clear();
  }

  write(key: string, input: boolean | string | number | Record<string, unknown> | undefined) {
    try {
      if (typeof input === 'object') {
        input = JSON.stringify(input);
      }

      this.localStorage.setItem(key, `${input}`);
    } catch (e: unknown) {}
  }

  read(key: string): number | boolean | string | Record<string, unknown> | null | undefined {
    try {
      const raw = this.localStorage.getItem(key);

      if (!raw) {
        return null;
      }

      // attempt to parse int
      const integer = parseInteger(raw);
      if (integer) {
        return integer;
      }

      // attempt to parse boolean
      const booleanValue = BooleanUtils.fromStringStrict(raw);
      if (booleanValue) {
        return booleanValue;
      }

      // attempt to parse JSON
      try {
        const parsed: any = JSON.parse(raw);
        return parsed;
      } catch (e: any) {
        return raw;
      }
    } catch (e: any) {
      return undefined;
    }
  }

  readBoolean(key: string): boolean | null {
    const booleanCandidate = this.read(key);

    if (typeof booleanCandidate !== 'boolean') {
      return null;
    }

    return booleanCandidate;
  }

  readString(key: string): string | null {
    const stringCandidate = this.read(key);

    if (typeof stringCandidate !== 'string') {
      return null;
    }

    return stringCandidate;
  }

  readRaw(key: string): unknown {
    return this.localStorage.getItem(key);
  }
}
