export function getTimeFromInputStr(input: string | undefined): string | null {
  let str = input || '';

  // Match hours
  const hoursMatch = matchHours(str);
  if (!hoursMatch) {
    return null;
  }
  str = str.substr(hoursMatch.hoursIndex);
  let { hours } = hoursMatch;

  // Match minutes
  let minutes = matchMinutes(str);

  if (minutes === 60) {
    minutes = 0;
    hours += 1;
  }

  hours = hours % 24;

  // Format
  const hoursStr = `${hours}`.padStart(2, '0');
  const minutesStr = `${minutes}`.padStart(2, '0');
  const result = `${hoursStr}:${minutesStr}`;
  return result;
}

function matchHours(input: string | undefined): null | { hours: number; hoursIndex: number } {
  if (!input) {
    return null;
  }

  const exactThreeConsecutiveNums =
    (input.match(/\d/g)?.length || 0) === 3 && (input.match(/\d{3}/g)?.length || 0) === 1;

  // In case 3 digits exist in input and are consecutively, prefer single digit match for hours instead of multi-digit
  const match = exactThreeConsecutiveNums ? input.match(/\d|0\d|1\d|(2[0-4])/) : input.match(/(2[0-4]|1\d|0\d|\d)/);

  if (!match) {
    return null;
  }

  const index = match.index;
  if (index === undefined) {
    return null;
  }
  const hoursStr = match[0];

  let hours = parseInt(hoursStr, 10);

  if (isNaN(hours) || hours < 0 || hours > 24) {
    return null;
  }

  // Match AM/PM
  const isPm: boolean = matchPm(input);

  if (isPm && hours < 12) {
    hours += 12;
  }

  return { hours, hoursIndex: index + hoursStr.length };
}

function matchMinutes(input: string | undefined): number {
  if (!input) {
    return 0;
  }

  const match = input.match(/([0-6]\d|\d)/);
  if (!match) {
    return 0;
  }

  const index = match.index;
  if (index === undefined) {
    return 0;
  }

  const minutesStr = match[0];

  const minutes = parseInt(minutesStr, 10);

  if (isNaN(minutes) || minutes < 0 || minutes > 60) {
    return 0;
  }

  return minutes;
}

function matchPm(str: string): boolean {
  const isPm: boolean = str.toLowerCase().includes('p');
  return isPm;
}
