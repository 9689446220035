export const globalSearchDeLangClient = {
  blabDefAddItemActionBtnTooltip: '{{itemName}} erstellen',
  clearAll: `Löschen`,
  clearTooltip: 'Suche löschen',
  dialog: {
    clearSearchHistory: {
      confirmButtonLabel: `Löschen`,
    },
    message: `Dies kann nicht rückgängig gemacht werden und du entfernst all deine letzten Suchanfragen.`,
    title: `Alle letzten Suchanfragen löschen?`,
  },
  emptySearchLabel: `Versuche, nach Einträgen, Personen oder Stichworten zu suchen`,
  noResults: `Keine Ergebnisse für deine Suche`,
  notifications: {
    clearSearchHistorySuccess: `Such-Historie erfolgreich bereinigt`,
  },
  placeholder: `Tape durchsuchen`,
  recentSearchLabel: `Letzte Suchanfragen`,
  removeHistoryItemTooltip: 'Suchanfrage entfernen',
  showAllResultsFor: `Alle Ergebnisse für "{{searchQuery}}" anzeigen`,
} as const;
