export const organizationUserEnLangClient = {
  manage: {
    accountType: 'Account type',
    activateAccount: 'Activate as member',
    activateAccountAsGuest: 'Activate as guest',
    activeBilling: 'Active billing',
    changeAccountType: 'Change account type',
    changeAccountTypeModal: {
      cancelButtonLabel: 'Cancel',
      saveButtonLabel: 'Save',
    },
    confirmDeactivateModal: {
      confirmButtonLabel: 'Deactivate',
      message: `Are you sure you want to deactivate this member? The member will be signed out of your organization on all devices, and unable to sign back in.`,
      title: 'Deactivate account?',
    },
    deactivateAccount: 'Deactivate account',
    deactivated: 'Deactivated',
    deactivateOrChangeRoleRejectionToast: `The last organization owner can’t be changed or deactivated.`,
    editInfo: 'Edit info',
    editInfoModal: {
      close: 'Close',
      cancelButtonLabel: 'Cancel',
      saveButtonLabel: 'Save',
      displayName: 'Display name',
      displayNamePlaceholder: 'Add display name',
      email: 'Email',
      emailPlaceholder: 'Add email',
      fullName: 'Full name',
      fullNamePlaceholder: 'Add full name',
      myJob: 'My job',
      myJobPlaceholder: 'Add your job description',
    },
    editInfoRejectionEmailTakenToast:
      'The provided email address is already being used by another account in your organization.',
    inactiveBilling: 'Inactive billing',
    invitedRole: {
      ORG_ADMIN: 'Invited admin',
      ORG_GUEST: 'Invited guest',
      ORG_MEMBER: 'Invited member',
      ORG_OWNER: 'Invited owner',
    },
    invitePeople: 'Invite people',
    manageMembers: 'Manage members',
    name: 'Name',
    optionsBtnTooltip: 'Options',
    copyInvitationLink: 'Copy invitation link',
    copyInvitationLinkSuccessToast: 'Invitation link copied to clipboard',
    resendInvitation: 'Resend invitation',
    resendInvitationSuccessToast: 'Sent invitation to {{email}}',
    revokeInvitation: 'Revoke invitation',
    revokeInvitationSuccessToast: 'Revoked invitation of {{email}}',
    role: {
      ORG_ADMIN: 'Admin',
      ORG_GUEST: 'Guest',
      ORG_MEMBER: 'Member',
      ORG_OWNER: 'Owner',
    },
    close: 'Close',
    roleExplanation: {
      ORG_OWNER: 'Can change organization settings, delete the organization and invite new members.',
      ORG_ADMIN: 'Can change organization settings and invite new members, but can’t delete the organization.',
      ORG_MEMBER: 'Cannot change organization settings or invite new members.',
      ORG_GUEST: '',
    },
  },
};
