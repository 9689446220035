import { differenceInSeconds } from '@t5s/shared/util/date';
import { SharedI18n } from '@t5s/shared/value-object/i18n';
import { DatetimeI18n } from '../../../datetime.i18n';
import { ApplicableI18n, isApplicableI18n, normalizeDate } from '../common';

const now = () => new Date(Date.now());

function deltaDateTimeI18n(date: Date, i18n: ApplicableI18n) {
  const deltaSec = Math.max(differenceInSeconds(now(), date), 1); // at least 1 sec ago

  if (deltaSec < 60) {
    return {
      key: deltaSec === 1 ? DatetimeI18n.key.secondDeltaLongSingle : DatetimeI18n.key.secondDeltaLongMulti,
      params: { delta: `${Math.floor(deltaSec)}` },
    };
  }

  // minutes ago
  const deltaMin = deltaSec / 60;
  if (deltaMin < 60) {
    return {
      key: deltaMin === 1 ? DatetimeI18n.key.minuteDeltaLongSingle : DatetimeI18n.key.minuteDeltaLongMulti,
      params: { delta: `${Math.floor(deltaMin)}` },
    };
  }

  // hours ago
  const deltaH = deltaMin / 60;
  if (deltaH < 24) {
    return {
      key: deltaH === 1 ? DatetimeI18n.key.hourDeltaLongSingle : DatetimeI18n.key.hourDeltaLongMulti,
      params: { delta: `${Math.floor(deltaH)}` },
    };
  }

  // days ago
  const deltaDays = deltaH / 24;
  if (deltaDays < 7) {
    return {
      key: deltaDays === 1 ? DatetimeI18n.key.dayDeltaLongSingle : DatetimeI18n.key.dayDeltaLongMulti,
      params: { delta: `${Math.floor(deltaDays)}` },
    };
  }

  // weeks ago
  const deltaWeeks = deltaDays / 7;
  if (deltaWeeks < 4) {
    return {
      key: deltaWeeks === 1 ? DatetimeI18n.key.weekDeltaLongSingle : DatetimeI18n.key.weekLongMulti,
      params: { delta: `${Math.floor(deltaWeeks)}` },
    };
  }

  // fallback to delta months
  const deltaMonths = deltaDays / 30;
  return {
    key: deltaMonths === 1 ? DatetimeI18n.key.monthDeltaLongSingle : DatetimeI18n.key.monthDeltaLongMulti,
    params: { delta: `${Math.floor(deltaMonths)}` },
  };
}

/** Formats a delta timestamp:
 * - 1 second
 * - 5 minutes
 * - 1 hour
 * - 2 days
 * - 1 week
 * - 1 month
 * - 2 months
 * - ...
 * ...
 */
export function formatDeltaDateTimeSkipNow(i18n: SharedI18n, date?: string | number | Date): string | undefined {
  if (!date || !isApplicableI18n(i18n)) {
    return undefined;
  }

  date = normalizeDate(date);

  const i18nStr = deltaDateTimeI18n(date, i18n);

  return DatetimeI18n.translate(i18n, i18nStr);
}
