export const organizationSidebarDeLangClient = {
  addWorkspaceBtnTooltip: 'Workspace hinzufügen',
  addWorkspaceMenu: {
    useTemplateBtn: 'Aus den Vorlagen wählen',
    blankWorkspace: 'Neuer Workspace',
  },
  collapseSidebarBtnTooltip: 'Sidebar schließen',
  expandSidebarBtnTooltip: 'Sidebar öffnen',
  organizationMenu: {
    add: `Organisation hinzufügen`,
    admin: `Organisations-Einstellungen`,
    invite: `Personen einladen`,
    workflowCenterButton: `Automatisierungen`,
    new: `Neu`,
    orgPage: `Organisationsseite`,
  },
};
