export const organizationUserDeLangClient = {
  manage: {
    accountType: 'Account-Typ',
    activateAccount: 'Als Mitglied aktivieren',
    activateAccountAsGuest: 'Als Gast aktivieren',
    activeBilling: 'Abrechnung aktiv',
    changeAccountType: 'Account-Typ ändern',
    changeAccountTypeModal: {
      cancelButtonLabel: 'Abbrechen',
      saveButtonLabel: 'Speichern',
    },
    confirmDeactivateModal: {
      confirmButtonLabel: 'Deaktivieren',
      message: `Bist du sicher, dass du dieses Mitglied deaktivieren möchtest? Das Mitglied wird auf allen Geräten aus dieser Organisation abgemeldet und kann sich nicht wieder anmelden.`,
      title: 'Account deaktivieren?',
    },
    deactivateAccount: 'Account deaktivieren',
    deactivated: 'Deaktiviert',
    deactivateOrChangeRoleRejectionToast: `Det letzte Organisations-Inhaber kann nicht geändert oder deaktiviert werden.`,
    editInfo: 'Infos bearbeiten',
    editInfoModal: {
      close: 'Schließen',
      cancelButtonLabel: 'Abbrechen',
      saveButtonLabel: 'Speichern',
      displayName: 'Anzeigename',
      displayNamePlaceholder: 'Anzeigename hinzufügen',
      email: 'E-Mail-Adresse',
      emailPlaceholder: 'E-Mail-Adresse hinzufügen',
      fullName: 'Vollständiger Name',
      fullNamePlaceholder: 'Vollständigen Namen hinzufügen',
      myJob: 'Mein Job',
      myJobPlaceholder: 'Jobbeschreibung hinzufügen',
    },
    editInfoRejectionEmailTakenToast:
      'Die angegebene E-Mail-Adresse wird bereits von einem anderen Account in deiner Organisation verwendet.',
    inactiveBilling: 'Abrechnung inaktiv',
    invitedRole: {
      ORG_ADMIN: 'Eingeladener Admin',
      ORG_GUEST: 'Eingeladener Gast',
      ORG_MEMBER: 'Eingeladenes Mitglied',
      ORG_OWNER: 'Eingeladener Inhaber',
    },
    invitePeople: 'Personen einladen',
    manageMembers: 'Mitglieder verwalten',
    name: 'Name',
    optionsBtnTooltip: 'Optionen',
    copyInvitationLink: 'Einladungs-Link kopieren',
    copyInvitationLinkSuccessToast: 'Einladungs-Link in die Zwischenablage kopiert',
    resendInvitation: 'Einladung erneut senden',
    resendInvitationSuccessToast: 'Einladung an {{email}} gesendet',
    revokeInvitation: 'Einladung widerrufen',
    revokeInvitationSuccessToast: 'Einladung von {{email}} widerrufen',
    role: {
      ORG_ADMIN: 'Organisations-Admin',
      ORG_GUEST: 'Gast',
      ORG_MEMBER: 'Mitglied',
      ORG_OWNER: 'Organisations-Inhaber',
    },
    close: 'Schließen',
    roleExplanation: {
      ORG_OWNER: 'Kann Organisations-Einstellungen ändern, die Organisation löschen und neue Mitglieder einladen.',
      ORG_ADMIN:
        'Kann Organisations-Einstellungen ändern und neue Mitglieder einladen, aber nicht die Organisation löschen.',
      ORG_MEMBER: 'Kann keine Organisations-Einstellungen ändern oder neue Mitglieder einladen.',
      ORG_GUEST: '',
    },
  },
};
