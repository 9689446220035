export const relationInputDeLangClient = {
  multiRelationInput: {
    placeholder: 'Einträge auswählen',
    searchPlaceholder: 'Nach Einträgen suchen',
  },
  singleRelationInput: {
    placeholder: 'Eintrag auswählen',
    searchPlaceholder: 'Nach Eintrag suchen',
  },
};
