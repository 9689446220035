import {
  CreateSingleLocationFlimValInput,
  SingleLocationFlimValDto,
  UpsertSingleLocationFlimValInput,
} from '@t5s/shared/gql';

export const getSingleLocationUpsertInput = ({
  blabItemId,
  flimDefId,
  city,
  country,
  originalFormattedAddress,
  postalCode,
  state,
  streetAddress,
  latitude,
  longitude,
}: SingleLocationFlimValDto): UpsertSingleLocationFlimValInput => ({
  blabItemId,
  flimDefId,
  city,
  country,
  originalFormattedAddress,
  postalCode,
  state,
  streetAddress: streetAddress ? streetAddress : undefined,
  latitude,
  longitude,
});

export const getSingleLocationCreateInputFromDto = ({
  flimDefId,
  city,
  country,
  originalFormattedAddress,
  postalCode,
  state,
  streetAddress,
  locationId,
  hideMapEmbed,
  latitude,
  longitude,
}: SingleLocationFlimValDto): CreateSingleLocationFlimValInput => ({
  flimDefId,
  city,
  country,
  originalFormattedAddress,
  postalCode,
  state,
  streetAddress,
  locationId,
  hideMapEmbed,
  latitude,
  longitude,
});
