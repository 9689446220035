export const blabItemIncomingRelationDeLangClient = {
  addItem: '{{itemName}} hinzufügen',
  goToRelatedItemsTooltip: 'Zur {{blabName}}-App gehen, gefiltert nach verbundenen Einträgen',
  placeholder: {
    subtitle: 'Wenn jemand einen Eintrag verbindet, erscheint er hier.',
    title: 'Hier gibt es noch nichts zu sehen',
  },
  relatedActivity: 'Verbundene Aktivitäten',
  relatedItems: 'Verbindungen',
  showMore: 'Mehr anzeigen',
} as const;
