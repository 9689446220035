export const findMyOrganizationsDeLangClient = {
  enterEmail: {
    btnLabel: 'Weiter',
    inputPlaceholder: 'du@beispiel.de',
    invalidEmailError: 'Bitte gib eine gültige E-Mail-Adresse ein',
    subtitle: 'Gib die E-Mail-Adresse ein, die du für deine Arbeit verwendest',
    title: 'Finde deine Tape-Organisation',
  },
  linkInfo: {
    linkLabel: 'Andere E-Mail-Adresse versuchen',
    subtitle:
      'Klicke auf den Link, um deine E-Mail-Adresse {{email}} zu bestätigen und loslegen zu können. Wenn du keine E-Mail erhalten hast, überprüfe bitte auch deinen Spam-Ordner.',
    title: 'Wir haben dir einen besonderen Link gesendet!',
  },
  pick: {
    createNewOrgLabel: 'Neue Organisation erstellen',
    launchBtnLabel: 'Anmelden',
    pickerBoxTitle: 'Deine Organisationen',
    subtitle: 'Wähle die Organisation in der du dich anmelden möchtest',
    title: 'Du bist bereits Mitglied dieser Tape-Organisationen',
    tryOtherEmailLabel: 'Andere E-Mail-Adresse versuchen',
  },
};
