export const categoryInputDeLangClient = {
  multiCategoryInput: {
    placeholder: 'Select options',
    searchPlaceholder: 'Search for options',
  },
  singleCategoryInput: {
    placeholder: 'Select an option',
    searchPlaceholder: 'Search for an option',
  },
};
