export const organizationSetupEnLangClient = {
  intentions: {
    continue: 'Next',
    intentions: {
      TASK_MANAGEMENT: 'Task Management',
      MARKETING: 'Marketing',
      PROJECT_MANAGEMENT: 'Project management',
      DEVELOPMENT: 'Software development',
      STARTUP: 'Startup',
      SALES: 'Sales',
      CUSTOMER_DEVELOPMENT: 'Customer Development',
    },
    section: {
      crm: 'Customer relationship management',
      finance: 'Finance',
      hr: 'Human resources',
      inventory: 'Inventory management',
      knowledge: 'Knowledge management',
      pm: 'Project management',
    },
    skip: 'Skip for now',
    subtitle:
      'We’ll use your choice to help you get up and running in Tape. You can easily change the selection again at any time.',
    title: 'What would you like to use Tape for first?',
  },
  invitation: {
    continue: 'Invite',
    label: 'Invite people',
    description: 'Type or paste in one or multiple emails separated by commas, spaces, or line breaks.',
    searchPlaceholder: 'annie@myteam.com, henry@company.com, ...',
    skip: 'Skip for now',
    subtitle: 'Tape works best with your teammates',
    title: 'Start with your team',
  },
};
