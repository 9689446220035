export const forgotPasswordDeLangClient = {
  enterEmail: {
    btnLabel: 'Weiter',
    inputPlaceholder: 'du@beispiel.de',
    invalidEmailError: 'Bitte gib eine gültige E-Mail-Adresse ein',
    subtitle:
      'Das Zurücksetzen deines Passworts erfordert die Eingabe der E-Mail-Adresse, die du für die Anmeldung bei tapeapp.com/{{orgSlug}} verwendest.',
    title: 'Passwort zurücksetzen',
  },
  enterNew: {
    btnLabel: 'Bestätigen',
    invalidNewPasswordConfirmError: 'Die von dir eingegebenen Passwörter stimmen nicht überein',
    invalidNewPasswordError: {
      none: 'Bitte gib ein neues Passwort ein',
      TOO_COMMON: 'Bitte gib ein stärkeres Passwort ein',
      TOO_SHORT: 'Dein Passwort muss mindestens 6 Zeichen lang sein',
    },
    newPassword: 'Neues Passwort',
    newPasswordConfirm: 'Neues Passwort bestätigen',
    newPasswordConfirmPlaceholder: 'Mindestens 6 Zeichen',
    newPasswordPlaceholder: 'Mindestens 6 Zeichen',
    subtitle: 'Gib dein neues Passwort für deinen Tape Account ein.',
    title: 'Passwort zurücksetzen',
  },
  linkInfo: {
    linkLabel: 'Andere E-Mail-Adresse versuchen',
    subtitle:
      'Klicke auf den Link, um deine E-Mail-Adresse {{email}} zu bestätigen und dein Passwort zurückzusetzen. Wenn du keine E-Mail erhalten hast, überprüfe bitte auch deinen Spam-Ordner.',
    title: 'Wir haben dir einen Link zum Zurücksetzen gesendet!',
  },
};
