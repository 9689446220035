export const reminderDeLangClient = {
  detail: {
    descPlaceholder: 'Details hinzufügen',
    dueAtPlaceholder: 'Fälligkeitsdatum hinzufügen',
    itemPlaceholder: 'Item verknüpfen',
    titlePlaceholder: 'Titel hinzufügen',
  },
  displaySettings: {
    APP: 'App',
    DUE_DATE: 'Fälligkeitsdatum',
    USER_ORDER: 'Meine Reihenfolge',
  },
  menu: {
    archiveAllCompleted: 'Alle erledigten archivieren',
    creationDate: 'Erstellungsdatum',
    dueAt: 'Fälligkeitsdatum',
    hideArchived: 'Archivierte ausblenden',
    myOrder: 'Meine Reihenfolge',
    relatedApp: 'Referenzierte app',
    showArchived: 'Archivierte einblenden',
    sortBy: 'Sortieren nach',
  },
  newReminderModal: {
    descriptionPlaceholder: 'Details hinzufügen',
    titlePlaceholder: 'Neue Erinnerung',
  },
  placeholder: {
    subline: `Du hast keine offenen Erinnerungen.`,
    title: 'Stark!',
  },
  sectionHeadlines: {
    archived: `Archiviert ({{count}})`,
    completed: `Erledigt ({{count}})`,
  },
  title: `Erinnerungen`,
} as const;
