export const blabItemNotificationLangDeClient = {
  // BlabItemActivityReply
  blabItemActivityReplyToActiveUserComment:
    '<b>{{authorName}}</b> hat auf deinen Kommentar in {{blabItemTitle}} geantwortet',
  blabItemActivityReplyToActiveUserCommentByAutomation:
    '<b>Automatisierung</b> hat auf deinen Kommentar in {{blabItemTitle}} geantwortet',
  blabItemActivityReplyToActiveUserCommentMention:
    '<b>{{authorName}}</b> hat dich in einer Antwort auf deinen Kommentar in {{blabItemTitle}} erwähnt',
  blabItemActivityReplyToActiveUserCommentMentionByAutomation:
    '<b>Automatisierung</b> hat dich in einer Antwort auf deinen Kommentar in {{blabItemTitle}} erwähnt',
  blabItemActivityReplyToActiveUserUpdate: '<b>{{authorName}}</b> hat auf dein Update in {{blabItemTitle}} geantwortet',
  blabItemActivityReplyToActiveUserUpdateByAutomation:
    '<b>Automatisierung</b> hat auf dein Update in {{blabItemTitle}} geantwortet',
  blabItemActivityReplyToActiveUserUpdateMention:
    '<b>{{authorName}}</b> hat dich in einer Antwort auf dein Update in {{blabItemTitle}} erwähnt',
  blabItemActivityReplyToActiveUserUpdateMentionByAutomation:
    '<b>Automatisierung</b> hat dich in einer Antwort auf dein Update in {{blabItemTitle}} erwähnt',
  blabItemActivityReplyToComment: '<b>{{authorName}}</b> hat auf einen Kommentar in {{blabItemTitle}} geantwortet',
  blabItemActivityReplyToCommentByAutomation:
    '<b>Automatisierung</b> hat auf einen Kommentar in {{blabItemTitle}} geantwortet',
  blabItemActivityReplyToCommentMention:
    '<b>{{authorName}}</b> hat dich in einer Antwort auf einen Kommentar in {{blabItemTitle}} erwähnt',
  blabItemActivityReplyToCommentMentionByAutomation:
    '<b>Automatisierung</b> hat dich in einer Antwort auf einen Kommentar in {{blabItemTitle}} erwähnt',
  blabItemActivityReplyToUpdate: '<b>{{authorName}}</b> hat auf ein Update in {{blabItemTitle}} geantwortet',
  blabItemActivityReplyToUpdateByAutomation:
    '<b>Automatisierung</b> hat auf ein Update in {{blabItemTitle}} geantwortet',
  blabItemActivityReplyToUpdateMention:
    '<b>{{authorName}}</b> hat dich in einer Antwort auf ein Update in {{blabItemTitle}} erwähnt',
  blabItemActivityReplyToUpdateMentionByAutomation:
    '<b>Automatisierung</b> hat dich in einer Antwort auf ein Update in {{blabItemTitle}} erwähnt',

  // BlabItemActivityReply
  blabItemActivityReply: '<b>{{authorName}}</b> auf {{blabItemTitle}} geantwortet',
  blabItemActivityReplyMention: '<b>{{authorName}}</b> hat dich in einer Antwort in {{blabItemTitle}} erwähnt',
  // BlabItemCommentActivity
  blabItemComment: '<b>{{authorName}}</b> hat {{blabItemTitle}} kommentiert',
  blabItemCommentByAutomation: '<b>Automatisierung</b> hat {{blabItemTitle}} kommentiert',
  blabItemCommentMention: '<b>{{authorName}}</b> hat dich in einem Kommentar in {{blabItemTitle}} erwähnt',
  blabItemCommentMentionByAutomation: '<b>Automatisierung</b> hat dich in einem Kommentar in {{blabItemTitle}} erwähnt',
  // BlabItemCreationActivity
  blabItemCreated: '<b>{{authorName}}</b> hat {{blabItemTitle}} erstellt',
  blabItemCreatedViaPublicInboundEmail: '<b>E-Mail</b> hat {{blabItemTitle}} erstellt',
  blabItemCreatedByAutomation: '<b>Automatisierung</b> hat {{blabItemTitle}} erstellt',
  // BlabItemDeletionActivity
  blabItemDeleted: '<b>{{authorName}}</b> hat {{blabItemTitle}} gelöscht',
  blabItemDeletedByAutomation: '<b>{{Automatisierung}}</b> hat {{blabItemTitle}} gelöscht',
  // BlabItemDeletionActivity
  blabItemRestored: '<b>{{authorName}}</b> hat {{blabItemTitle}} wiederhergestellt',
  blabItemRestoredByAutomation: '<b>Automatisierung</b> hat {{blabItemTitle}} wiederhergestellt',
  // InvitationActivity
  blabItemInvitation: '<b>{{authorName}}</b> hat dich zu {{blabItemTitle}} eingeladen',
  blabItemInvitationByAutomation: '<b>Automation</b> hat dich zu {{blabItemTitle}} eingeladen',
  // BlabItemRevisionActivity
  blabItemRevision: '<b>{{authorName}}</b> hat {{blabItemTitle}} aktualisiert',
  blabItemRevisionByAutomation: '<b>Automatisierung</b> hat {{blabItemTitle}} aktualisiert',
  // Title calculation
  blabItemTitle: '{{blabDefName}} <b>{{blabItemTitle}}</b>',
  // ## FlimValAttachment
  flimValAttachmentCreated: '<b>{{authorName}}</b> hat Datei "{{attachmentName}}" in {{blabItemTitle}} hinzugefügt',
  flimValAttachmentDeleted: '<b>{{authorName}}</b> hat Datei "{{prevAttachmentName}}" in {{blabItemTitle}} entfernt',
  flimValAttachmentReplaced:
    '<b>{{authorName}}</b> hat Datei "{{prevAttachmentName}}" durch Datei "{{attachmentName}}" in {{blabItemTitle}} ersetzt',
  flimValAttachmentsCreated: '<b>{{authorName}}</b> hat Dateien "{{attachmentNames}}" in {{blabItemTitle}} hinzugefügt',
  // ## Checklist FlimVal
  flimValChecklistActiveUserAdded:
    '<b>{{authorName}}</b> hat dir <b>{{checklistEntryName}}</b> unter {{flimDefName}} in {{blabItemTitle}} zugewiesen',
  flimValChecklistActiveUserRemoved:
    '<b>{{authorName}}</b> hat dich von <b>{{checklistEntryName}}</b> unter {{flimDefName}} in {{blabItemTitle}} als Zugewiesen entfernt.',
  flimValChecklistCompleted:
    '<b>{{authorName}}</b> hat <b>{{checklistEntryName}}</b> unter {{flimDefName}} in {{blabItemTitle}} als erledigt markiert',
  flimValChecklistIncompleted:
    '<b>{{authorName}}</b> hat <b>{{checklistEntryName}}</b> unter {{flimDefName}} in {{blabItemTitle}} als unerledigt markiert',
  flimValChecklistTitlePlaceholder: 'Unbenannt',
  // ## User FlimVal
  flimValUserActiveUserAdded: '<b>{{authorName}}</b> hat dich als {{flimDefName}} in {{blabItemTitle}} hinzugefügt',
  flimValUserActiveUserRemoved: '<b>{{authorName}}</b> hat dich als {{flimDefName}} in {{blabItemTitle}} entfernt',
  untitledBlabItemTitle: '<b>Neuen {{blabDef}}</b>',
} as const;
