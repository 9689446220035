import { BlabItemRevisionType } from '@t5s/shared/gql';

export const blabItemRevisionActivityLangDeClient = {
  [BlabItemRevisionType.SINGLE_TEXT]: {
    created: 'Hat {{flimDefName}} "{{value}}" hinzugefügt',
    deleted: 'Hat {{flimDefName}} "{{prevValue}}" gelöscht',
    updated: 'Hat {{flimDefName}} von "{{prevValue}}" auf "{{value}}" geändert',
  },
  [BlabItemRevisionType.MULTI_TEXT]: {
    created: 'Hat {{flimDefName}} hinzugefügt',
    deleted: 'Hat {{flimDefName}} gelöscht',
    updated: 'Hat {{flimDefName}} geändert',
  },
  [BlabItemRevisionType.SINGLE_CATEGORY]: {
    created: 'Hat {{flimDefName}} auf "{{value}}" gesetzt',
    deleted: 'Hat {{flimDefName}} "{{prevValue}}" entfernt',
    updated: 'Hat {{flimDefName}} von "{{prevValue}}" auf "{{value}}" geändert',
  },
  [BlabItemRevisionType.MULTI_CATEGORY]: {
    created: 'Hat {{flimDefName}} auf "{{value}}" gesetzt',
    deleted: 'Hat {{flimDefName}} "{{prevValue}}" entfernt',
    updatedAdd: 'Hat {{flimDefName}} "{{value}}" zu "{{prevValue}}" hinzugefügt',
    updatedRemove: 'Hat {{flimDefName}} "{{value}}" von "{{prevValue}}" entfernt',
  },
  [BlabItemRevisionType.SINGLE_RELATION]: {
    created: 'Hat "{{value}}" als {{flimDefName}} hinzugefügt',
    deleted: 'hat "{{prevValue}}" als {{flimDefName}} entfernt',
    updated: 'Hat "{{prevValue}}" durch "{{value}}" als {{flimDefName}} ersetzt',
  },
  [BlabItemRevisionType.MULTI_RELATION]: {
    created: 'Hat "{{value}}" als {{flimDefName}} hinzugefügt',
    deleted: 'Hat "{{prevValue}}" als {{flimDefName}} gelöscht',
    updatedAdd: 'Hat "{{value}}" zu "{{prevValue}}" als {{flimDefName}} hinzugefügt',
    updatedRemove: 'Hat "{{value}}" von "{{prevValue}}" als {{flimDefName}} entfernt',
  },
  [BlabItemRevisionType.INCOMING_RELATION]: {
    created: 'Hat {{value}} als {{flimDefName}} in "{{blabItemTitle}}" hinzugefügt',
    deleted: 'Hat {{value}} als {{flimDefName}} in "{{blabItemTitle}}" entfernt',
  },
  [BlabItemRevisionType.SINGLE_ATTACHMENT]: {
    created: 'Hat Datei "{{value}}" zu {{flimDefName}} hinzugefügt',
    deleted: 'Hat Datei "{{prevValue}}" aus {{flimDefName}} entfernt',
    updated: 'Hat Datei "{{prevValue}}" durch Datei "{{value}}" in {{flimDefName}} ersetzt',
  },
  [BlabItemRevisionType.MULTI_ATTACHMENT]: {
    created: 'Hat Datei "{{value}}" zu {{flimDefName}} hinzugefügt',
    deleted: 'Hat Datei "{{prevValue}}" aus {{flimDefName}} entfernt',
    updatedAdd: 'Hat Datei "{{value}}" zu Dateien "{{prevValue}}" in {{flimDefName}} hinzugefügt',
    updatedRemove: 'Hat Datei "{{value}}" aus Dateien "{{prevValue}}" in {{flimDefName}} entfernt',
  },
  [BlabItemRevisionType.SINGLE_USER]: {
    created: 'Hat "{{value}}" als {{flimDefName}} hinzugefügt',
    deleted: 'Hat "{{prevValue}}" als {{flimDefName}} entfernt',
    updated: 'Hat "{{prevValue}}" durch "{{value}}" als {{flimDefName}} ersetzt',
  },
  [BlabItemRevisionType.MULTI_USER]: {
    created: 'Hat "{{value}}" als {{flimDefName}} hinzugefügt',
    deleted: 'Hat "{{prevValue}}" als {{flimDefName}} entfernt',
    updatedAdd: 'Hat "{{value}}" zu "{{prevValue}}" als {{flimDefName}} hinzugefügt',
    updatedRemove: 'Hat "{{value}}" von "{{prevValue}}" als {{flimDefName}} entfernt',
  },
  [BlabItemRevisionType.SINGLE_DATE]: {
    created: 'Hat {{flimDefName}} auf "{{value}}" gesetzt',
    deleted: 'Hat {{flimDefName}} "{{prevValue}}" entfernt',
    updated: 'Hat {{flimDefName}} auf "{{value}}" geändert',
  },
  [BlabItemRevisionType.RANGE_DATE]: {
    created: 'Hat {{flimDefName}} auf "{{value}}" gesetzt',
    deleted: 'Hat {{flimDefName}} "{{prevValue}}" entfernt',
    updated: 'Hat {{flimDefName}} auf "{{value}}" geändert',
  },
} as const;
