export const loginEnLangClient = {
  email: 'Email address',
  emailPlaceholder: 'you@example.com',
  enterCredentials: 'Enter your email address and password.',
  error: {
    invalidCredentials: 'Sorry, you entered an incorrect email address or password',
    invalidEmail: 'Please enter a valid email address',
    invalidPassword: 'Please enter your password',
    userDeactivated: `This account is deactivated.`,
  },
  forgotPassword: `Forgot password?`,
  login: 'Sign in',
  logInToYourOrganization: 'Sign in to {{orgName}}',
  password: 'Password',
  passwordPlaceholder: 'Your password',
  signinAnotherOrganization: 'Sign in to another organization',
  yourEmailAddress: 'Your email address',
} as const;
