export const organizationPlanLangEnClient = {
  planInfo: {
    activityLimitInfo:
      'It has used {{activityCount}} of its {{activityLimit}} activity limit ({{activityPercentage}}%).',
    freePlanInfo: 'Your organization is currently on the free version of Tape',
    seeUpgradeOptions: 'See upgrade options',
  },
  planNames: {
    BASIC: 'Basic',
    ENTERPRISE: 'Enterprise',
    FREE: 'Free',
    STANDARD: 'Premium',
  },
  planOverview: {
    closeBtnTooltip: 'Close',
    contactSales: 'Contact Sales',
    enterpriseIndividual: 'Individual',
    features: {
      sectionTitle: 'Features',
      appsAndWorkspaces: 'Apps and workspaces',
      focus: 'Focus and checklist field',
      user: 'User management',
      editorUserRole: 'Editor user role',
      customizable: 'Full customizability',
      workflows: 'Advanced workflow automations',
      support: 'Priority support',
    },
    perMonth: 'per month',
    perUser: 'per user',
    priceBillingPeriodInfoDescr: '{{price}} per active user when paying month to month',
    priceBillingPeriodInfoTitle: 'Per active user, per month, when paying once a year',
    title: 'Plan overview',
    unlimited: 'Unlimited',
    upgrade: 'Upgrade',
    usage: {
      sectionTitle: 'Usage',
      activities: 'Activities',
      fileUploads: 'File uploads',
      records: 'Records',
      members: 'Members',
      revisionHistory: 'Revision History',
      automationActions: 'Automation actions',
    },
  },
};
