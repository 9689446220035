export const reminderEnLangClient = {
  closeBtnTooltip: 'Close',
  detail: {
    backBtnTooltip: 'Back',
    blabItemPreview: {
      removeBtnTooltip: 'Remove',
    },
    completeBtnTooltip: 'Mark complete',
    descPlaceholder: 'Add details',
    dueAtBadge: {
      removeBtnTooltip: 'Remove',
    },
    dueAtPlaceholder: 'Add due date',
    incompleteBtnTooltip: 'Mark incomplete',
    itemPlaceholder: 'Add item',
    removeBtnTooltip: 'Delete',
    titlePlaceholder: 'Add title',
  },
  displaySettings: {
    APP: 'Related app',
    DUE_DATE: 'Due date',
    USER_ORDER: 'My order',
  },
  menu: {
    archiveAllCompleted: 'Archive all completed',
    creationDate: 'Creation date',
    dueAt: 'Due date',
    hideArchived: 'Hide archived',
    myOrder: 'My order',
    relatedApp: 'Related app',
    showArchived: 'Show archived',
    sortBy: 'Sort by',
  },
  newReminderBtnTooltip: 'New reminder',
  newReminderModal: {
    descriptionPlaceholder: 'Add details',
    titlePlaceholder: 'New reminder',
  },
  placeholder: {
    subline: `You've finished all your reminders.`,
    title: 'Awesome!',
  },
  preview: {
    archiveBtnTooltip: 'Archive',
    checkIconBtnTooltip: 'Mark complete',
    editBtnTooltip: 'Edit details',
    unarchiveBtnTooltip: 'Unarchive',
    uncheckIconBtnTooltip: 'Mark incomplete',
  },
  sectionHeadlines: {
    archived: `Archived ({{count}})`,
    completed: `Completed ({{count}})`,
  },
  title: `Reminders`,
} as const;
