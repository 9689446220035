export const userSettingsEnLangClient = {
  account: {
    email: {
      confirmPassword: 'Your password for confirmation',
      confirmPasswordErrorIncorrect: 'Your password is incorrect',
      confirmPasswordErrorNone: 'Your password is required',
      confirmPasswordPlaceholder: 'Your current password',
      currentEmail: 'Current email address',
      forgotPassword: 'Forgot password?',
      newEmail: 'New email address',
      newEmailErrorInvalid: `New email must be a valid email address`,
      newEmailErrorUnavailable: `New email address is being used by another account `,
      newEmailPlaceholder: 'you@example.com',
      next: 'Next',
      successToast: 'Your email address has been successfully updated.',
      title: 'Change email address',
    },
    emailVerification: {
      title: 'We sent you a code!',
      tryAnotherEmail: 'Try another email address',
      verificationCode: 'Verification code',
      verificationCodeErrorIncorrect: 'Verification code is invalid, just try again',
      verificationCodeErrorNone: 'Verification code is required',
      verificationCodeLabel: 'Enter it here to verify {{email}}.',
      verificationCodePlaceholder: 'Verification code',
      verifyBtn: 'Verify',
    },
    formats: {
      dateFormat: 'Date format',
      decimalFormat: 'Decimal symbol',
      decimalFormats: {
        DECIMAL_SEPARATOR_COMMA: ',',
        DECIMAL_SEPARATOR_DOT: '.',
      },
      measurementSystem: 'Measurement units',
      measurementSystems: {
        METRIC: 'Metric',
        IMPERIAL_US: 'US',
      },
      firstWeekday: 'First weekday',
      language: 'Language',
      languagePlaceholder: 'Select a language',
      setAutomatically: 'Set time zone automatically',
      timeFormat: 'Time format',
      timeFormat12h: '12 hours',
      timeFormat24h: '24 hours',
      timeZone: 'Time zone',
      autoApplyTimezoneLabel: 'Set time zone automatically',
      timezoneDescription:
        'Tape uses your time zone to send summary and notification emails and as default for new automations.',
      title: 'Language and formats',
      weekday: {
        MONDAY: 'Monday',
        SATURDAY: 'Saturday',
        SUNDAY: 'Sunday',
      },
    },
    password: {
      currentPassword: 'Current password',
      currentPasswordError: 'Current password is incorrect',
      currentPasswordErrorNone: 'Current password is required',
      currentPasswordPlaceholder: 'Your current password',
      forgotPassword: 'Forgot password?',
      newPassword: 'New password',
      newPasswordConfirm: 'Confirm password',
      newPasswordConfirmMatchError: `The passwords you entered don't match`,
      newPasswordConfirmPlaceholder: 'Minimum of 6 characters',
      newPasswordErrorCommon: 'New password must be stronger',
      newPasswordErrorNone: 'New password is required',
      newPasswordErrorShort: 'New password must be at least 6 characters long',
      newPasswordPlaceholder: 'Minimum of 6 characters',
      save: 'Save',
      successToast: 'Your password has been successfully changed.',
      title: 'Change password',
    },

    accountSecurity: {
      headline: 'Account security',

      mfaLabel: '2-step verification',
      mfaDisabledSublabel: 'Add an additional layer of security to your account during login.',
      mfaEnabledSublabel: 'You have 2 verification methods turned on.',
      mfaDisabledButtonLabel: 'Add verification methods',
      mfaEnabledButtonLabel: 'Change verification methods',
    },
  },
  closeBtnTooltip: 'Close',
  connectedAccounts: {
    title: 'Connected Accounts',
  },
  notifications: {
    browserNotifications: 'Desktop notifications',
    browserNotificationsBlocked: 'Desktop notifications have been disabled in your browser',
    browserNotificationsInlineLabel: 'Turn on desktop notifications',
    browserNotificationsNote: `Get notifications to find out what’s going on when you’re not on Tape.`,
    browserNotificationsNoteBlocked:
      'To get desktop notifications from Tape, you’ll need to allow them in your browser settings first.',

    emailNotificationsTitle: 'Email notifications',

    userEmailNotificationsWhenOffline: {
      title: 'Important activity in your organization',
      note: `Receive emails when you get mentions, records assigned and checklist items assigned`,
    },

    userEmailNotificationsWhenOnline: {
      title: 'Always send email notifications',
      note: `Receive emails about important activity in your organization, even when you're active in Tape`,
    },

    emailNotifications: 'Away updates',
    emailNotificationsNote: `Receive emails every 2 hours for new notifications and direct messages when you’re away`,

    placeholderText: `To get notifications from Tape, you’ll need to allow them in your browser settings first.`,
    placeholderTitle: `Turn on notifications?`,
    title: 'Notifications',
  },
  sidemenu: {
    about: 'About Tape',
    account: {
      email: 'Email address',
      language: 'Language and formats',
      password: 'Password',
      security: 'Security',
      sectionName: 'Account',
    },
    api: 'API',
    connectedAccounts: 'Connected accounts',
    notifications: 'Notifications',
    profile: 'Profile',
  },
  title: 'Preferences',
} as const;
