import { colorDeLangClient } from './color/color.de';
import { countryDeLangClient } from './country/country.de';
import { languageDeLangClient } from './language/language.de';
import { stateDeLangClient } from './state/state.de';
import { TimezoneDeLangClient } from './timezone/timezone.de';

export const coreDeLangClient = {
  calendar: {
    dayIndex: `Tag {{index}}/{{duration}}`,
    from: `Von {{time}}`,
    until: `Bis {{time}}`,
  },
  cancel: `Abbrechen`,
  color: colorDeLangClient,
  confirmationUnsavedChanges: {
    confirmButtonLabel: `Verwerfen`,
    message: `Beim Verlassen dieses Fensters gehen alle ungespeicherten Änderungen verloren.`,
    rejectButtonLabel: `Zurück`,
    title: `Änderungen verwerfen?`,
  },
  country: countryDeLangClient,
  dropdown: {
    noSearchMatch: 'Kein Ergebnis für "{{searchText}}"',
  },
  language: languageDeLangClient,
  state: stateDeLangClient,
  timeStamps: {
    hourDelta: `{{delta}} Std.`,
    minuteDelta: `{{delta}} Min.`,
    noDate: `Kein Datum`,
    now: `Jetzt`,
    past: `Überfällig`,
    secondDelta: `{{delta}} Sek.`,
    today: `Heute`,
    tomorrow: `Morgen`,
    tomorrowTime: `Morgen {{time}}`,
    yesterday: `Gestern`,
    yesterdayTime: `Gestern {{time}}`,
  },
  timezone: TimezoneDeLangClient,
  userStatus: {
    offline: 'Abwesend',
    offline_snoozed: 'Benachrichtigungen pausiert',
    online: 'Aktiv',
    online_snoozed: 'Benachrichtigungen pausiert',
  },
};
