import { Inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { BROWSER_LANGUAGE } from '@t5s/web-client/provider/browser-language';
import { catchErrorDispatch } from '@t5s/client/util/store';
import { Language } from '@t5s/shared/gql';
import { TypeGuards } from '@t5s/shared/gql-type-guards/common';
import { LocalStorageService } from '@t5s/web-client/service/local-storage';
import { combineLatest, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { InternationalizationActions } from './internationalization.actions';

const LANGUAGE_KEY = `i18n-language`;

const { isLanguage } = TypeGuards;

function selectLanguageForBootstrap([browserLanguage, localStorageLanguage]: [
  Language,
  Language | undefined,
]): Language {
  if (!localStorageLanguage) {
    return browserLanguage;
  }

  return localStorageLanguage;
}

@Injectable()
export class InternationalizationEffects {
  constructor(
    private readonly actions$: Actions,
    @Inject(BROWSER_LANGUAGE) private readonly browserLanguage: Language,
    private readonly localStorage: LocalStorageService,
  ) {}

  readonly bootstrapLanguage$ = createEffect(() =>
    combineLatest([
      of(this.browserLanguage),
      of(this.localStorage.read(LANGUAGE_KEY)).pipe(
        map((language) => {
          if (isLanguage(language)) {
            return language;
          }

          return undefined;
        }),
      ),
    ]).pipe(
      map(selectLanguageForBootstrap),
      map((language) => InternationalizationActions.bootstrapLanguage({ language })),
    ),
  );

  readonly writeLanguageLocalStorage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(InternationalizationActions.changeLanguage),
        switchMap(({ language }) => of(this.localStorage.write(LANGUAGE_KEY, language)).pipe(catchErrorDispatch())),
      ),
    { dispatch: false },
  );
}
