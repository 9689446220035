export const billingLangDeClient = {
  billingDetails: {
    additionalNotes: 'Zusätzliche Hinweise',
    additionalNotesExplanation:
      'Benutze dieses Feld für jegliche zusätzlichen Informationen, die auf deiner Abrechnung erscheinen sollen',
    additionalNotesPlaceholder: 'Zusätzliche Hinweise hinzufügen',
    billingDetails: 'Abrechnungsinformationen',
    city: 'Stadt',
    cityPlaceholder: 'Stadt hinzufügen',
    companyName: 'Name des Unternehmens',
    companyNamePlaceholder: 'Name des Unternehmens hinzufügen',
    country: 'Land',
    email: 'E-Mail-Adresse',
    emailPlaceholder: 'E-Mail-Adresse hinzufügen',
    firstName: 'Vorname',
    firstNamePlaceholder: 'Vorname hinzufügen',
    hasTaxId: 'Hast du eine USt-ID?',
    hasTaxIdNo: 'Nein',
    hasTaxIdYes: 'Ja',
    invoiceNote: 'Zusätzliche Hinweise',
    invoiceNoteExplanation:
      'Benutze dieses Feld für jegliche zusätzlichen Informationen, die auf deiner Abrechnung erscheinen sollen.',
    invoiceNotePlaceholder: 'Zusätzliche Hinweise hinzufügen',
    isBusinessPurchase: 'Ist dies ein geschäftlicher Kauf?',
    isBusinessPurchaseNo: 'Nein',
    isBusinessPurchaseYes: 'Ja',
    lastName: 'Nachname',
    lastNamePlaceholder: 'Nachname hinzufügen',
    postalCode: 'Postleitzahl',
    postalCodePlaceholder: 'Postleitzahl hinzufügen',
    stateProvinceRegion: 'Bundesland/Landkreis/Region',
    stateProvinceRegionPlaceholder: 'Bundesland/Landkreis/Region hinzufügen',
    streetAddress: 'Straße und Hausnummer',
    streetAddressPlaceholder: 'Straße und Hausnummer hinzufügen',
    suiteUnit: 'Wohnung/Einheit',
    suiteUnitPlaceholder: 'Wohnung/Einheit hinzufügen',
    taxId: 'USt-ID',
  },
  billingOverview: {
    accountStatus: 'Account-Status',
    accountStatusBillingEmail: 'Rechnungs-E-Mails werden an {{billingEmail}} gesendet',
    accountStatusCredit: 'Du hast Guthaben zur Verrechnung im Höhe von {{credit}}',
    accountStatusNumLicensesMany: 'Du bezahlst für {{numUsers}} Mitglieder',
    accountStatusNumLicensesOne: 'Du bezahlst für ein Mitglied',
    accountStatusPaymentMethod: 'Für zukünftige Zahlungen wird die Kreditkarte •••• {{lastFour}} verwendet',
    accountStatusUpcomingInvoice: 'Bei Verlängerung deines Abonnements werden {{amountTotal}} fällig',
    accountStatusUpcomingInvoiceWithTax:
      'Bei Verlängerung deines Abonnements werden {{amountTotal}} ({{amountNetto}} plus {{amountTax}}) fällig',
    activeBillingSubscriptionCancelled:
      'Deine Organisation wird am {{currentPeriodEnd}} auf den kostenlosen Plan zurückgestuft.',
    activeBillingSubscriptionHeadline: `Deine Organisation nutzt den {{planName}} Plan mit {{billingPeriod}} Bezahlung`,
    activeBillingSubscriptionHeadlineSuccessChange: `Abonnement geändert!`,
    activeBillingSubscriptionHeadlineSuccessNew: `Danke für deinen Kauf!`,
    activeBillingSubscriptionRenewal:
      'Dein Plan wird am {{currentPeriodEnd}} für {{upcomingInvoiceAmount}} verlängert.',
    basicPlanInfoHeadline: 'Jetzt auf den Basic Plan upgraden und erhalte',
    billingCustomerException: {
      title: 'Fehler',
      subtitle: 'Beim Laden der Zahlungsinformationen ist ein Fehler aufgetreten. Bitte kontaktiere den Support.',
    },
    billingPeriods: {
      MONTHLY: 'monatlicher',
      YEARLY: 'jährlicher',
    },
    changePlan: 'Plan ändern',
    freePlanHeadline: 'Deine Organisation nutzt den kostenlosen Plan',
    freePlanSubline: `Der kostenlose Plan hat ein Aktivitäten Limit. Deine Organisation hat bereits {{activityCount}} von {{activityLimit}} verfügbaren Aktivitäten aufgebraucht ({{activityLimitPercentage}}%).`,
    learnMore: 'Mehr erfahren',
    planOverviewBtn: 'Plan-Übersicht',
    previewChangesBtn: 'Änderungsvorschau',
    proPlanSubtitle: 'Für sehr große Unternehmen',
    proPlanTitle: 'Der Tape Pro Plan',
    standardPlanSubtitle: 'Für große Unternehmen',
    standardPlanTitle: 'Der Tape Standard Plan',
    upgrade: 'Upgrade',
    upgradeToPremium: 'Upgrade auf Premium',
  },
  billingPeriod: {
    billedAnnually: 'Jährliche Abrechnung',
    billedMonthly: 'Monatliche Abrechnung',
    renewsAnnually: 'Wird jährlich verlängert',
    renewsMonthly: 'Wird monatlich verlängert',
  },
  cancel: {
    cancel: 'Cancel',
    confirmCancel: 'Downgrade Tape',
    headline: 'Do you really want to downgrade?',
    misses: {
      externalWorkspaces: 'External workspace sharing',
      guestAccounts: 'Guest accounts',
      premiumSupport: 'Premium support',
      unlimitedActivites: 'Unlimited activities and items',
      unlimitedAttachments: 'Unlimited attachment space',
    },
    missesHeadline: 'Here’s what your team will miss out on when you go back to the free version of Tape',
    subline: `If you choose to downgrade, your team will be on your current plan until {{currentPeriodEnd}}, at which point your team will
     be on the free version of Tape. If you have any credits remaining at that time, we’ll put the amount into your organizations
     Tape account. We’ll also let the other admins know that this organizations plan has changed.`,
    sublineImmediateCancel: `Your plan term ends on {{currentPeriodEnd}}. If you downgrade today, we will credit any unused time back to your organizations Tape account. We’ll also let the other admins know that this organizations plan has changed.`,
    title: 'Preview your plan changes',
  },
  changePlan: {
    backBtn: 'Zurück',
    confirmChangeBtn: 'Änderungen bestätigen',
    downgradeToPlan: 'Downgrade zum {{planName}} Plan.',
    renew: 'Abonnement verlängern',
    switchToAnnualyUponRenewal: 'Zum {{planName}} Plan (jährlich) wechseln.',
    switchToFreeImmediately: 'Sofort auf den kostenlosen Plan wechseln.',
    switchToFreePlanUponRenewal: 'Bei Verlängerung zum kostenlosen Plan wechseln.',
    switchToMonthlyUponRenewal: 'Zum {{planName}} Plan (monatlich) wechseln.',
    upgradeToPlan: 'Upgrade zum {{planName}} Plan.',
  },
  checkout: {
    billingDetails: 'Rechnungsinformationen',
    changeYourPlan: 'Plan ändern',
    dueToday: 'Heute fällig',
    editOrder: 'Bestellung ändern',
    orderDetails: 'Bestellinformationen',
    paymentDetails: 'Zahlungsinformationen',
    paymentMethod: 'Zahlungsmethoden',
    payWithAnotherCard: 'Mit einer anderen Karte bezahlen',
    purchase: 'Jetzt kaufen',
    reviewBtn: 'Bestellung überprüfen',
    reviewOrderPurchaseInfo: `You can cancel your plan at any time. Purchases made by credit card can’t be refunded, although your credit can be transferred to another account. By submitting this form, you confirm that you agree to our Terms of Service and Privacy Policy.`,
    reviewTitle: `Bestellung für {{orgName}} überprüfen`,
    successText: `Danke für dein Upgrade!`,
    successTitle: `Kauf erfolgreich`,
    total: 'Gesamtsumme',
    upgradeOrgToPlan: '{{orgName}} upgraden',
  },
  invoiceHistory: {
    invoiceAction: {
      contactSupport: 'Support kontaktieren',
      download: 'Rechnung herunterladen',
      retryPayment: 'Zahlung erneut versuchen',
    },
    invoiceDescription: 'Rechnung #{{number}} · {{amount}}',
    invoiceReason: 'Verlängerung Abonnement',
    noInvoicesPlaceholder: {
      subtitle: 'Wenn du auf den Basic-Plan upgradest, wird deine Rechnungs-Historie hier angezeigt.',
      title: 'Hier gibt es noch nichts zu sehen',
    },
    optionsBtnTooltip: 'Optionen',
    status: {
      PAID: 'Bezahlt',
      UNPAID: 'Nicht bezahlt',
      VOID: 'Storniert',
    },
    tableHeaderDetails: 'Details',
    tableHeaderName: 'Name',
    title: 'Rechnungen',
  },
  invoicePreview: {
    credit: 'Guthaben',
    creditReason: 'Faire Rechnungsstellung',
    debit: 'Schulden',
    debitReason: 'Kontoausgleich',
    discountReason: 'Coupon Code eingelöst',
    licenseDescriptionAnnualy: '{{unitAmount}} x {{numActiveUser}} Mitglieder x 1 Jahr',
    licenseDescriptionMonthly: '{{unitAmount}} x {{numActiveUser}} Mitglieder x 1 Monat',
    licenseTitle: '{{numActiveUser}} Mitglieder',
    proration: 'Ungenutzte Zeit',
    prorationReason: 'Faire Rechnungsstellung',
    tax: {
      b2bTaxablePurchase: 'Geschäftlicher Kauf',
      b2cPurchase: 'Kein geschäftlicher Kauf',
      taxIdApplied: 'Ust-ID: {{taxId}}',
      taxIdMissing: 'Ust-ID nicht vorhanden',
    },
  },
  paymentMethod: {
    addBtn: 'Karte hinzufügen',
    addNewCard: 'Neue Karte hinzufügen',
    addNewCardInfo:
      'Deine Karte wird zu diesem Zeitpunkt nicht belastet. Sie wird von uns hinterlegt und als Standardzahlungsmethode für zukünftige Zahlungen verwendet.',
    card: {
      cardNumber: 'Kartennummer',
      cvv: 'CVC',
      cvvInfo: '3 Ziffern auf der Rückseite',
      error: 'Bitte prüfe deine Angaben',
      expiration: 'Ablaufdatum',
      name: 'Name auf der Karte',
      postalCode: 'PLZ',
      secureForm: 'Sicheres Formular',
    },
    existing: 'Hinterlegte Karten',
    makeDefault: 'Als Standard setzen',
    paymentMethod: 'Zahlungsmethoden',
    remove: 'Karte entfernen',
    removeConfirmationModal: {
      confirmButtonLabel: 'Remove',
      message: 'Are you sure you want to remove this payment method from your account?',
      title: 'Remove payment method?',
    },
    removeLastConfirmationModal: {
      confirmButtonLabel: 'Remove',
      message: 'If you remove this payment method, you’ll need to add another before the next payment is due.',
      title: 'Remove last payment method?',
    },
  },
  renew: {
    title: 'Abonnement verlängern',
  },
  tax: {
    vat: 'Umsatzsteuer',
  },
} as const;
