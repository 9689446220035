import { Language } from '@t5s/shared/gql';
import { LangObjType } from '@t5s/shared/i18n/common';
import { RecursiveStringCast } from '@t5s/shared/types';
import { deLangWebClient } from './de';
import { enLangWebClient } from './en';

export type WebClientLangObjType = RecursiveStringCast<typeof enLangWebClient>;

export const webClientLanguagesDict: { [key in Language]: LangObjType & WebClientLangObjType } = {
  DE: deLangWebClient,
  EN: enLangWebClient,
};
