export const organizationSignupDeLangClient = {
  end: {
    continueBtnLabel: 'Weiter',
    inputPlaceholder: 'z.B. Fiktion-Marketing oder Fiktion GmbH',
    invalidOrganizationNameError: 'Bitte gib einen Namen für deine Tape-Organisation ein',
    subtitle: 'Dies wird der Name deiner Tape-Organisation. Du kannst den Namen jederzeit wieder leicht ändern.',
    title: 'Wie lautet der Name deines Unternehmens oder Teams?',
  },
  splashScreen: {
    title: 'Deine Organisation wird erstellt',
  },
  start: {
    createAccountBtnLabel: 'Konto erstellen',
    email: 'E-Mail-Adresse',
    emailError: 'Bitte gib eine gültige E-Mail-Adresse ein',
    emailPlaceholder: 'du@beispiel.de',
    name: 'Name',
    nameError: 'Bitte gib einen Namen ein',
    namePlaceholder: 'Dein vollständiger Name',
    optInPromotionalEmails: 'Erhalte E-Mails über Tipps, Neuigkeiten und Angebote zu Tape.',
    password: 'Passwort',
    passwordError: {
      none: `Bitte gib ein Passwort ein`,
      TOO_COMMON: `Bitte gib ein stärkeres Passwort ein`,
      TOO_SHORT: `Dein Passwort muss mindestens 6 Zeichen lang sein`,
    },
    passwordPlaceholder: 'Mindestens 6 Zeichen',
    termsAndConditionsNotice: {
      anchor1terms: {
        href: 'https://get.tapeapp.com/de/datenschutz/',
        label: 'Nutzungsbedingungen',
      },
      anchor1to2separator: ' und unserer',
      anchor2privacy: {
        href: 'https://get.tapeapp.com/de/datenschutz/',
        label: 'Datenschutzrichtlinie',
      },
      anchor3cookie: {
        href: 'https://get.tapeapp.com/de/datenschutz/',
        label: 'Cookie-Richtlinie',
      },
      and: ' und unserer ',
      final: ' zu.',
      start: 'Indem du ein Konto erstellst, stimmst du unseren ',
    },
    title: 'Erstelle zunächst dein Tape-Konto',
  },
  verification: {
    codeExceededError: `Der Code ist leider abgelaufen oder du hast die maximale Anzahl
    an Versuchen erreicht`,
    continueBtnLabel: 'Weiter',
    inputPlaceholder: 'Verifizierungscode',
    invalidCodeError: 'Dieser Code ist ungültig, versuche es einfach noch einmal',
    noCodeError: 'Bitte gib deinen Code ein.',
    retrySignup: 'Eine neue Tape-Organisation erstellen',
    subtitle:
      'Gib ihn hier zur Verifizierung von {{email}} ein. Wenn du keine E-Mail erhalten hast, überprüfe bitte auch deinen Spam-Ordner.',
    title: 'Wir haben dir einen Code gesendet!',
  },
};
