import { audioRoomNotificationLangDeClient } from './audio-room-notification/audio-room-notification.de';
import { blabDefNotificationLangDeClient } from './blab-def-notification/blab-def-notification.de';
import { blabItemNotificationLangDeClient } from './blab-item-notification/blab-item-notification.de';
import { userGroupNotificationLangDeClient } from './user-group-notification/user-group-notification.de';
import { userNotificationLangDeClient } from './user-notification/user-notification.de';
import { workflowDefNotificationLangDeClient } from './workflow-def-notification/workflow-def-notification.de';
import { workspaceNotificationLangDeClient } from './workspace-notification/workspace-notification.de';

export const notificationsDeLangClient = {
  audioRoom: audioRoomNotificationLangDeClient,
  workflowDef: workflowDefNotificationLangDeClient,
  blabDef: blabDefNotificationLangDeClient,
  userGroup: userGroupNotificationLangDeClient,
  blabItem: blabItemNotificationLangDeClient,
  filteredBy: 'Gefiltert nach',
  filterToggleBtnTooltip: 'Filter',
  menu: {
    markAllAsRead: 'Alle als gelesen markieren',
    notificationSettings: 'Einstellungen',
  },
  notificationTabTitle: 'Benachrichtigungen',
  placeholder: {
    noNotifications: {
      subtitle: `Wenn du Benachrichtigungen für einen Eintrag oder ein Mitglied aktivierst, findest du hier alles was passiert.`,
      title: 'Hier gibt es noch nichts zu sehen',
    },
    noUnreadNotifications: {
      subtitle: `Bitte lösche deinen Filter.`,
      title: 'Du hast keine ungelesenen Benachrichtigungen',
    },
    noYouNotifications: {
      subtitle: `Wenn dich jemand erwähnt oder zu einem Eintrag hinzufügt, siehst du es hier.`,
      title: 'Hier gibt es noch nichts zu sehen',
    },
  },
  tabs: {
    all: 'Alle',
    you: 'Du',
  },
  toggleMarkReadBtnTooltip: 'Als gelesen markieren',
  toggleMarkUnreadBtnTooltip: 'Als ungelesen markieren',
  unreadFilter: 'Ungelesen',
  user: userNotificationLangDeClient,
  workspace: workspaceNotificationLangDeClient,
} as const;
