export const organizationSignupEnLangClient = {
  end: {
    continueBtnLabel: 'Next',
    inputPlaceholder: 'Ex: Acme Marketing or Acme Co',
    invalidOrganizationNameError: 'Please enter a name for your Tape organization',
    subtitle: 'This will be the name of your Tape organization. You can easily change the name at any time.',
    title: 'What’s the name of your company or team?',
  },
  splashScreen: {
    title: 'Your organization is being created',
  },
  start: {
    createAccountBtnLabel: 'Create account',
    email: 'Email address',
    emailError: 'Please enter a valid email address',
    emailPlaceholder: 'you@example.com',
    name: 'Name',
    nameError: 'Please enter a name',
    namePlaceholder: 'Your full name',
    optInPromotionalEmails: 'Receive email with Tape tips, news, and offers.',
    password: 'Password',
    passwordError: {
      none: `Please enter a password`,
      TOO_COMMON: `Please enter a stronger password`,
      TOO_SHORT: `Your password must be at least 6 characters long`,
    },
    passwordPlaceholder: 'Minimum of 6 characters',
    termsAndConditionsNotice: {
      anchor1terms: {
        href: 'https://get.tapeapp.com/privacy-policy/',
        label: 'Terms & Conditions',
      },
      anchor1to2separator: ' and ',
      anchor2privacy: {
        href: 'https://get.tapeapp.com/privacy-policy/',
        label: 'Privacy Policy',
      },
      anchor3cookie: {
        href: 'https://get.tapeapp.com/privacy-policy/',
        label: 'Cookie Policy',
      },
      and: ' and ',
      final: '.',
      start: 'By creating an account, you’re agreeing to our ',
    },
    title: 'First, create your Tape account',
  },
  verification: {
    codeExceededError: `Unfortunately the code has expired or you have reached the maximum number of attempts`,
    continueBtnLabel: 'Next',
    inputPlaceholder: 'Verification code',
    invalidCodeError: 'This code is invalid, just try again',
    noCodeError: 'Please enter your code',
    retrySignup: 'Create a new Tape organization',
    subtitle: `Enter it here to verify {{email}}. If you didn't receive an email, please also check your spam folder.`,
    title: 'We sent you a code!',
  },
};
