export const blabDefCreateEnLangClient = {
  backBtnTooltip: 'Back',
  closeBtnTooltip: 'Close',
  importBtnTooltip: 'Import',
  importV2BtnTooltip: 'Import V2',
  icon: {
    searchPlaceholder: 'Search for an icon',
    title: 'App icon',
  },
  info: {
    appName: 'App name',
    appNamePlaceholder: 'Ex: Tasks',
    cancelBtnLabel: 'Cancel',
    createBtnLabel: 'Create',
    icon: 'App icon',
    itemName: 'Record name',
    itemNamePlaceholder: 'Ex: Task',
    title: 'Create a new app',
  },
  method: {
    custom: {
      subtitle: 'Go to the app template to create your own app in minutes.',
      title: 'Create a new app',
    },
    import: {
      subtitle: 'Meanwhile just start to create your own app in minutes.',
      title: 'Data import is coming soon!',
    },
    market: {
      subtitle: 'Meanwhile just start to create your own app in minutes.',
      title: 'Tape app market is coming soon!',
    },
    title: 'Add an app',
  },
};
