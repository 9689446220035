import {
  ActiveActorContextUnion,
  ActiveActorType,
  ActiveUserContext,
} from '@t5s/shared/value-object/active-actor-context';

export function isActiveUserContext(context: ActiveActorContextUnion | null | undefined): context is ActiveUserContext {
  return context?.type === ActiveActorType.USER;
}

/**
 * Get the active user context from the active actor context or undefined if the active actor context is not a user context.
 * @param context Active actor context
 * @returns Active user context or undefined
 */
export function getActiveUserContext(
  context: ActiveActorContextUnion | null | undefined,
): ActiveUserContext | undefined {
  return isActiveUserContext(context) ? context : undefined;
}

/**
 * Get the active user ID from the active actor context or undefined if the active actor context is not a user context.
 * @param actor Active actor context
 * @returns Active user ID or undefined
 */
export function getActiveUserId(actor: ActiveActorContextUnion | null | undefined): number | undefined {
  return getActiveUserContext(actor)?.activeUserId;
}
