export const workspaceEnLangClient = {
  confirmDeleteWorkspace: {
    confirmButtonLabel: `Delete`,
    message: `Are you sure you want to delete this workspace? This can’t be undone.`,
    title: `Delete workspace?`,
  },
  confirmLeaveWorkspace: {
    confirmButtonLabel: `Leave`,
    message: `Are you sure you want to leave this workspace?`,
    title: `Leave workspace?`,
  },
  leaveWorkspacRejectionToast: `Please give someone else Full Access before leaving this workspace.`,
  workspaceMenu: {
    addPeople: `Add people`,
    archive: `Archive workspace`,
    clone: `Duplicate workspace`,
    delete: `Delete workspace`,
    leave: `Leave workspace`,
    manageBlabDefs: `Manage apps`,
    manageMembers: `Manage members`,
    publishTemplate: `Publish to templates`,
    publishUpdateTemplate: `Publish template update`,
    removeTemplatePublication: `Remove from templates`,
    viewMembers: `View members`,
    workspacePage: `Workspace page`,
    workspaceSettings: `Workspace settings`,
    workflowCenterButton: `Automations`,
    new: `New`,
    addDescription: 'Add description',
    editDescription: 'Edit description',
  },
} as const;
