export const blabDefDeLangClient = {
  create: {
    createButton: `App erstellen`,
  },
  createBtnTooltip: 'App hinzufügen',
  createMoreMenu: {
    createBlank: 'Neue App',
    templates: 'Aus den Vorlagen wählen',
    import: 'Excel / CSV importieren',
  },
  dialog: {
    deleteBlabDef: {
      confirmButtonLabel: `Löschen`,
      lastBlabDefRemoveToast: `Die letzte App in einem Workspace kann nicht gelöscht werden.`,
      message: `Bist du sicher, dass du diese App löschen möchtest? Dies kann nicht rückgängig gemacht werden.`,
      title: `App löschen?`,
    },
  },
  flimDefs: {
    categoryFlimDef: {
      layout: {
        DROPDOWN: `Dropdown`,
        INLINE: `Einzeilig`,
        LIST: `Liste`,
      },
    },
  },
  moreMenu: {
    appPage: `App Seite`,
    archiveButton: `Archivieren`,
    cloneButton: `App duplizieren`,
    permissionSettings: `Berechtigungen verwalten`,
    deleteButton: `App löschen`,
    followButton: `App folgen`,
    unfollowButton: `App entfolgen`,
    editButton: `Felder anpassen`,
    settingsButton: `App-Einstellungen`,
    emailToApp: 'Email zu App',
    export: 'Export',
    import: 'Import Excel / CSV',
    developerInfo: 'Entwickler',
    workflowCenterButton: `Automatisierungen`,
    new: `Neu`,
  },
  notifications: {
    createBlabDefError: `Fehler beim Erstellen.`,
    createBlabDefSuccess: `App {{blabName}} erstellt.`,
    deleteBlabDefError: `App konnte nicht gelöscht werden.`,
    deleteBlabDefSuccess: `App gelöscht.`,
    updateBlabDefSuccess: `App aktualisiert.`,
  },
};
