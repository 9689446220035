import { AuthenticateWithCredentialsSuccessMfaRequiredDto } from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isAuthenticateWithCredentialsSuccessTwoFactorAuthRequiredDto(
  candidate: any,
): candidate is AuthenticateWithCredentialsSuccessMfaRequiredDto {
  return isDto<AuthenticateWithCredentialsSuccessMfaRequiredDto>(
    candidate,
    'AuthenticateWithCredentialsSuccessMfaRequiredDto',
  );
}

export function isAuthenticateWithCredentialsSuccessTwoFactorAuthRequiredDtoOrFail(
  candidate: any,
): AuthenticateWithCredentialsSuccessMfaRequiredDto {
  const result = isAuthenticateWithCredentialsSuccessTwoFactorAuthRequiredDto(candidate);

  if (!result) {
    throw new Error(`Candidate ${candidate} is not of type AuthenticateWithCredentialsSuccessMfaRequiredDto`);
  }

  return candidate;
}
