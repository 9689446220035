import { blabItemRevisionActivityLangDeClient } from '../activity/blab-item-revision-activity.de';

export const blabItemActivityDeLangClient = {
  activityPlaceholder: {
    subtitle:
      'Es wurden keine Kommentare zu diesem Eintrag gefunden. Um alle Aktivitäten zu sehen, entferne den Filter.',
    title: 'Keine Kommentare gefunden',
  },
  blabItemCreated: 'Hat {{title}} erstellt',
  blabItemDeleted: 'Hat {{title}} gelöscht',
  blabItemRestored: 'Hat {{title}} wiederhergestellt',
  confirmDeleteBlabItemComment: {
    confirmButtonLabel: `Löschen`,
    message: `Dies kann nicht rückgängig gemacht werden und Antworten auf diesen Kommentar werden ebenfalls entfernt.`,
    title: `Kommentar löschen?`,
  },
  dropzoneLabelComment: 'Hochladen in Kommentar',
  dropzoneLabelReply: 'Hochladen in Antwort',
  header: {
    activityHeadline: `Aktivitäten`,
    commentCount: `{{ count }} Kommentar`,
    commentsCount: `{{ count }} Kommentare`,
    filterCommentsSubline: 'Gefiltert nach: <em>Kommentare</em>',
    filterUpdatesSubline: 'Gefiltert nach: <em>Änderungen</em>',
    titleMenu: {
      allActivity: 'Alle Aktivitäten',
      onlyComments: 'Kommentare',
      onlyUpdates: 'Änderungen',
      title: 'Filtern nach',
    },
    updateCount: `{{ count }} Änderung`,
    updatesCount: `{{ count }} Änderungen`,
  },
  newReply: 'Neue Antwort beginnen',
  reply: {
    confirmDeleteReply: {
      confirmButtonLabel: `Löschen`,
      message: `Bist du sicher, dass du diese Antwort löschen möchtest? Dies kann nicht rückgängig gemacht werden.`,
      title: `Antwort löschen?`,
    },
    invalidFileSizeFileAddedToast: 'Eine oder mehrere Dateien überschreiten das Limit von 100 MB.',
    tooManyFilesAttachedToast: `Du kannst nur 4 Dateien auf einmal hochladen.`,
  },
  revertDialog: {
    confirmButtonLabel: `Wiederherstellen`,
    message: `Bist du sicher, dass du den vorherigen Wert wiederherstellen möchtest?`,
    title: `Vorherigen Wert wiederherstellen?`,
  },
  revision: blabItemRevisionActivityLangDeClient,
} as const;
