export const TimezoneDeLangClient = {
  Africa__Algiers: '(UTC+01:00) West-Zentralafrika',
  Africa__Cairo: '(UTC+02:00) Kairo',
  Africa__Casablanca: '(UTC) Casablanca',
  Africa__Harare: '(UTC+02:00) Harare, Pretoria',
  Africa__Monrovia: '(UTC) Monrovia, Reykjavik',
  Africa__Nairobi: '(UTC+03:00) Nairobi',
  Africa__Tripoli: '(UTC+02:00) Tripolis',
  Africa__Windhoek: '(UTC+01:00) Windhuk',
  America__Adak: '(UTC-10:00) Aleuten-Inseln',
  America__Anchorage: '(UTC-09:00) Alaska',
  America__Asuncion: '(UTC-04:00) Asuncion',
  America__Bahia: '(UTC-03:00) Salvador',
  America__Belize: '(UTC-06:00) Mittelamerika',
  America__Bogota: '(UTC-05:00) Bogotá, Lima, Quito, Rio Branco',
  America__Buenos_Aires: '(UTC-03:00) Buenos Aires',
  America__Cancun: '(UTC-05:00) Chetumal',
  America__Caracas: '(UTC-04:00) Caracas',
  America__Cayenne: '(UTC-03:00) Cayenne, Fortaleza',
  America__Chicago: '(UTC-06:00) Central Zeit (USA & Kanada)',
  America__Chihuahua: '(UTC-07:00) Chihuahua, La Paz, Mazatlan',
  America__Cuiaba: '(UTC-04:00) Cuiaba',
  America__Denver: '(UTC-07:00) Mountain Zeit (USA & Kanada), Navajo Nation',
  America__Fortaleza: '(UTC-03:00) Araguaína',
  America__Godthab: '(UTC-03:00) Grönland',
  America__Grand_Turk: '(UTC-04:00) Turks- und Caicosinseln',
  America__Halifax: '(UTC-04:00) Atlantic (Kanada)',
  America__Havana: '(UTC-05:00) Havanna',
  America__Indiana__Indianapolis: '(UTC-05:00) Indiana (Ost)',
  America__Los_Angeles: '(UTC-08:00) Pazifische Zeit (USA & Kanada)',
  America__Manaus: '(UTC-04:00) Georgetown, La Paz, Manaus, San Juan',
  America__Mexico_City: '(UTC-06:00) Guadalajara, Mexiko-Stadt, Monterrey',
  America__Miquelon: '(UTC-03:00) St. Pierre und Miquelon',
  America__Montevideo: '(UTC-03:00) Montevideo',
  America__New_York: '(UTC-05:00) Eastern Zeit (USA & Kanada)',
  America__Noronha: '(UTC-02:00) Fernando de Noronha',
  America__Phoenix: '(UTC-07:00) Arizona',
  America__Port0au0Prince: '(UTC-05:00) Haiti',
  America__Regina: '(UTC-06:00) Saskatchewan',
  America__Santiago: '(UTC-04:00) Santiago',
  America__Sao_Paulo: '(UTC-03:00) Brasilia',
  America__St_Johns: '(UTC-03:30) Neufundland',
  America__Tijuana: '(UTC-08:00) Niederkalifornien',
  Asia__Almaty: '(UTC+06:00) Astana',
  Asia__Amman: '(UTC+02:00) Amman',
  Asia__Anadyr: '(UTC+12:00) Anadyr, Petropawlowsk-Kamtschatski',
  Asia__Baghdad: '(UTC+03:00) Bagdad',
  Asia__Baku: '(UTC+04:00) Baku',
  Asia__Bangkok: '(UTC+07:00) Bangkok, Hanoi, Jakarta',
  Asia__Barnaul: '(UTC+07:00) Barnaul, Gorno-Altaisk',
  Asia__Beirut: '(UTC+02:00) Beirut',
  Asia__Chita: '(UTC+09:00) Chita',
  Asia__Chongqing: '(UTC+08:00) Peking, Chongqing, Hongkong (SAR), Urumchi',
  Asia__Colombo: '(UTC+05:30) Sri Jayawardenepura',
  Asia__Damascus: '(UTC+02:00) Damaskus',
  Asia__Dhaka: '(UTC+06:00) Dakka',
  Asia__Gaza: '(UTC+02:00) Gaza, Hebron',
  Asia__Hovd: '(UTC+07:00) Hovd',
  Asia__Irkutsk: '(UTC+08:00) Irkutsk',
  Asia__Istanbul: '(UTC+03:00) Istanbul',
  Asia__Jerusalem: '(UTC+02:00) Jerusalem',
  Asia__Kabul: '(UTC+04:30) Kabul',
  Asia__Karachi: '(UTC+05:00) Islamabad, Karatschi',
  Asia__Katmandu: '(UTC+05:45) Katmandu',
  Asia__Kolkata: '(UTC+05:30) Chennai, Kolkata, Mumbai, New Delhi',
  Asia__Krasnoyarsk: '(UTC+07:00) Krasnojarsk',
  Asia__Kuala_Lumpur: '(UTC+08:00) Kuala Lumpur, Singapur',
  Asia__Kuwait: '(UTC+03:00) Kuwait, Riad',
  Asia__Magadan: '(UTC+11:00) Magadan',
  Asia__Muscat: '(UTC+04:00) Abu Dhabi, Muskat',
  Asia__Novosibirsk: '(UTC+07:00) Nowosibirsk',
  Asia__Pyongyang: '(UTC+08:30) Pjöngjang',
  Asia__Rangoon: '(UTC+06:30) Yangon (Rangun)',
  Asia__Sakhalin: '(UTC+11:00) Sachalin',
  Asia__Seoul: '(UTC+09:00) Seoul',
  Asia__Srednekolymsk: '(UTC+11:00) Tschokurdach',
  Asia__Taipei: '(UTC+08:00) Taipeh',
  Asia__Tashkent: '(UTC+05:00) Aschgabat, Taschkent',
  Asia__Tbilisi: '(UTC+04:00) Tiflis',
  Asia__Tehran: '(UTC+03:30) Teheran',
  Asia__Shanghai: '(UTC+08:00) Shanghai, China',
  Asia__Tokyo: '(UTC+09:00) Osaka, Sapporo, Tokio',
  Asia__Tomsk: '(UTC+07:00) Tomsk',
  Asia__Ulaanbaatar: '(UTC+08:00) Ulan-Bator',
  Asia__Vladivostok: '(UTC+10:00) Wladiwostok',
  Asia__Yakutsk: '(UTC+09:00) Jakutsk',
  Asia__Yekaterinburg: '(UTC+05:00) Jekaterinburg',
  Asia__Yerevan: '(UTC+04:00) Eriwan',
  Atlantic__Azores: '(UTC-01:00) Azoren',
  Atlantic__Cape_Verde: '(UTC-01:00) Cabo Verde',
  Australia__Adelaide: '(UTC+09:30) Adelaide',
  Australia__Brisbane: '(UTC+10:00) Brisbane',
  Australia__Canberra: '(UTC+10:00) Canberra, Melbourne, Sydney',
  Australia__Darwin: '(UTC+09:30) Darwin',
  Australia__Eucla: '(UTC+08:45) Eucla',
  Australia__Hobart: '(UTC+10:00) Hobart',
  Australia__Lord_Howe: '(UTC+10:30) Lord-Howe-Insel',
  Australia__Perth: '(UTC+08:00) Perth',
  chooseATimezone: 'Eine Zeitzone auswählen',
  Europe__Amsterdam: '(UTC+01:00) Amsterdam, Berlin, Bern, Rom, Stockholm, Wien',
  Europe__Berlin: '(UTC+01:00) Amsterdam, Berlin, Bern, Rom, Stockholm, Wien',
  Europe__Astrakhan: '(UTC+04:00) Astrachan, Uljanowsk, Wolgograd',
  Europe__Athens: '(UTC+02:00) Athen, Bukarest',
  Europe__Belgrade: '(UTC+01:00) Belgrad, Bratislava (Pressburg), Budapest, Ljubljana, Prag',
  Europe__Brussels: '(UTC+01:00) Brüssel, Kopenhagen, Madrid, Paris',
  Europe__Helsinki: '(UTC+02:00) Helsinki, Kiew, Riga, Sofia, Tallinn, Wilna',
  Europe__Kaliningrad: '(UTC+02:00) Kaliningrad',
  Europe__London: '(UTC) Dublin, Edinburgh, Lissabon, London',
  Europe__Minsk: '(UTC+03:00) Minsk',
  Europe__Moscow: '(UTC+03:00) Moskau (Moskwa), St. Petersburg',
  Europe__Samara: '(UTC+04:00) Ischewsk, Samara',
  Europe__Warsaw: '(UTC+01:00) Sarajevo, Skopje, Warschau, Zagreb',
  Indian__Mauritius: '(UTC+04:00) Port Louis',
  Pacific__Apia: '(UTC+13:00) Samoa',
  Pacific__Auckland: '(UTC+12:00) Auckland, Wellington',
  Pacific__Bougainville: '(UTC+11:00) Bougainville-Insel',
  Pacific__Chatham: '(UTC+12:45) Chatham-Inseln',
  Pacific__Easter: '(UTC-06:00) Osterinsel',
  Pacific__Fiji: '(UTC+12:00) Fidschi',
  Pacific__Guadalcanal: '(UTC+11:00) Salomonen, Neu-Kaledonien',
  Pacific__Guam: '(UTC+10:00) Guam, Port Moresby',
  Pacific__Honolulu: '(UTC-10:00) Hawaii',
  Pacific__Kiritimati: '(UTC+14:00) Insel Kiritimati',
  Pacific__Marquesas: '(UTC-09:30) Marquesas-Inseln',
  Pacific__Midway: '(UTC-11:00) Midwayinseln, Amerikanisch-Samoa',
  Pacific__Norfolk: '(UTC+11:00) Norfolkinsel',
  Pacific__Tongatapu: "(UTC+13:00) Nuku'alofa",
  searchForATimezone: 'Nach Zeitzone suchen',
};
