export const setUserStatusDeLangClient = {
  deleteAfter: `Löschen nach`,
  inputPlaceholder: `Wie lautet dein Status?`,
  saveBtn: `Speichern`,
  statusSuggestions: {
    commuting: `Unterwegs`,
    inMeeting: `In einer Besprechung`,
    remote: `Home-Office`,
    sick: `Krank`,
    vacation: `Im Urlaub`,
  },
  title: `Status einstellen`,
  until: {
    ['THIRTY_MIN']: '30 Minuten',
    ['ONE_HOUR']: '1 Stunde',
    ['TODAY']: 'Heute',
    ['THIS_WEEK']: 'In dieser Woche',
    custom: 'Benutzerdefiniert',
    doNotClear: 'Nicht löschen',
  },
} as const;
