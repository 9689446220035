import { SetUserStatusUntilOptions } from '@t5s/web-client/value-object/user-status';

export const setUserStatusEnLangClient = {
  deleteAfter: `Clear after`,
  inputPlaceholder: `What's your status?`,
  saveBtn: `Save`,
  statusSuggestions: {
    commuting: `Commuting`,
    inMeeting: `In a meeting`,
    remote: `Working remotely`,
    sick: `Out sick`,
    vacation: `Vacationing`,
  },
  title: `Set a status`,
  until: {
    [SetUserStatusUntilOptions.THIRTY_MIN]: '30 min',
    [SetUserStatusUntilOptions.ONE_HOUR]: '1 hour',
    [SetUserStatusUntilOptions.TODAY]: 'Today',
    [SetUserStatusUntilOptions.THIS_WEEK]: 'This week',
    custom: 'Custom',
    doNotClear: 'Do not clear',
  } as {
    [key: string]: string;
  },
} as const;
