import { Language } from '@t5s/shared/gql';
import { LangObjType } from '@t5s/shared/i18n/common';
import { RecursiveStringCast } from '@t5s/shared/types';
import { InternationalizationDefaults } from '@t5s/shared/util/i18n';
import { deLangClient } from './de';
import { enLangClient } from './en';

export type ClientLangObjType = RecursiveStringCast<typeof enLangClient>;

export const clientLanguagesDict: { [key in Language]: LangObjType & ClientLangObjType } = {
  DE: deLangClient,
  EN: enLangClient,
};

export const defaultClientLanguageObj = clientLanguagesDict[InternationalizationDefaults.Language];
