export const blabItemDeLangClient = {
  dropzoneLabel: 'Hochladen in {{itemName}}',
  flimVal: {
    add: '{{flimDefName}} hinzufügen',
    attachment: {
      add: 'Hinzufügen',
      attachmentMetaInfo: 'Hinzugefügt von {{user}}',
      delete: 'Löschen',
      download: 'Herunterladen',
      dropFilesHere: 'Dateien hier loslassen',
      rename: 'Umbenennen',
    },
    multiLink: {},
    multiText: {
      add: `{{flimDefName}} hinzufügen`,
      format: `Für Formatierung '/' tippen oder Text markieren`,
    },
    relation: {
      addBlabItemReferenceMultiRelation: 'Hinzufügen',
      addBlabItemReferenceSingleRelation: 'Hinzufügen',
      chooseBlabDef: `App auswählen`,
      overlay: {
        addBtnLabel: `Hinzufügen`,
        createBtnLabel: `Neu erstellen`,
        placeholderText: `Leider konnten keine Inhalte mit diesem Namen in verbundenen Apps gefunden werden.`,
        placeholderTitle: `Keine Inhalte in verbundenen Apps gefunden!`,
      },
    },
    user: {
      addUserReferenceMultiUser: 'Hinzufügen',
      addUserReferenceSingleUser: 'Hinzufügen',
      overlay: {
        addBtnLabel: `Hinzufügen`,
        placeholderText: `In diesem Workspace konnte leider kein Mitglied mit diesem Namen gefunden werden.`,
        placeholderTitle: `Mitglied nicht gefunden!`,
      },
    },
    validationError: {
      END_DATE_REQUIRED: 'Startdatum und Enddatum sind erforderlich',
      END_DATE_TIME_REQUIRED: `Startdatum, Startzeit, Enddatum und Endzeit sind erforderlich`,
      IS_REQUIRED: '{{flimDefName}} ist erforderlich',
      TIME_REQUIRED: 'Zeit ist erforderlich',
      MUST_BE_NUMBER: '{{flimDefName}} muss eine Zahl sein',
      NUMBER_TOO_SMALL: '{{flimDefName}} unterschreitet den minimalen Wert des Zahlenfelds',
      NUMBER_TOO_LARGE: '{{flimDefName}} überschreitet den maximalen Wert des Zahlenfelds',
    },
  },
  notifications: {
    creationFailed: 'Erstellung fehlgeschlagen.',
  },
} as const;
