export const billingLangEnClient = {
  billingDetails: {
    additionalNotes: 'Additional notes',
    additionalNotesExplanation: `Use this field for any additional information you'd like to include on your billing statement`,
    additionalNotesPlaceholder: 'Add additional notes',
    billingDetails: 'Billing details',
    city: 'City',
    cityPlaceholder: 'Add city',
    companyName: 'Company Name',
    companyNamePlaceholder: 'Add company name',
    country: 'Country',
    email: 'Email address',
    emailPlaceholder: 'Add email address',
    firstName: 'First Name',
    firstNamePlaceholder: 'Add first name',
    hasTaxId: 'Do you have a VAT ID on hand?',
    hasTaxIdNo: 'No',
    hasTaxIdYes: 'Yes',
    invoiceNote: 'Additional notes',
    invoiceNoteExplanation:
      'Use this field for any additional information you’d like to include on your billing statement.',
    invoiceNotePlaceholder: 'Add additional notes',
    isBusinessPurchase: 'Is this a business purchase?',
    isBusinessPurchaseNo: 'No',
    isBusinessPurchaseYes: 'Yes',
    lastName: 'Last Name',
    lastNamePlaceholder: 'Add last name',
    postalCode: 'Postal Code',
    postalCodePlaceholder: 'Add postal code',
    stateProvinceRegion: 'State/Province/Region',
    stateProvinceRegionPlaceholder: 'Add state/provice/region',
    streetAddress: 'Street address',
    streetAddressPlaceholder: 'Add street address',
    suiteUnit: 'Suite/Unit',
    suiteUnitPlaceholder: 'Add suite/unit',
    taxId: 'VAT ID',
  },
  billingOverview: {
    accountStatus: 'Account status',
    accountStatusBillingEmail: 'Billing emails are sent to {{billingEmail}}',
    accountStatusCredit: 'You currently have a credit of {{credit}} to use against future payments',
    accountStatusNumLicensesMany: 'You are paying for {{numUsers}} members',
    accountStatusNumLicensesOne: 'You are paying for 1 member',
    accountStatusPaymentMethod: 'Future charges will be billed to the card •••• {{lastFour}}',
    accountStatusUpcomingInvoice: 'On renewal you will be charged {{amountTotal}}',
    accountStatusUpcomingInvoiceWithTax:
      'On renewal you will be charged {{amountTotal}} ({{amountNetto}} plus {{amountTax}})',
    activeBillingSubscriptionCancelled:
      'On the renewal date ({{currentPeriodEnd}}), your organization will revert to the Free plan.',
    activeBillingSubscriptionHeadline: `Your organization is on the {{planName}} plan with {{billingPeriod}} payment`,
    activeBillingSubscriptionHeadlineSuccessChange: `Change successful!`,
    activeBillingSubscriptionHeadlineSuccessNew: `Thanks for your purchase!`,
    activeBillingSubscriptionRenewal: 'Your plan will renew on {{currentPeriodEnd}} for {{upcomingInvoiceAmount}}.',
    basicPlanInfoHeadline: 'Upgrade to the Basic plan and get',
    billingCustomerException: {
      title: 'Error',
      subtitle: 'There was an error loading your subscription / payment plan info. Please contact support.',
    },
    billingPeriods: {
      MONTHLY: 'monthly',
      YEARLY: 'yearly',
    },
    changePlan: 'Change plan',
    freePlanHeadline: 'Your organization is on the Free plan',
    freePlanSubline: `The Free plan has an activity limit. Your organization has used {{activityCount}} of its {{activityLimit}} activity limit ({{activityLimitPercentage}}%).`,
    learnMore: 'Learn more',
    planOverviewBtn: 'Plan overview',
    previewChangesBtn: 'Preview changes',
    proPlanSubtitle: 'For very large businesses',
    proPlanTitle: 'The Tape Pro plan',
    standardPlanSubtitle: 'For larger businesses',
    standardPlanTitle: 'The Tape Standard plan',
    upgrade: 'Upgrade',
    upgradeToPremium: 'Upgrade to Premium',
  },
  billingPeriod: {
    billedAnnually: 'Billed annually',
    billedMonthly: 'Billed monthly',
    renewsAnnually: 'Renews annually',
    renewsMonthly: 'Renews monthly',
  },
  cancel: {
    cancel: 'Cancel',
    confirmCancel: 'Downgrade Tape',
    headline: 'Do you really want to downgrade?',
    misses: {
      externalWorkspaces: 'External workspace sharing',
      guestAccounts: 'Guest accounts',
      premiumSupport: 'Premium support',
      unlimitedActivites: 'Unlimited activities and items',
      unlimitedAttachments: 'Unlimited attachment space',
    },
    missesHeadline: 'Here’s what your team will miss out on when you go back to the free version of Tape',
    subline: `If you choose to downgrade, your team will be on your current plan until {{currentPeriodEnd}}, at which point your team will
     be on the free version of Tape. If you have any credits remaining at that time, we’ll put the amount into your organizations
     Tape account. We’ll also let the other admins know that this organizations plan has changed.`,
    sublineImmediateCancel: `Your plan term ends on {{currentPeriodEnd}}. If you downgrade today, we will credit any unused time back to your organizations Tape account. We’ll also let the other admins know that this organizations plan has changed.`,
    title: 'Preview your plan changes',
  },
  changePlan: {
    backBtn: 'Back',
    confirmChangeBtn: 'Confirm change',
    downgradeToPlan: 'Downgrade to {{planName}} plan.',
    renew: 'Renew subscription',
    switchToAnnualyUponRenewal: 'Switch to {{planName}} plan (yearly).',
    switchToFreeImmediately: 'Switch immediately to Free plan.',
    switchToFreePlanUponRenewal: 'Switch to Free plan upon renewal.',
    switchToMonthlyUponRenewal: 'Switch to {{planName}} plan (monthly).',
    upgradeToPlan: 'Upgrade to {{planName}} plan.',
  },
  checkout: {
    billingDetails: 'Billing details',
    changeYourPlan: 'Change your plan',
    dueToday: 'Due today',
    editOrder: 'Edit order',
    orderDetails: 'Order details',
    paymentDetails: 'Payment details',
    paymentMethod: 'Payment method',
    payWithAnotherCard: 'Pay with another card',
    purchase: 'Purchase',
    reviewBtn: 'Review Order',
    reviewOrderPurchaseInfo: `You can cancel your plan at any time. Purchases made by credit card can’t be refunded, although your credit can be transferred to another account. By submitting this form, you confirm that you agree to our Terms of Service and Privacy Policy.`,
    reviewTitle: `Review {{orgName}} order`,
    successText: `Thanks for your upgrade!`,
    successTitle: `Purchase successful`,
    total: 'Total',
    upgradeOrgToPlan: 'Upgrade {{orgName}}',
  },
  invoiceHistory: {
    invoiceAction: {
      contactSupport: 'Contact support',
      download: 'Download invoice',
      retryPayment: 'Retry payment',
    },
    invoiceDescription: 'Invoice #{{number}} · {{amount}}',
    invoiceReason: 'Subscription Renewal',
    noInvoicesPlaceholder: {
      subtitle: 'When you upgrade to the Basic plan, your invoice history will show up here.',
      title: 'Nothing to see here yet',
    },
    optionsBtnTooltip: 'Options',
    status: {
      PAID: 'Paid',
      UNPAID: 'Unpaid',
      VOID: 'Void',
    },
    tableHeaderDetails: 'Details',
    tableHeaderName: 'Name',
    title: 'Invoices',
  },
  invoicePreview: {
    credit: 'Credits',
    creditReason: 'Fair billing policy',
    debit: 'Debit',
    debitReason: 'Debit settlement',
    discountReason: 'Applied coupon code',
    licenseDescriptionAnnualy: '{{unitAmount}} x {{numActiveUser}} members x 1 year',
    licenseDescriptionMonthly: '{{unitAmount}} x {{numActiveUser}} members x 1 month',
    licenseTitle: '{{numActiveUser}} members',
    proration: 'Unused time',
    prorationReason: 'Fair billing policy',
    tax: {
      b2bTaxablePurchase: 'Business purchase',
      b2cPurchase: 'No business purchase',
      taxIdApplied: 'VAT ID: {{taxId}}',
      taxIdMissing: 'VAT ID is missing',
    },
  },
  paymentMethod: {
    addBtn: 'Add card',
    addNewCard: 'Add new card',
    addNewCardInfo:
      'Your card will not be charged at this time. It will be kept on file and will be used as the default payment method for future charges.',
    card: {
      cardNumber: 'Card number',
      cvv: 'CVC',
      cvvInfo: '3 digits on back',
      error: 'Please check your input',
      expiration: 'Expiry',
      name: 'Name on card',
      postalCode: 'Zip code',
      secureForm: 'Secure Form',
    },
    existing: 'Existing cards',
    makeDefault: 'Make default',
    paymentMethod: 'Payment methods',
    remove: 'Remove card',
    removeConfirmationModal: {
      confirmButtonLabel: 'Remove',
      message: 'Are you sure you want to remove this payment method from your account?',
      title: 'Remove payment method?',
    },
    removeLastConfirmationModal: {
      confirmButtonLabel: 'Remove',
      message: 'If you remove this payment method, you’ll need to add another before the next payment is due.',
      title: 'Remove last payment method?',
    },
  },
  renew: {
    title: 'Renew subscription',
  },
  tax: {
    vat: 'VAT',
  },
} as const;
