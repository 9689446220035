import { DateFormat } from '@t5s/shared/gql';
import { parseInteger } from '@t5s/shared/util/number';
import { isValid, parse, setDate, setMonth } from 'date-fns';
import { currentDate } from './current-date-time';

function matchSingleNumber(input: string, dateFormat: DateFormat, refDate: number): Date | undefined {
  const index = input.match(/\d/)?.index;
  if (index !== undefined) {
    const num = parseInteger(input.slice(index, index + 1));
    if (!num) {
      return undefined;
    }

    if (input.match(/\d\./)) {
      dateFormat = DateFormat.DOT_DD_MM_YYYY;
    } else if (input.match(/\d\//)) {
      dateFormat = DateFormat.SLASH_MM_DD_YYYY;
    }

    return dateFormat === DateFormat.DOT_DD_MM_YYYY ? setDate(refDate, num) : setMonth(refDate, num - 1);
  }
  return undefined;
}

function matchTwoNumbers(input: string, dateFormat: DateFormat, refDate: number): Date | undefined {
  // Two consecutive numbers -> must be a day or a month
  let index = input.match(/\d\d/)?.index;
  if (index !== undefined) {
    const num = parseInteger(input.slice(index, index + 2));
    if (num && num > 0) {
      if (num <= 12) {
        dateFormat =
          input[index + 2] === '.'
            ? DateFormat.DOT_DD_MM_YYYY
            : input[index + 2] === '/'
            ? DateFormat.SLASH_MM_DD_YYYY
            : dateFormat;

        return dateFormat === DateFormat.DOT_DD_MM_YYYY ? setDate(refDate, num) : setMonth(refDate, num - 1);
      } else if (num <= 31) {
        return setDate(refDate, num);
      }
      // Must be two separate numbers
      const num1 = parseInteger(input.slice(index, index + 1));
      const num2 = parseInteger(input.slice(index + 1, index + 2));
      if (!num1 || !num2) {
        return undefined;
      }
      return dateFormat === DateFormat.DOT_DD_MM_YYYY
        ? setDate(setMonth(refDate, num2 - 1), num1)
        : setDate(setMonth(refDate, num1 - 1), num2);
    }
  }

  // Two numbers separated by dot -> must be day.month
  index = input.match(/\d\.\d/)?.index;
  if (index !== undefined) {
    const num1 = parseInteger(input.slice(index, index + 1));
    const num2 = parseInteger(input.slice(index + 2, index + 3));
    if (!num1 || !num2) {
      return undefined;
    }
    return setMonth(setDate(refDate, num1), num2 - 1);
  }

  // Two numbers separated by slash -> must be month/day
  index = input.match(/\d\/\d/)?.index;
  if (index !== undefined) {
    const num1 = parseInteger(input.slice(index, index + 1));
    const num2 = parseInteger(input.slice(index + 2, index + 3));
    if (!num1 || !num2) {
      return undefined;
    }
    return setMonth(setDate(refDate, num2), num1 - 1);
  }

  return undefined;
}

function matchThreeNumbers(input: string, dateFormat: DateFormat, refDate: number): Date | undefined {
  // Dot format day.month
  let index = input.match(/[0-3]\d\.\d/)?.index;
  if (index !== undefined) {
    const day = parseInteger(input.slice(index, index + 2));
    const month = parseInteger(input.slice(index + 3, index + 4));
    if (!day || !month || day > 31) {
      return undefined;
    }
    return setMonth(setDate(refDate, day), month - 1);
  }

  index = input.match(/\d\.[0-1]\d/)?.index;
  if (index !== undefined) {
    const day = parseInteger(input.slice(index, index + 1));
    const month = parseInteger(input.slice(index + 2, index + 4));
    if (!day || !month || month > 12) {
      return undefined;
    }
    return setMonth(setDate(refDate, day), month - 1);
  }

  // Slash format month/day
  index = input.match(/[0-1]\d\/\d/)?.index;
  if (index !== undefined) {
    const month = parseInteger(input.slice(index, index + 2));
    const day = parseInteger(input.slice(index + 3, index + 4));
    if (!month || !day || month > 12) {
      return undefined;
    }
    return setMonth(setDate(refDate, day), month - 1);
  }

  index = input.match(/\d\/[0-3]\d/)?.index;
  if (index !== undefined) {
    const month = parseInteger(input.slice(index, index + 1));
    const day = parseInteger(input.slice(index + 2, index + 4));
    if (!month || !day || day > 31) {
      return undefined;
    }
    return setMonth(setDate(refDate, day), month - 1);
  }

  return undefined;
}

function matchFourNumbers(input: string, dateFormat: DateFormat, refDate: number): Date | undefined {
  // Dot format day.month
  let index = input.match(/[0-3]\d\.[0-1]\d/)?.index;
  if (index !== undefined) {
    const day = parseInteger(input.slice(index, index + 2));
    const month = parseInteger(input.slice(index + 3, index + 5));
    if (!day || !month || day > 31 || month > 12) {
      return undefined;
    }
    return setMonth(setDate(refDate, day), month - 1);
  }

  // Slash format month/day
  index = input.match(/[0-1]\d\/[0-3]\d/)?.index;
  if (index !== undefined) {
    const month = parseInteger(input.slice(index, index + 2));
    const day = parseInteger(input.slice(index + 3, index + 5));
    if (!month || !day || month > 12 || day > 31) {
      return undefined;
    }
    return setMonth(setDate(refDate, day), month - 1);
  }

  return undefined;
}

export function getDateFromInputStr(
  input: string | undefined,
  { dateFormat, referenceDate }: { dateFormat?: DateFormat; referenceDate?: Date } = {},
): Date | undefined {
  input = input || '';

  input = input.replace(/[^\d\.\/]/g, '');
  const refDate = referenceDate ? referenceDate.getTime() : currentDate().getTime();
  dateFormat = dateFormat ?? DateFormat.SLASH_MM_DD_YYYY;

  const amountNumbers = input.match(/\d/g)?.length || 0;

  // No numbers -> no valid date
  if (amountNumbers === 0) {
    return undefined;
  }

  let result: Date | undefined;

  switch (amountNumbers) {
    case 1:
      result = matchSingleNumber(input, dateFormat, refDate);
      break;
    case 2:
      result = matchTwoNumbers(input, dateFormat, refDate);
      break;
    case 3:
      result = matchThreeNumbers(input, dateFormat, refDate);
      break;
    case 4:
      result = matchFourNumbers(input, dateFormat, refDate);
      break;
  }

  if (result) {
    return result;
  }

  result = parse(input, `dd.MM.yy`, refDate);
  result = isValid(result) ? result : parse(input, `dd.MM.yyyy`, refDate);

  switch (dateFormat) {
    case DateFormat.SLASH_DD_MM_YYYY: {
      result = isValid(result) ? result : parse(input, `dd/MM/yy`, refDate);
      result = isValid(result) ? result : parse(input, `dd/MM/yyyy`, refDate);
      break;
    }

    default: {
      result = isValid(result) ? result : parse(input, `MM/dd/yy`, refDate);
      result = isValid(result) ? result : parse(input, `MM/dd/yyyy`, refDate);
    }
  }
  result = isValid(result) ? result : undefined;

  return result;
}
