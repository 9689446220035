export const organizationSettingsGeneralDeLangClient = {
  delete: 'Löschen',
  deleteOrganization: 'Organisation löschen',
  deleteOrganizationInfo:
    'Eine Tape Organisation zu löschen kann nicht rückgängig gemacht werden. Jegliche Inhalte und Dateien werden unwiderruflich gelöscht.',
  invitations: 'Einladungen',
  invitationsInfo:
    'Als Standardeinstellung kann jedes Mitglied neue Personen zu deiner Organisation einladen. Wenn du möchtest, kannst du das ändern, sodass Einladungen die Zustimmung eines Organisations-Admins erfordern.',
  invitationsLabel: 'Zustimmung eines Organisations-Admins verlangen',
  nameDisplay: 'Anzeige der Namen',
  nameDisplayLabel: 'Vollständige Namen anstelle von Anzeigenamen anzeigen',
  organizationLanguage: 'Organisationssprache',
  organizationLanguageInfo: 'Dies wirkt sich auf Systembenachrichtigungen und E-Mails bei der Anmeldung aus.',
  organizationLanguagePlaceholder: 'Organisationssprache auswählen',
};
