
      export interface IntrospectionResultData {
        __schema: {
          types: {
            kind: string;
            name: string;
            possibleTypes: {
              name: string;
            }[];
          }[];
        };
      }
      const result: IntrospectionResultData = {
  "__schema": {
    "types": [
      {
        "kind": "INTERFACE",
        "name": "I18nSettings",
        "possibleTypes": [
          {
            "name": "ActiveUserDto"
          },
          {
            "name": "UserSettingsDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ActiveUserBlabDefInfoActiveBlabViewUnionType",
        "possibleTypes": [
          {
            "name": "BlabViewDto"
          },
          {
            "name": "ActiveUserAutoSaveBlabViewDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "FlimFilterDto",
        "possibleTypes": [
          {
            "name": "CalculationFlimFilterDto"
          },
          {
            "name": "ChecklistFlimFilterDto"
          },
          {
            "name": "CreatedAtFlimFilterDto"
          },
          {
            "name": "CreatedByFlimFilterDto"
          },
          {
            "name": "LastModifiedAtFlimFilterDto"
          },
          {
            "name": "LastModifiedByFlimFilterDto"
          },
          {
            "name": "MultiAttachmentFlimFilterDto"
          },
          {
            "name": "MultiCategoryFlimFilterDto"
          },
          {
            "name": "MultiEmailFlimFilterDto"
          },
          {
            "name": "MultiImageFlimFilterDto"
          },
          {
            "name": "MultiLinkFlimFilterDto"
          },
          {
            "name": "MultiPhoneFlimFilterDto"
          },
          {
            "name": "MultiRelationFlimFilterDto"
          },
          {
            "name": "MultiTextFlimFilterDto"
          },
          {
            "name": "MultiUserFlimFilterDto"
          },
          {
            "name": "NumberFlimFilterDto"
          },
          {
            "name": "RangeDateFlimFilterDto"
          },
          {
            "name": "SingleAttachmentFlimFilterDto"
          },
          {
            "name": "SingleCategoryFlimFilterDto"
          },
          {
            "name": "SingleDateFlimFilterDto"
          },
          {
            "name": "SingleLocationFlimFilterDto"
          },
          {
            "name": "SingleRelationFlimFilterDto"
          },
          {
            "name": "SingleTextFlimFilterDto"
          },
          {
            "name": "SingleUserFlimFilterDto"
          },
          {
            "name": "StatusFlimFilterDto"
          },
          {
            "name": "UniqueIdFlimFilterDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FlimStatDefUnionType",
        "possibleTypes": [
          {
            "name": "SingleTextFlimStatDefDto"
          },
          {
            "name": "SingleUserFlimStatDefDto"
          },
          {
            "name": "SingleCategoryFlimStatDefDto"
          },
          {
            "name": "SingleAttachmentFlimStatDefDto"
          },
          {
            "name": "SingleRelationFlimStatDefDto"
          },
          {
            "name": "SingleDateFlimStatDefDto"
          },
          {
            "name": "RangeDateFlimStatDefDto"
          },
          {
            "name": "MultiRelationFlimStatDefDto"
          },
          {
            "name": "NumberFlimStatDefDto"
          },
          {
            "name": "StatusFlimStatDefDto"
          },
          {
            "name": "ChecklistFlimStatDefDto"
          },
          {
            "name": "MultiLinkFlimStatDefDto"
          },
          {
            "name": "CreatedAtFlimStatDefDto"
          },
          {
            "name": "CreatedByFlimStatDefDto"
          },
          {
            "name": "LastModifiedAtFlimStatDefDto"
          },
          {
            "name": "LastModifiedByFlimStatDefDto"
          },
          {
            "name": "MultiAttachmentFlimStatDefDto"
          },
          {
            "name": "MultiImageFlimStatDefDto"
          },
          {
            "name": "MultiTextFlimStatDefDto"
          },
          {
            "name": "MultiCategoryFlimStatDefDto"
          },
          {
            "name": "MultiUserFlimStatDefDto"
          },
          {
            "name": "CalculationFlimStatDefDto"
          },
          {
            "name": "MultiEmailFlimStatDefDto"
          },
          {
            "name": "MultiPhoneFlimStatDefDto"
          },
          {
            "name": "SingleLocationFlimStatDefDto"
          },
          {
            "name": "UniqueIdFlimStatDefDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "FlimStatDefDto",
        "possibleTypes": [
          {
            "name": "SingleTextFlimStatDefDto"
          },
          {
            "name": "SingleUserFlimStatDefDto"
          },
          {
            "name": "SingleCategoryFlimStatDefDto"
          },
          {
            "name": "SingleAttachmentFlimStatDefDto"
          },
          {
            "name": "SingleRelationFlimStatDefDto"
          },
          {
            "name": "SingleDateFlimStatDefDto"
          },
          {
            "name": "RangeDateFlimStatDefDto"
          },
          {
            "name": "MultiRelationFlimStatDefDto"
          },
          {
            "name": "NumberFlimStatDefDto"
          },
          {
            "name": "StatusFlimStatDefDto"
          },
          {
            "name": "ChecklistFlimStatDefDto"
          },
          {
            "name": "MultiLinkFlimStatDefDto"
          },
          {
            "name": "CreatedAtFlimStatDefDto"
          },
          {
            "name": "CreatedByFlimStatDefDto"
          },
          {
            "name": "LastModifiedAtFlimStatDefDto"
          },
          {
            "name": "LastModifiedByFlimStatDefDto"
          },
          {
            "name": "MultiAttachmentFlimStatDefDto"
          },
          {
            "name": "MultiImageFlimStatDefDto"
          },
          {
            "name": "MultiTextFlimStatDefDto"
          },
          {
            "name": "MultiCategoryFlimStatDefDto"
          },
          {
            "name": "MultiUserFlimStatDefDto"
          },
          {
            "name": "CalculationFlimStatDefDto"
          },
          {
            "name": "MultiEmailFlimStatDefDto"
          },
          {
            "name": "MultiPhoneFlimStatDefDto"
          },
          {
            "name": "SingleLocationFlimStatDefDto"
          },
          {
            "name": "UniqueIdFlimStatDefDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SplitByUnionType",
        "possibleTypes": [
          {
            "name": "SingleCategorySplitByDto"
          },
          {
            "name": "MultiCategorySplitByDto"
          },
          {
            "name": "SingleUserSplitByDto"
          },
          {
            "name": "MultiUserSplitByDto"
          },
          {
            "name": "SingleRelationSplitByDto"
          },
          {
            "name": "MultiRelationSplitByDto"
          },
          {
            "name": "StatusSplitByDto"
          },
          {
            "name": "SingleDateSplitByDto"
          },
          {
            "name": "RangeDateSplitByDto"
          },
          {
            "name": "CreatedAtSplitByDto"
          },
          {
            "name": "LastModifiedAtSplitByDto"
          },
          {
            "name": "CalculationSplitByDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "SplitBySectionDto",
        "possibleTypes": [
          {
            "name": "SingleCategorySplitBySectionDto"
          },
          {
            "name": "MultiCategorySplitBySectionDto"
          },
          {
            "name": "SingleUserSplitBySectionDto"
          },
          {
            "name": "MultiUserSplitBySectionDto"
          },
          {
            "name": "SingleRelationSplitBySectionDto"
          },
          {
            "name": "MultiRelationSplitBySectionDto"
          },
          {
            "name": "StatusSplitBySectionDto"
          },
          {
            "name": "SingleDateSplitBySectionDto"
          },
          {
            "name": "RangeDateSplitBySectionDto"
          },
          {
            "name": "CreatedAtSplitBySectionDto"
          },
          {
            "name": "LastModifiedAtSplitBySectionDto"
          },
          {
            "name": "CalculationSplitBySectionDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "CategoryOptionDefDto",
        "possibleTypes": [
          {
            "name": "SingleCategoryOptionDefDto"
          },
          {
            "name": "MultiCategoryOptionDefDto"
          },
          {
            "name": "StatusOptionDefDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FlimDefUnionType",
        "possibleTypes": [
          {
            "name": "SingleTextFlimDefDto"
          },
          {
            "name": "MultiTextFlimDefDto"
          },
          {
            "name": "SingleCategoryFlimDefDto"
          },
          {
            "name": "MultiCategoryFlimDefDto"
          },
          {
            "name": "SingleRelationFlimDefDto"
          },
          {
            "name": "MultiRelationFlimDefDto"
          },
          {
            "name": "SingleAttachmentFlimDefDto"
          },
          {
            "name": "MultiAttachmentFlimDefDto"
          },
          {
            "name": "SingleUserFlimDefDto"
          },
          {
            "name": "MultiUserFlimDefDto"
          },
          {
            "name": "SingleDateFlimDefDto"
          },
          {
            "name": "RangeDateFlimDefDto"
          },
          {
            "name": "ChecklistFlimDefDto"
          },
          {
            "name": "MultiLinkFlimDefDto"
          },
          {
            "name": "CreatedAtFlimDefDto"
          },
          {
            "name": "CreatedByFlimDefDto"
          },
          {
            "name": "LastModifiedAtFlimDefDto"
          },
          {
            "name": "LastModifiedByFlimDefDto"
          },
          {
            "name": "NumberFlimDefDto"
          },
          {
            "name": "StatusFlimDefDto"
          },
          {
            "name": "CalculationFlimDefDto"
          },
          {
            "name": "MultiEmailFlimDefDto"
          },
          {
            "name": "MultiPhoneFlimDefDto"
          },
          {
            "name": "MultiImageFlimDefDto"
          },
          {
            "name": "SingleLocationFlimDefDto"
          },
          {
            "name": "UniqueIdFlimDefDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "FlimDefDto",
        "possibleTypes": [
          {
            "name": "SingleTextFlimDefDto"
          },
          {
            "name": "MultiTextFlimDefDto"
          },
          {
            "name": "SingleCategoryFlimDefDto"
          },
          {
            "name": "MultiCategoryFlimDefDto"
          },
          {
            "name": "SingleRelationFlimDefDto"
          },
          {
            "name": "MultiRelationFlimDefDto"
          },
          {
            "name": "SingleAttachmentFlimDefDto"
          },
          {
            "name": "MultiAttachmentFlimDefDto"
          },
          {
            "name": "SingleUserFlimDefDto"
          },
          {
            "name": "MultiUserFlimDefDto"
          },
          {
            "name": "SingleDateFlimDefDto"
          },
          {
            "name": "RangeDateFlimDefDto"
          },
          {
            "name": "ChecklistFlimDefDto"
          },
          {
            "name": "MultiLinkFlimDefDto"
          },
          {
            "name": "CreatedAtFlimDefDto"
          },
          {
            "name": "CreatedByFlimDefDto"
          },
          {
            "name": "LastModifiedAtFlimDefDto"
          },
          {
            "name": "LastModifiedByFlimDefDto"
          },
          {
            "name": "NumberFlimDefDto"
          },
          {
            "name": "StatusFlimDefDto"
          },
          {
            "name": "CalculationFlimDefDto"
          },
          {
            "name": "MultiEmailFlimDefDto"
          },
          {
            "name": "MultiPhoneFlimDefDto"
          },
          {
            "name": "MultiImageFlimDefDto"
          },
          {
            "name": "SingleLocationFlimDefDto"
          },
          {
            "name": "UniqueIdFlimDefDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "CategoryFlimDefDto",
        "possibleTypes": [
          {
            "name": "SingleCategoryFlimDefDto"
          },
          {
            "name": "MultiCategoryFlimDefDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "RelationFlimDefDto",
        "possibleTypes": [
          {
            "name": "SingleRelationFlimDefDto"
          },
          {
            "name": "MultiRelationFlimDefDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "BlabViewBoardColumnUnionType",
        "possibleTypes": [
          {
            "name": "BlabViewBoardStatusColumnDto"
          },
          {
            "name": "BlabViewBoardSingleCategoryColumnDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BlabViewBoardColumnDto",
        "possibleTypes": [
          {
            "name": "BlabViewBoardStatusColumnDto"
          },
          {
            "name": "BlabViewBoardSingleCategoryColumnDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BlabViewApplicableConfiguration",
        "possibleTypes": [
          {
            "name": "ActiveUserAutoSaveBlabViewDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ActiveUserSplitBySectionUnionType",
        "possibleTypes": [
          {
            "name": "ActiveUserSingleCategorySplitBySectionDto"
          },
          {
            "name": "ActiveUserMultiCategorySplitBySectionDto"
          },
          {
            "name": "ActiveUserSingleUserSplitBySectionDto"
          },
          {
            "name": "ActiveUserMultiUserSplitBySectionDto"
          },
          {
            "name": "ActiveUserSingleRelationSplitBySectionDto"
          },
          {
            "name": "ActiveUserMultiRelationSplitBySectionDto"
          },
          {
            "name": "ActiveUserStatusSplitBySectionDto"
          },
          {
            "name": "ActiveUserSingleDateSplitBySectionDto"
          },
          {
            "name": "ActiveUserRangeDateSplitBySectionDto"
          },
          {
            "name": "ActiveUserCreatedAtSplitBySectionDto"
          },
          {
            "name": "ActiveUserLastModifiedAtSplitBySectionDto"
          },
          {
            "name": "ActiveUserCalculationSplitBySectionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CalendarEventUnionType",
        "possibleTypes": [
          {
            "name": "BlabItemCalendarEventDto"
          },
          {
            "name": "ReminderCalendarEventDto"
          },
          {
            "name": "ChecklistEntryCalendarEventDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "CalendarEventDto",
        "possibleTypes": [
          {
            "name": "BlabItemCalendarEventDto"
          },
          {
            "name": "ReminderCalendarEventDto"
          },
          {
            "name": "ChecklistEntryCalendarEventDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ActiveUserDevPortalContextUnionType",
        "possibleTypes": [
          {
            "name": "ActiveUserDevPortalContextDto"
          },
          {
            "name": "ActiveUserDevPortalRejectionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GetBillingSubscriptionResult",
        "possibleTypes": [
          {
            "name": "ActiveBillingSubscriptionDto"
          },
          {
            "name": "IncompleteBillingSubscriptionDto"
          },
          {
            "name": "NoActiveOrIncompleteBillingSubscriptionDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BillingSubscriptionDto",
        "possibleTypes": [
          {
            "name": "ActiveBillingSubscriptionDto"
          },
          {
            "name": "IncompleteBillingSubscriptionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "InvoiceItemUnionType",
        "possibleTypes": [
          {
            "name": "LicenseInvoiceItemDto"
          },
          {
            "name": "ProrationInvoiceItemDto"
          },
          {
            "name": "DiscountInvoiceItemDto"
          },
          {
            "name": "TaxInvoiceItemDto"
          },
          {
            "name": "CreditInvoiceItemDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "InvoiceItemDto",
        "possibleTypes": [
          {
            "name": "LicenseInvoiceItemDto"
          },
          {
            "name": "ProrationInvoiceItemDto"
          },
          {
            "name": "DiscountInvoiceItemDto"
          },
          {
            "name": "TaxInvoiceItemDto"
          },
          {
            "name": "CreditInvoiceItemDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "PaymentMethodUnionType",
        "possibleTypes": [
          {
            "name": "CardPaymentMethodDto"
          },
          {
            "name": "SepaDebitPaymentMethodDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "PaymentMethodDto",
        "possibleTypes": [
          {
            "name": "CardPaymentMethodDto"
          },
          {
            "name": "SepaDebitPaymentMethodDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "SearchResultDto",
        "possibleTypes": [
          {
            "name": "BlabDefSearchResultDto"
          },
          {
            "name": "BlabItemSearchResultDto"
          },
          {
            "name": "ChatGroupSearchResultDto"
          },
          {
            "name": "UserSearchResultDto"
          },
          {
            "name": "WorkspaceSearchResultDto"
          },
          {
            "name": "ChatMessageSearchResultDto"
          },
          {
            "name": "RelationFlimDefBlabDefSearchResultDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "FlimValDto",
        "possibleTypes": [
          {
            "name": "MultiAttachmentFlimValDto"
          },
          {
            "name": "SingleAttachmentFlimValDto"
          },
          {
            "name": "MultiCategoryFlimValDto"
          },
          {
            "name": "SingleCategoryFlimValDto"
          },
          {
            "name": "RangeDateFlimValDto"
          },
          {
            "name": "SingleDateFlimValDto"
          },
          {
            "name": "MultiImageFlimValDto"
          },
          {
            "name": "SingleLocationFlimValDto"
          },
          {
            "name": "NumberFlimValDto"
          },
          {
            "name": "MultiRelationFlimValDto"
          },
          {
            "name": "SingleRelationFlimValDto"
          },
          {
            "name": "StatusFlimValDto"
          },
          {
            "name": "MultiTextFlimValDto"
          },
          {
            "name": "SingleTextFlimValDto"
          },
          {
            "name": "MultiUserFlimValDto"
          },
          {
            "name": "SingleUserFlimValDto"
          },
          {
            "name": "CalculationFlimValDto"
          },
          {
            "name": "ChecklistFlimValDto"
          },
          {
            "name": "CreatedAtFlimValDto"
          },
          {
            "name": "CreatedByFlimValDto"
          },
          {
            "name": "MultiLinkFlimValDto"
          },
          {
            "name": "LastModifiedByFlimValDto"
          },
          {
            "name": "LastModifiedAtFlimValDto"
          },
          {
            "name": "MultiEmailFlimValDto"
          },
          {
            "name": "MultiPhoneFlimValDto"
          },
          {
            "name": "UniqueIdFlimValDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "BlabItemActivityUnionType",
        "possibleTypes": [
          {
            "name": "BlabItemCommentActivityDto"
          },
          {
            "name": "BlabItemRevisionActivityDto"
          },
          {
            "name": "BlabItemCreatedActivityDto"
          },
          {
            "name": "BlabItemDeletedActivityDto"
          },
          {
            "name": "BlabItemRestoredActivityDto"
          },
          {
            "name": "BlabItemInvitationCreatedActivityDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BlabItemActivityDto",
        "possibleTypes": [
          {
            "name": "BlabItemCommentActivityDto"
          },
          {
            "name": "BlabItemRevisionActivityDto"
          },
          {
            "name": "BlabItemCreatedActivityDto"
          },
          {
            "name": "BlabItemDeletedActivityDto"
          },
          {
            "name": "BlabItemRestoredActivityDto"
          },
          {
            "name": "BlabItemInvitationCreatedActivityDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "FileDto",
        "possibleTypes": [
          {
            "name": "BlabItemActivityReplyAttachmentDto"
          },
          {
            "name": "BlabItemCommentAttachmentDto"
          },
          {
            "name": "FlimValAttachmentDto"
          },
          {
            "name": "FlimValImageDto"
          },
          {
            "name": "ChatMessageAttachmentDto"
          },
          {
            "name": "UserPostAttachmentDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "BlabItemRevisionUnionType",
        "possibleTypes": [
          {
            "name": "SingleTextFlimValRevisionDto"
          },
          {
            "name": "MultiTextFlimValRevisionDto"
          },
          {
            "name": "SingleCategoryFlimValRevisionDto"
          },
          {
            "name": "MultiCategoryFlimValRevisionDto"
          },
          {
            "name": "SingleRelationFlimValRevisionDto"
          },
          {
            "name": "MultiRelationFlimValRevisionDto"
          },
          {
            "name": "IncomingRelationRevisionDto"
          },
          {
            "name": "SingleAttachmentFlimValRevisionDto"
          },
          {
            "name": "MultiAttachmentFlimValRevisionDto"
          },
          {
            "name": "MultiImageFlimValRevisionDto"
          },
          {
            "name": "SingleUserFlimValRevisionDto"
          },
          {
            "name": "MultiUserFlimValRevisionDto"
          },
          {
            "name": "SingleDateFlimValRevisionDto"
          },
          {
            "name": "RangeDateFlimValRevisionDto"
          },
          {
            "name": "ChecklistFlimValRevisionDto"
          },
          {
            "name": "MultiLinkFlimValRevisionDto"
          },
          {
            "name": "NumberFlimValRevisionDto"
          },
          {
            "name": "StatusFlimValRevisionDto"
          },
          {
            "name": "MultiEmailFlimValRevisionDto"
          },
          {
            "name": "MultiPhoneFlimValRevisionDto"
          },
          {
            "name": "SingleLocationFlimValRevisionDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "FlimValRevisionDto",
        "possibleTypes": [
          {
            "name": "SingleTextFlimValRevisionDto"
          },
          {
            "name": "MultiTextFlimValRevisionDto"
          },
          {
            "name": "SingleCategoryFlimValRevisionDto"
          },
          {
            "name": "MultiCategoryFlimValRevisionDto"
          },
          {
            "name": "SingleRelationFlimValRevisionDto"
          },
          {
            "name": "MultiRelationFlimValRevisionDto"
          },
          {
            "name": "SingleAttachmentFlimValRevisionDto"
          },
          {
            "name": "MultiAttachmentFlimValRevisionDto"
          },
          {
            "name": "MultiImageFlimValRevisionDto"
          },
          {
            "name": "SingleUserFlimValRevisionDto"
          },
          {
            "name": "MultiUserFlimValRevisionDto"
          },
          {
            "name": "SingleDateFlimValRevisionDto"
          },
          {
            "name": "RangeDateFlimValRevisionDto"
          },
          {
            "name": "ChecklistFlimValRevisionDto"
          },
          {
            "name": "MultiLinkFlimValRevisionDto"
          },
          {
            "name": "NumberFlimValRevisionDto"
          },
          {
            "name": "StatusFlimValRevisionDto"
          },
          {
            "name": "MultiEmailFlimValRevisionDto"
          },
          {
            "name": "MultiPhoneFlimValRevisionDto"
          },
          {
            "name": "SingleLocationFlimValRevisionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FlimDefRevisionPreviewUnionType",
        "possibleTypes": [
          {
            "name": "FlimDefRevisionPreviewDto"
          },
          {
            "name": "CategoryFlimDefRevisionPreviewDto"
          },
          {
            "name": "NumberFlimDefRevisionPreviewDto"
          },
          {
            "name": "StatusFlimDefRevisionPreviewDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ReminderUnionType",
        "possibleTypes": [
          {
            "name": "ReminderDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FlimStatResultUnionType",
        "possibleTypes": [
          {
            "name": "SingleTextFlimStatResultDto"
          },
          {
            "name": "MultiTextFlimStatResultDto"
          },
          {
            "name": "SingleCategoryFlimStatResultDto"
          },
          {
            "name": "MultiCategoryFlimStatResultDto"
          },
          {
            "name": "SingleUserFlimStatResultDto"
          },
          {
            "name": "MultiUserFlimStatResultDto"
          },
          {
            "name": "SingleAttachmentFlimStatResultDto"
          },
          {
            "name": "MultiAttachmentFlimStatResultDto"
          },
          {
            "name": "SingleRelationFlimStatResultDto"
          },
          {
            "name": "MultiRelationFlimStatResultDto"
          },
          {
            "name": "SingleDateFlimStatResultDto"
          },
          {
            "name": "RangeDateFlimStatResultDto"
          },
          {
            "name": "StatusFlimStatResultDto"
          },
          {
            "name": "MultiLinkFlimStatResultDto"
          },
          {
            "name": "LastModifiedAtFlimStatResultDto"
          },
          {
            "name": "LastModifiedByFlimStatResultDto"
          },
          {
            "name": "CreatedAtFlimStatResultDto"
          },
          {
            "name": "CreatedByFlimStatResultDto"
          },
          {
            "name": "NumberFlimStatResultDto"
          },
          {
            "name": "ChecklistFlimStatResultDto"
          },
          {
            "name": "CalculationFlimStatResultDto"
          },
          {
            "name": "MultiEmailFlimStatResultDto"
          },
          {
            "name": "MultiPhoneFlimStatResultDto"
          },
          {
            "name": "MultiImageFlimStatResultDto"
          },
          {
            "name": "SingleLocationFlimStatResultDto"
          },
          {
            "name": "UniqueIdFlimStatResultDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "FlimStatResultDto",
        "possibleTypes": [
          {
            "name": "SingleTextFlimStatResultDto"
          },
          {
            "name": "MultiTextFlimStatResultDto"
          },
          {
            "name": "SingleCategoryFlimStatResultDto"
          },
          {
            "name": "MultiCategoryFlimStatResultDto"
          },
          {
            "name": "SingleUserFlimStatResultDto"
          },
          {
            "name": "MultiUserFlimStatResultDto"
          },
          {
            "name": "SingleAttachmentFlimStatResultDto"
          },
          {
            "name": "MultiAttachmentFlimStatResultDto"
          },
          {
            "name": "SingleRelationFlimStatResultDto"
          },
          {
            "name": "MultiRelationFlimStatResultDto"
          },
          {
            "name": "SingleDateFlimStatResultDto"
          },
          {
            "name": "RangeDateFlimStatResultDto"
          },
          {
            "name": "StatusFlimStatResultDto"
          },
          {
            "name": "MultiLinkFlimStatResultDto"
          },
          {
            "name": "LastModifiedAtFlimStatResultDto"
          },
          {
            "name": "LastModifiedByFlimStatResultDto"
          },
          {
            "name": "CreatedAtFlimStatResultDto"
          },
          {
            "name": "CreatedByFlimStatResultDto"
          },
          {
            "name": "NumberFlimStatResultDto"
          },
          {
            "name": "ChecklistFlimStatResultDto"
          },
          {
            "name": "CalculationFlimStatResultDto"
          },
          {
            "name": "MultiEmailFlimStatResultDto"
          },
          {
            "name": "MultiPhoneFlimStatResultDto"
          },
          {
            "name": "MultiImageFlimStatResultDto"
          },
          {
            "name": "SingleLocationFlimStatResultDto"
          },
          {
            "name": "UniqueIdFlimStatResultDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ConversationSearchResultUnionType",
        "possibleTypes": [
          {
            "name": "ChatGroupSearchResultDto"
          },
          {
            "name": "UserSearchResultDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ConversationDto",
        "possibleTypes": [
          {
            "name": "ChatGroupDto"
          },
          {
            "name": "ChatDialogDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ChatActivityDto",
        "possibleTypes": [
          {
            "name": "ChatMessageActivityDto"
          },
          {
            "name": "ChatVideoCallActivityDto"
          },
          {
            "name": "ConversationCreateActivityDto"
          },
          {
            "name": "ChatGroupJoinActivityDto"
          },
          {
            "name": "ChatGroupLeaveActivityDto"
          },
          {
            "name": "ChatGroupNameChangeActivityDto"
          },
          {
            "name": "ChatGroupThumbnailChangeActivityDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchResultUnionType",
        "possibleTypes": [
          {
            "name": "BlabDefSearchResultDto"
          },
          {
            "name": "BlabItemSearchResultDto"
          },
          {
            "name": "WorkspaceSearchResultDto"
          },
          {
            "name": "UserSearchResultDto"
          },
          {
            "name": "ChatMessageSearchResultDto"
          },
          {
            "name": "ChatGroupSearchResultDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SearchHistoryItemUnionType",
        "possibleTypes": [
          {
            "name": "SearchHistoryItemQueryDto"
          },
          {
            "name": "SearchHistoryItemResultDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "SearchHistoryItemDto",
        "possibleTypes": [
          {
            "name": "SearchHistoryItemQueryDto"
          },
          {
            "name": "SearchHistoryItemResultDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GetLocationAutocompleteSuggestionsResultUnionType",
        "possibleTypes": [
          {
            "name": "LocationAutocompleteSuggestionsDto"
          },
          {
            "name": "GetLocationAutocompleteSuggestionsRejectionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GetLocationDetailsResultUnionType",
        "possibleTypes": [
          {
            "name": "LocationDetailsDto"
          },
          {
            "name": "GetLocationDetailsRejectionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "BlabDefActivityUnionType",
        "possibleTypes": [
          {
            "name": "BlabDefInvitationCreatedActivityDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "BlabDefActivityDto",
        "possibleTypes": [
          {
            "name": "BlabDefInvitationCreatedActivityDto"
          },
          {
            "name": "BlabDefCreatedActivityDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "WorkspaceActivityUnionType",
        "possibleTypes": [
          {
            "name": "WorkspaceCreatedActivityDto"
          },
          {
            "name": "WorkspaceUserAddedActivityDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "WorkspaceActivityDto",
        "possibleTypes": [
          {
            "name": "WorkspaceCreatedActivityDto"
          },
          {
            "name": "WorkspaceUserAddedActivityDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "OrganizationActivityUnionType",
        "possibleTypes": [
          {
            "name": "OrganizationCreatedActivityDto"
          },
          {
            "name": "OrganizationLogoChangedActivityDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "OrganizationActivityDto",
        "possibleTypes": [
          {
            "name": "OrganizationCreatedActivityDto"
          },
          {
            "name": "OrganizationLogoChangedActivityDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UserActivityUnionType",
        "possibleTypes": [
          {
            "name": "UserCreatedActivityDto"
          },
          {
            "name": "UserPostActivityDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "UserActivityDto",
        "possibleTypes": [
          {
            "name": "UserCreatedActivityDto"
          },
          {
            "name": "UserPostActivityDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "NotificationActivityDto",
        "possibleTypes": [
          {
            "name": "UserNotificationActivityDto"
          },
          {
            "name": "BlabItemNotificationActivityDto"
          },
          {
            "name": "WorkspaceNotificationActivityDto"
          },
          {
            "name": "AudioRoomNotificationActivityDto"
          },
          {
            "name": "WorkflowDefNotificationActivityDto"
          },
          {
            "name": "BlabDefNotificationActivityDto"
          },
          {
            "name": "UserGroupNotificationActivityDto"
          },
          {
            "name": "OrganizationNotificationActivityDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GetUserInfoByOrganizationInvitationTokenResult",
        "possibleTypes": [
          {
            "name": "OrganizationInvitationSignupUserInfoDto"
          },
          {
            "name": "GetUserInfoByOrganizationInvitationTokenRejectedAlreadyAccepted"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GetOrganizationProfileBySlugResult",
        "possibleTypes": [
          {
            "name": "OrganizationProfileDto"
          },
          {
            "name": "OrganizationSlugNoExistDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ShareContextUnionType",
        "possibleTypes": [
          {
            "name": "OrganizationShareContextDto"
          },
          {
            "name": "WorkspaceShareContextDto"
          },
          {
            "name": "BlabDefShareContextDto"
          },
          {
            "name": "BlabItemShareContextDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ShareContextDto",
        "possibleTypes": [
          {
            "name": "OrganizationShareContextDto"
          },
          {
            "name": "WorkspaceShareContextDto"
          },
          {
            "name": "BlabDefShareContextDto"
          },
          {
            "name": "BlabItemShareContextDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "WorkflowRunLogEventUnion",
        "possibleTypes": [
          {
            "name": "WorkflowRunFiltersSetupFailedLogEvent"
          },
          {
            "name": "WorkflowRunFiltersExecutionFailedLogEvent"
          },
          {
            "name": "WorkflowRunFilterUserLogEvent"
          },
          {
            "name": "WorkflowRunActionsSetupFailedLogEvent"
          },
          {
            "name": "WorkflowRunActionSkippingLogEvent"
          },
          {
            "name": "WorkflowRunActionStartedLogEvent"
          },
          {
            "name": "WorkflowRunActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunActionExecutionSyntaxErrorEvent"
          },
          {
            "name": "WorkflowRunActionFailedLogEvent"
          },
          {
            "name": "WorkflowRunActionExceptionCaughtLogEvent"
          },
          {
            "name": "WorkflowRunActionUserLogEvent"
          },
          {
            "name": "WorkflowRunActionDebugLogEvent"
          },
          {
            "name": "WorkflowRunForLoopActionBreakResultLogEvent"
          },
          {
            "name": "WorkflowRunForLoopActionContinueResultLogEvent"
          },
          {
            "name": "WorkflowRunForLoopActionBreakExceptionLogEvent"
          },
          {
            "name": "WorkflowRunForLoopActionContinueExceptionLogEvent"
          },
          {
            "name": "WorkflowRunConditionalActionLogEvent"
          },
          {
            "name": "WorkflowRunBlabItemCreateActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunBlabItemCommentCreateActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunHttpCallActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunCollectBlabItemsActionSuceededLogEvent"
          },
          {
            "name": "WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEvent"
          },
          {
            "name": "WorkflowRunCollectedRecordsCommentCreateSucceededLogEvent"
          },
          {
            "name": "WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEvent"
          },
          {
            "name": "WorkflowPreviewRunActionContextOutputLogEvent"
          },
          {
            "name": "WorkflowRunActionEmailSendAttemptLogEvent"
          },
          {
            "name": "WorkflowRunCreatePdfActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunGenerateWeblinkActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunCollectedBlabItemsCallWorkfowActionLogEvent"
          },
          {
            "name": "WorkflowRunCurrentBlabItemCallWorkfowActionLogEvent"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "WorkflowRunLogEvent",
        "possibleTypes": [
          {
            "name": "WorkflowRunFiltersSetupFailedLogEvent"
          },
          {
            "name": "WorkflowRunFiltersExecutionFailedLogEvent"
          },
          {
            "name": "WorkflowRunFilterUserLogEvent"
          },
          {
            "name": "WorkflowRunActionsSetupFailedLogEvent"
          },
          {
            "name": "WorkflowRunActionExecutionSyntaxErrorEvent"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "WorkflowRunActionLogEvent",
        "possibleTypes": [
          {
            "name": "WorkflowRunActionSkippingLogEvent"
          },
          {
            "name": "WorkflowRunActionStartedLogEvent"
          },
          {
            "name": "WorkflowRunActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunActionFailedLogEvent"
          },
          {
            "name": "WorkflowRunActionExceptionCaughtLogEvent"
          },
          {
            "name": "WorkflowRunActionUserLogEvent"
          },
          {
            "name": "WorkflowRunActionDebugLogEvent"
          },
          {
            "name": "WorkflowRunForLoopActionBreakResultLogEvent"
          },
          {
            "name": "WorkflowRunForLoopActionContinueResultLogEvent"
          },
          {
            "name": "WorkflowRunForLoopActionBreakExceptionLogEvent"
          },
          {
            "name": "WorkflowRunForLoopActionContinueExceptionLogEvent"
          },
          {
            "name": "WorkflowRunConditionalActionLogEvent"
          },
          {
            "name": "WorkflowRunBlabItemCreateActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunBlabItemCommentCreateActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunHttpCallActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunCollectBlabItemsActionSuceededLogEvent"
          },
          {
            "name": "WorkflowRunCollectedRecordsUpdateActionUpdateSucceededLogEvent"
          },
          {
            "name": "WorkflowRunCollectedRecordsCommentCreateSucceededLogEvent"
          },
          {
            "name": "WorkflowRunReferencedRecordsUpdateActionUpdateSucceededLogEvent"
          },
          {
            "name": "WorkflowPreviewRunActionContextOutputLogEvent"
          },
          {
            "name": "WorkflowRunActionEmailSendAttemptLogEvent"
          },
          {
            "name": "WorkflowRunCreatePdfActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunGenerateWeblinkActionSucceededLogEvent"
          },
          {
            "name": "WorkflowRunCollectedBlabItemsCallWorkfowActionLogEvent"
          },
          {
            "name": "WorkflowRunCurrentBlabItemCallWorkfowActionLogEvent"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "WorkspaceMembershipUnionType",
        "possibleTypes": [
          {
            "name": "WorkspaceUserDto"
          },
          {
            "name": "WorkspaceInvitationDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FocusEntryUnionType",
        "possibleTypes": [
          {
            "name": "ReminderFocusEntryDto"
          },
          {
            "name": "ChecklistFocusEntryDto"
          },
          {
            "name": "BlabItemFocusEntryDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "FocusEntryDto",
        "possibleTypes": [
          {
            "name": "ReminderFocusEntryDto"
          },
          {
            "name": "ChecklistFocusEntryDto"
          },
          {
            "name": "BlabItemFocusEntryDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "GetCalculationScriptResultPreviewResultUnionType",
        "possibleTypes": [
          {
            "name": "CalculationScriptMultiTextResultPreviewDto"
          },
          {
            "name": "CalculationScriptNumberResultPreviewDto"
          },
          {
            "name": "CalculationScriptSingleDateResultPreviewDto"
          },
          {
            "name": "GetCalculationScriptResultPreviewRejectionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "CreateBillingSubscriptionResult",
        "possibleTypes": [
          {
            "name": "ActiveBillingSubscriptionDto"
          },
          {
            "name": "IncompleteBillingSubscriptionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ChangeBillingSubscriptionPlanResult",
        "possibleTypes": [
          {
            "name": "ActiveBillingSubscriptionDto"
          },
          {
            "name": "IncompleteBillingSubscriptionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "StartChatVideoCallResult",
        "possibleTypes": [
          {
            "name": "ChatVideoCallActivityDto"
          },
          {
            "name": "MicrosoftUserAuthenticationRequiredDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "AuthenticateWithFindMyOrgsTokenResult",
        "possibleTypes": [
          {
            "name": "UserSessionDto"
          },
          {
            "name": "FindMyOrgUserHasPendingInvitationDto"
          },
          {
            "name": "AuthenticateWithFindMyOrgsTokenRejectionDto"
          },
          {
            "name": "AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "OrganizationAuthenticationProviderResultUnion",
        "possibleTypes": [
          {
            "name": "OrganizationAuthenticationProviderDto"
          },
          {
            "name": "OrganizationAuthenticationProviderRejectionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "OrganizationSmtpSettingsResultUnion",
        "possibleTypes": [
          {
            "name": "OrganizationSmtpSettingsDto"
          },
          {
            "name": "OrganizationSmtpSettingsRejectionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ChangeOrganizationUserRoleResult",
        "possibleTypes": [
          {
            "name": "OrganizationUserDto"
          },
          {
            "name": "ChangeOrganizationRoleRejectedLastOwner"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "UpdateOrganizationUserInfoResult",
        "possibleTypes": [
          {
            "name": "OrganizationUserDto"
          },
          {
            "name": "UpdateOrganizationInfoRejectedEmailUnavailable"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "DeactivateOrganizationUserInfoResult",
        "possibleTypes": [
          {
            "name": "OrganizationUserDto"
          },
          {
            "name": "DeactivateOrganizationUserRejectedLastOwner"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "AuthenticateWithCredentialsResult",
        "possibleTypes": [
          {
            "name": "UserSessionDto"
          },
          {
            "name": "AuthenticateWithCredentialsSuccessMfaRequiredDto"
          },
          {
            "name": "AuthenticateWithCredentialsRejectionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "RequestChangeUserEmailVerificationResult",
        "possibleTypes": [
          {
            "name": "ChangeUserEmailVerificationSessionDto"
          },
          {
            "name": "RequestChangeUserEmailVerificationRejectionDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "RemoveWorkspaceMembershipResult",
        "possibleTypes": [
          {
            "name": "RemoveWorkspaceMembershipSuccess"
          },
          {
            "name": "RemoveWorkspaceMembershipRejected"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ChangeWorkspaceMembershipRoleResult",
        "possibleTypes": [
          {
            "name": "WorkspaceInvitationDto"
          },
          {
            "name": "WorkspaceUserDto"
          },
          {
            "name": "ChangeWorkspaceRoleRejectedLastAdmin"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "ConvertFlimResultDto",
        "possibleTypes": [
          {
            "name": "ConvertMultiAttachmentFlimToSingleAttachmentFlimResultDto"
          },
          {
            "name": "ConvertSingleAttachmentFlimToMultiAttachmentFlimResultDto"
          },
          {
            "name": "ConvertMultiCategoryFlimToSingleCategoryFlimResultDto"
          },
          {
            "name": "ConvertSingleCategoryFlimToStatusFlimResultDto"
          },
          {
            "name": "ConvertSingleCategoryFlimToSingleTextFlimResultDto"
          },
          {
            "name": "ConvertSingleCategoryFlimToMultiCategoryFlimResultDto"
          },
          {
            "name": "ConvertRangeDateFlimToSingleDateFlimResultDto"
          },
          {
            "name": "ConvertSingleDateFlimToRangeDateFlimResultDto"
          },
          {
            "name": "ConvertMultiRelationFlimToSingleRelationFlimResultDto"
          },
          {
            "name": "ConvertSingleRelationFlimToMultiRelationFlimResultDto"
          },
          {
            "name": "ConvertMultiTextFlimToSingleTextFlimResultDto"
          },
          {
            "name": "ConvertSingleTextFlimToMultiTextFlimResultDto"
          },
          {
            "name": "ConvertSingleTextFlimToSingleCategoryFlimResultDto"
          },
          {
            "name": "ConvertSingleTextFlimToNumberFlimResultDto"
          },
          {
            "name": "ConvertSingleTextFlimToMultiLinkFlimResultDto"
          },
          {
            "name": "ConvertMultiUserFlimToSingleUserFlimResultDto"
          },
          {
            "name": "ConvertSingleUserFlimToMultiUserFlimResultDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SubscribeToExportBlabDefDataJobResult",
        "possibleTypes": [
          {
            "name": "SubscribeToExportBlabDefDataJobProgressEvent"
          },
          {
            "name": "SubscribeToExportBlabDefDataJobSuccessEvent"
          },
          {
            "name": "SubscribeToExportBlabDefDataJobFailureEvent"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SubscribeToImportBlabDefDataJobResult",
        "possibleTypes": [
          {
            "name": "SubscribeToImportBlabDefDataJobProgressEvent"
          },
          {
            "name": "SubscribeToImportBlabDefDataJobSuccessEvent"
          },
          {
            "name": "SubscribeToImportBlabDefDataJobFailureEvent"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "BlabDefDeveloperInfoSubscriptionEventUnionType",
        "possibleTypes": [
          {
            "name": "BlabDefDeveloperInfoSubscriptionBlabDefWebhookEventDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SubscribeToBlabDefDuplicationJobResult",
        "possibleTypes": [
          {
            "name": "SubscribeToBlabDefDuplicationJobSuccessEvent"
          },
          {
            "name": "SubscribeToBlabDefDuplicationJobFailureEvent"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SubscribeToUpdateBlabItemsFlimValJobResult",
        "possibleTypes": [
          {
            "name": "SubscribeToUpdateBlabItemsFlimValSuccessEvent"
          },
          {
            "name": "SubscribeToUpdateBlabItemsFlimValFailureEvent"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "BlabItemActivitySubscriptionUpdate",
        "possibleTypes": [
          {
            "name": "BlabItemCommentActivityDto"
          },
          {
            "name": "BlabItemRevisionActivityDto"
          },
          {
            "name": "BlabItemCreatedActivityDto"
          },
          {
            "name": "BlabItemDeletedActivityDto"
          },
          {
            "name": "BlabItemRestoredActivityDto"
          },
          {
            "name": "BlabItemActivityDeletedDto"
          },
          {
            "name": "BlabItemInvitationCreatedActivityDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "BlabItemActivityReplySubscriptionUpdate",
        "possibleTypes": [
          {
            "name": "BlabItemActivityReplyDto"
          },
          {
            "name": "BlabItemActivityReplyDeletedDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SubscribeToBulkFollowUnfollowBlabItemsResult",
        "possibleTypes": [
          {
            "name": "SubscribeToBulkFollowUnfollowBlabItemsSuccessEvent"
          },
          {
            "name": "SubscribeToBulkFollowUnfollowBlabItemsFailureEvent"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ClientApplicationBrowserNotificationUnionType",
        "possibleTypes": [
          {
            "name": "ChatActivityClientApplicationBrowserNotificationDto"
          },
          {
            "name": "NotificationClientApplicationBrowserNotificationDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SubscribeToOauthIntegrationAuthenticationStatusResult",
        "possibleTypes": [
          {
            "name": "SubscribeToOauthIntegrationAuthenticationStatusSuccessEvent"
          },
          {
            "name": "SubscribeToOauthIntegrationAuthenticationStatusFailureEvent"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "WorkflowRunsSubscriptionUpdate",
        "possibleTypes": [
          {
            "name": "WorkflowRunCreatedSubscriptionUpdate"
          },
          {
            "name": "WorkflowRunLogsAppendedSubscriptionUpdate"
          },
          {
            "name": "WorkflowRunUpdatedSubscriptionUpdate"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "WorkflowCenterWebhookEventUnionType",
        "possibleTypes": [
          {
            "name": "WorkflowCenterWebhookEventDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SubscribeToWorkspaceDuplicationJobResult",
        "possibleTypes": [
          {
            "name": "SubscribeToWorkspaceDuplicationJobSuccessEvent"
          },
          {
            "name": "SubscribeToWorkspaceDuplicationJobFailureEvent"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "SingleConversationSubscriptionUpdate",
        "possibleTypes": [
          {
            "name": "ChatMessageActivityDto"
          },
          {
            "name": "ConversationCreateActivityDto"
          },
          {
            "name": "ChatGroupJoinActivityDto"
          },
          {
            "name": "ChatGroupLeaveActivityDto"
          },
          {
            "name": "ChatGroupNameChangeActivityDto"
          },
          {
            "name": "ChatGroupThumbnailChangeActivityDto"
          },
          {
            "name": "ChatActivityDeletedDto"
          },
          {
            "name": "ChatVideoCallActivityDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FocusEntrySubscriptionUpdateUnionType",
        "possibleTypes": [
          {
            "name": "FocusEntryAddedSubscriptionEvent"
          },
          {
            "name": "FocusEntryUpdatedSubscriptionEvent"
          },
          {
            "name": "FocusEntryRemovedSubscriptionEvent"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "ChatActivityUnionType",
        "possibleTypes": [
          {
            "name": "ChatMessageActivityDto"
          },
          {
            "name": "ConversationCreateActivityDto"
          },
          {
            "name": "ChatGroupJoinActivityDto"
          },
          {
            "name": "ChatGroupLeaveActivityDto"
          },
          {
            "name": "ChatGroupNameChangeActivityDto"
          },
          {
            "name": "ChatGroupThumbnailChangeActivityDto"
          },
          {
            "name": "ChatVideoCallActivityDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "FlimValUnionType",
        "possibleTypes": [
          {
            "name": "SingleTextFlimValDto"
          },
          {
            "name": "MultiTextFlimValDto"
          },
          {
            "name": "SingleCategoryFlimValDto"
          },
          {
            "name": "MultiCategoryFlimValDto"
          },
          {
            "name": "SingleRelationFlimValDto"
          },
          {
            "name": "MultiRelationFlimValDto"
          },
          {
            "name": "SingleUserFlimValDto"
          },
          {
            "name": "MultiUserFlimValDto"
          },
          {
            "name": "SingleAttachmentFlimValDto"
          },
          {
            "name": "MultiAttachmentFlimValDto"
          },
          {
            "name": "SingleDateFlimValDto"
          },
          {
            "name": "RangeDateFlimValDto"
          },
          {
            "name": "MultiLinkFlimValDto"
          },
          {
            "name": "ChecklistFlimValDto"
          },
          {
            "name": "CreatedAtFlimValDto"
          },
          {
            "name": "CreatedByFlimValDto"
          },
          {
            "name": "LastModifiedByFlimValDto"
          },
          {
            "name": "LastModifiedAtFlimValDto"
          },
          {
            "name": "NumberFlimValDto"
          },
          {
            "name": "StatusFlimValDto"
          },
          {
            "name": "CalculationFlimValDto"
          },
          {
            "name": "MultiEmailFlimValDto"
          },
          {
            "name": "MultiPhoneFlimValDto"
          },
          {
            "name": "MultiImageFlimValDto"
          },
          {
            "name": "SingleLocationFlimValDto"
          },
          {
            "name": "UniqueIdFlimValDto"
          }
        ]
      },
      {
        "kind": "INTERFACE",
        "name": "MetaFilterDto",
        "possibleTypes": [
          {
            "name": "TimestampMetaFilterDto"
          }
        ]
      },
      {
        "kind": "UNION",
        "name": "NotificationUnionType",
        "possibleTypes": [
          {
            "name": "BlabItemNotificationDto"
          },
          {
            "name": "UserNotificationDto"
          },
          {
            "name": "WorkspaceNotificationDto"
          },
          {
            "name": "AudioRoomNotificationDto"
          },
          {
            "name": "WorkflowDefNotificationDto"
          },
          {
            "name": "BlabDefNotificationDto"
          },
          {
            "name": "UserGroupNotificationDto"
          }
        ]
      }
    ]
  }
};
      export default result;
    