export const flimDefSettingsEnLangClient = {
  addButtonLabel: 'Create',
  attachment: {
    onlyAllowSingle: 'Allow adding only one file',
  },
  backBtnTooltip: 'Back',
  category: {
    layoutSettings: 'Layout settings',
    addBtnTooltip: 'Add option',
    dropdown: 'Dropdown list',
    inline: 'Inline',
    list: 'List',
    newOptionLabel: 'Option',
    optionDefLabelPlaceholder: 'Add option',
    optionDefsPlaceholder: 'No option added yet.',
    removeBtnTooltip: 'Remove option',
    selectColorBtnTooltip: 'Select option color',
    moveTooltip: 'Move',
    conversionRemoveBtnDisabledTooltip: 'Option cannot be deleted until change of field type is saved',
  },
  checklist: {
    showInFocus: 'Show checklist items in focus',
    showCompletedDefault: `Hide completed checklist items`,
  },
  closeBtnTooltip: 'Close',
  createdAt: {
    showInRecord: 'Show in record',
    showTime: 'Include time',
  },
  createdBy: {
    showInRecord: 'Show in record',
  },
  lastModifiedAt: {
    showInRecord: 'Show in record',
    showTime: 'Include time',
  },
  lastModifiedBy: {
    showInRecord: 'Show in record',
  },
  date: {
    calendarSettings: {
      showInCalendar: 'Show in calendar',
      title: 'Calendar settings',
    },
    includeTime: 'Include time',
    isDueDateOfStatusField: 'Set as due date for status field',
    isDueDateOfStatusFieldDisabledTooltip: 'Status field is required',
    onlyAllowSingle: 'Include end date',
    requireEndDate: 'Require end date',
    requireTime: 'Require time',
  },
  number: {
    format: 'Formatting settings',
    showUnitPrefix: 'Show unit symbol as prefix',
    showUnitSuffix: 'Show unit symbol as suffix',
    hideThousandSeparator: 'Show thousand separator',
    formatInput: {
      placeholderPrefix: 'Unit symbol prefix',
      placeholderSuffix: 'Unit symbol suffix',
    },
  },
  displaySettings: {
    hiddenIfEmpty: 'Hidden if empty',
    alwaysHidden: 'Always hidden',
    title: 'Display settings',
  },
  doneButtonLabel: 'Save',
  fieldName: 'Field name',
  fieldNamePlaceholder: 'Add name',
  fieldType: 'Field type',
  fieldTypeSettings: {
    defaultValue: 'Set default value',
    title: 'Field type settings',
  },
  generalSettings: {
    helpText: 'Help text',
    helpTextPlaceholder: 'Add help text',
    requiredField: 'Required field',
    showHelpText: 'Show help text',
    title: 'General settings',
  },
  location: {
    showMap: 'Show map',
  },
  multiText: {
    disableRichTextFormatting: 'Disable rich text formatting',
  },
  overlayTitle: 'Edit field',
  overlayTitleAdd: 'Create field',
  overlayTitleConversion: 'Change field type',
  phone: {
    callLinkProtocolHeadline: 'Call link protocol',
    callLinkProtocolOptions: {
      callto: 'Callto',
      tel: 'Tel',
    },
    allowOnlySingleEntry: 'Allow adding only one phone number',
  },
  email: {
    allowOnlySingleEntry: 'Allow adding only one email',
  },
  relation: {
    addBtnTooltip: 'Create relation',
    blabReferencePlaceholder: 'No relations to other apps created yet',
    blabViewNameFallback: {
      LIST: 'List',
      TABLE: 'Table',
      BADGES: 'Gallery',
      BOARD: 'Board',
    },
    blabViewNoneLabel: 'No view',
    defaultBlabView: 'All {{blabName}}',
    onlyAllowSingle: 'Allow linking to only one record',
    removeBtnTooltip: 'Remove app',
  },
  text: {
    defaultValue: 'Default value',
    defaultValuePlaceholder: 'Add default value',
  },
  user: {
    doNotNotify: `Don't notify users when they're added`,
    onlyOneUser: 'Allow adding only one member',
    showInFocus: 'Show in focus',
    showInFocusDisabledTooltip: 'Status field is required',
  },
  status: {
    requiredTooltip: 'Status field must be required',
    layoutSettings: 'Layout settings',
    addBtnTooltip: 'Add option',
    dropdown: 'Dropdown list',
    inline: 'Inline',
    list: 'List',
    optionDefLabelPlaceholder: 'Add option',
    optionDefsPlaceholder: 'No option added yet.',
    removeBtnTooltip: 'Remove option',
    lastMeansCompletedNotRemovableBtnTooltip: 'Last completed status cannot be removed',
    lastMeansIncompleteNotRemovableBtnTooltip: 'Last incomplete status cannot be removed',
    conversionRemoveBtnDisabledTooltip: 'Status cannot be deleted until change of field type is saved',
    moveTooltip: 'Move',
    selectColorBtnTooltip: 'Select option color',
    newOptionMeansCompletedLabel: 'Completed',
    newOptionMeansIncompleteLabel: 'Incomplete',
    completed: 'Completed',
    incomplete: 'Incomplete',
    completedLastOfItsKindTooltip: 'Last completed status cannot be changed',
    incompleteLastOfItsKindTooltip: 'Last incomplete status cannot be changed',
  },
  uniqueId: {
    uniqueIdPrefixPlaceholder: 'Unique ID prefix',
  },
  image: {
    layoutSettings: 'Layout settings',
    layoutSizeSmall: 'Small',
    layoutSizeMedium: 'Medium',
    layoutSizeLarge: 'Large',
  },
};
