export const organizationPlanLangDeClient = {
  planInfo: {
    activityLimitInfo:
      'Es wurden {{activityCount}} von {{activityLimit}} verfügbaren Aktivitäten ({{activityPercentage}}%) bereits genutzt.',
    freePlanInfo: 'Deine Organisation nutzt aktuell die kostenlose Version von Tape',
    seeUpgradeOptions: 'Upgrade Optionen ansehen',
  },
  planNames: {
    BASIC: 'Basic',
    ENTERPRISE: 'Enterprise',
    FREE: 'Free',
    STANDARD: 'Premium',
  },
  planOverview: {
    closeBtnTooltip: 'Schließen',
    contactSales: 'Anfragen',
    enterpriseIndividual: 'Individuell',
    features: {
      sectionTitle: 'Features',
      appsAndWorkspaces: 'Apps und Workspaces',
      focus: 'Fokus und Checklistenfeld',
      user: 'Benutzerverwaltung',
      editorUserRole: 'Editor Rolle',
      customizable: 'Voller Anpassungsumfang',
      workflows: 'Erweiterte Workflow Automatisierungen',
      support: 'Vorrangiger Support',
    },
    perMonth: 'pro Monat',
    perUser: 'pro Nutzer',
    priceBillingPeriodInfoDescr: '{{price}} pro aktivem Nutzer bei monatlicher Abrechnung',
    priceBillingPeriodInfoTitle: 'Pro aktivem Nutzer, pro Monat, bei jährlicher Abrechnung',
    title: 'Plan-Übersicht',
    unlimited: 'Unbegrenzt',
    upgrade: 'Upgrade',
    usage: {
      sectionTitle: 'Nutzung',
      activities: 'Aktivitäten',
      fileUploads: 'Datei Upload',
      records: 'Einträge',
      members: 'Mitglieder',
      revisionHistory: 'Revisions-Historie',
      automationActions: 'Automatisierungs-Aktionen',
    },
  },
};
