import { roundToNearestMinutes } from 'date-fns';
import { removeTime } from './time.utils';

export function currentDate(): Date {
  return removeTime(new Date());
}

export function currentDatetime({ nearestTo }: { nearestTo?: number } = {}) {
  return roundToNearestMinutes(new Date(), { nearestTo: nearestTo ?? 30 });
}
