export const organizationSettingsEnLangClient = {
  billingDetails: {
    saveBtn: 'Save',
    title: 'Billing details',
  },
  billingOverview: {
    title: `Billing overview`,
  },
  closeBtnTooltip: 'Close',
  generalSettings: {
    deleteInfoModal: {
      confirmButtonLabel: 'Contact',
      message:
        'Due to our high privacy requirements, you need to contact our support for the deletion of your organization.',
      title: 'Delete organization?',
    },
    title: `General settings`,
  },
  invitations: {
    title: 'Invitiations',
  },
  members: {
    title: 'Members',
  },
  organizationProfile: {
    changeSlugModal: {
      confirmButtonLabel: 'Edit',
      message: `Any links using the existing URL won't work anymore, if you decide to change the organization URL.`,
      title: 'Edit organization URL?',
    },
    saveBtn: 'Save',
    title: 'Edit organization profile',
  },
  paymentMethods: {
    title: `Payment methods`,
  },
  sidemenu: {
    billing: {
      billingDetails: 'Billing details',
      invoiceHistory: 'Invoices',
      overview: 'Billing overview',
      paymentMethod: 'Payment methods',
      sectionName: 'Billing',
    },
    generalSettings: `General settings`,
    invitations: `Invitations`,
    manageMembers: `Manage members`,
    security: `Security`,
    orgProfile: 'Organization profile',
    teams: `Teams`,
    workspaces: `Workspaces`,
  },
  teams: {
    title: 'Teams',
  },
  title: 'Settings',
  workspaces: {
    title: 'Manage workspaces',
  },
} as const;
