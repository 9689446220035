export const blabItemTableDeLangClient = {
  selectionMenu: {
    buttons: {
      delete: 'Löschen',
    },
    confirmDeleteSelectedBlabItems: {
      confirmButtonLabel: `Löschen`,
      message: `Bist du sicher, dass du die ausgewählten Einträge löschen möchtest?`,
      title: `Ausgewählte Einträge löschen?`,
    },
    itemSelected: '{{itemName}} ausgewählt',
    itemsSelected: '{{blabName}} ausgewählt',
  },
  toolbar: {
    sort: {
      labelNoSort: 'Sortierung',
      label: '{{ numSorts }} Sortierung',
      clearBtnTooltip: 'Sortierung löschen',
    },
    filters: {
      addBtnTooltip: 'Filter hinzufügen',
      clearBtnTooltip: 'Filter löschen',
      flimFilter: {
        MULTI_ATTACHMENT: {
          CONTAINS: 'Beinhaltet',
          EMPTY: 'Ist leer',
          ONE: 'Beinhaltet',
          NONE: 'Beinhaltet keines',
          NOT_CONTAINS: 'Beinhaltet nicht',
          NOT_EMPTY: 'Ist nicht leer',
          STARTS_WITH: 'Beginnt mit',
          ENDS_WITH: 'Endet mit',
          placeholder: 'Wert hinzufügen',
        },
        MULTI_CATEGORY: {
          ALL: 'Hat jeden von',
          EMPTY: 'Ist leer',
          EXACT: 'Ist exakt',
          NONE: 'Ist keiner von',
          NOT_EMPTY: 'Ist nicht leer',
          ONE: 'Hat einen von',
        },
        MULTI_RELATION: {
          ALL: 'Hat jeden von',
          EMPTY: 'Ist leer',
          EXACT: 'Ist exakt',
          NONE: 'Ist keiner von',
          NOT_EMPTY: 'Ist nicht leer',
          ONE: 'Hat einen von',
        },
        MULTI_TEXT: {
          CONTAINS: 'Beinhaltet',
          EMPTY: 'Ist leer',
          IS: 'Ist',
          IS_NOT: 'Ist nicht',
          NOT_CONTAINS: 'Beinhaltet nicht',
          NOT_EMPTY: 'Ist nicht leer',
          STARTS_WITH: 'Beginnt mit',
          ENDS_WITH: 'Endet mit',
          placeholder: 'Wert hinzufügen',
        },
        MULTI_USER: {
          ALL: 'Hat jeden von',
          EMPTY: 'Ist leer',
          EXACT: 'Ist exakt',
          NONE: 'Ist keiner von',
          NOT_EMPTY: 'Ist nicht leer',
          ONE: 'Hat einen von',
        },
        SINGLE_ATTACHMENT: {
          CONTAINS: 'Beinhaltet',
          EMPTY: 'Ist leer',
          IS: 'Ist',
          IS_NOT: 'Ist nicht',
          NOT_CONTAINS: 'Beinhaltet nicht',
          NOT_EMPTY: 'Ist nicht leer',
          STARTS_WITH: 'Beginnt mit',
          ENDS_WITH: 'Endet mit',
          placeholder: 'Wert hinzufügen',
        },
        SINGLE_CATEGORY: {
          EMPTY: 'Ist leer',
          IS: 'Ist',
          IS_ANY: 'Ist einer von',
          IS_NONE: 'Ist keiner von',
          IS_NOT: 'Ist nicht',
          NOT_EMPTY: 'Ist nicht leer',
        },
        SINGLE_RELATION: {
          EMPTY: 'Ist leer',
          IS: 'Ist',
          IS_ANY: 'Ist einer von',
          IS_NONE: 'Ist keiner von',
          IS_NOT: 'Ist nicht',
          NOT_EMPTY: 'Ist nicht leer',
        },
        SINGLE_TEXT: {
          CONTAINS: 'Beinhaltet',
          EMPTY: 'Ist leer',
          IS: 'Ist',
          IS_NOT: 'Ist nicht',
          NOT_CONTAINS: 'Beinhaltet nicht',
          NOT_EMPTY: 'Ist nicht leer',
          STARTS_WITH: 'Beginnt mit',
          ENDS_WITH: 'Endet mit',
          placeholder: 'Wert hinzufügen',
        },
        SINGLE_USER: {
          EMPTY: 'Ist leer',
          IS: 'Ist',
          IS_ANY: 'Ist einer von',
          IS_NONE: 'Ist keiner von',
          IS_NOT: 'Ist nicht',
          NOT_EMPTY: 'Ist nicht leer',
        },
        STATUS: {
          EMPTY: 'Ist leer',
          IS: 'Ist',
          IS_ANY: 'Ist einer von',
          IS_NONE: 'Ist keiner von',
          IS_NOT: 'Ist nicht',
          NOT_EMPTY: 'Ist nicht leer',
          COMPLETED: 'Erledigt',
          INCOMPLETE: 'Offen',
        },
        CALCULATION: {
          CONTAINS: 'Beinhaltet',
          EMPTY: 'Ist leer',
          EQUAL: 'Ist',
          NOT_EQUAL: 'Ist nicht',
          NOT_CONTAINS: 'Beinhaltet nicht',
          NOT_EMPTY: 'Ist nicht leer',
          ENDS_WITH: 'Endet mit',
          STARTS_WITH: 'Beginnt mit',

          // number: TODO:
          SMALLER: '',
          SMALLER_OR_EQUAL: '',
          LARGER: '',
          LARGER_OR_EQUAL: '',

          // date: TODO:
          IS_BEFORE: '',
          IS_AFTER: '',
          IS_WITHIN: '',
          IS_ON_OR_BEFORE: '',
          IS_ON_OR_AFTER: '',

          placeholder: 'Wert hinzufügen',
        },
      },
      inputDisabledPlaceholder: 'Deaktiviert',
      many: '{{numFilters}} Filter / {{numResults}} von {{numTotal}}',
      one: '1 Filter / {{numResults}} von {{numTotal}}',
      placeholder: {
        noFilterableFlimDefs: 'Keine Felder zum Filtern gefunden',
        text: 'Keine Filter für diese Ansicht angewandt',
      },
      prefix: {
        and: 'Und',
        where: 'Wo',
      },
      removeFilterBtnTooltip: 'Filter entfernen',
      title: 'Filter',
      zero: 'Filter / {{numResults}} von {{numTotal}}',
    },
    hiddenColumns: {
      all: 'Alle Spalten',
      clearBtnTooltip: 'Anpassen zurücksetzen',
      many: '{{numHiddenColumns}} ausgeblendete Spalten',
      menuTitle: 'Spalten',
      one: '1 ausgeblendete Spalte',
      zero: 'Spalten',
    },
    options: {
      many: '{{numUnsaved}} ungespeicherte Änderungen',
      one: '1 ungespeicherte Änderung',
      optionsBtnTooltip: 'Ansichts-Optionen',
      saveCurrentBtnTooltip: 'Zu dieser Ansicht speichern',
      saveNewBtnTooltip: 'Als neue Ansicht speichern',
    },
    rowHeight: {
      EXTRA_TALL: `Sehr hoch`,
      MEDIUM: `Mittel`,
      SHORT: `Klein`,
      TALL: `Hoch`,
    },
    sortings: {
      many: '{{numSorts}} Sortierungen',
      one: '1 Sortierung',
      zero: 'Sortierung',
    },
  },
} as const;
