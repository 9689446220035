import { createReducer, on } from '@ngrx/store';
import { I18nSettings } from '@t5s/shared/gql';
import { InternationalizationDefaults } from '@t5s/shared/util/i18n';
import { InternationalizationActions } from './internationalization.actions';

export interface InternationalizationState extends I18nSettings {}

const initialState: InternationalizationState = {
  language: InternationalizationDefaults.Language,
  timeFormat: InternationalizationDefaults.TimeFormat,
  dateFormat: InternationalizationDefaults.DateFormat,
  firstWeekday: InternationalizationDefaults.FirstWeekday,
  decimalFormat: InternationalizationDefaults.DecimalFormat,
  measurementSystem: InternationalizationDefaults.MeasurementSystem,
};

export const internationalizationReducer = createReducer(
  initialState,
  on(
    InternationalizationActions.bootstrapLanguage,
    InternationalizationActions.changeLanguage,
    (state, { language }) => ({ ...state, language }),
  ),
  on(InternationalizationActions.changeTimeFormat, (state, { timeFormat }) => ({ ...state, timeFormat })),
  on(InternationalizationActions.changeDateFormat, (state, { dateFormat }) => ({ ...state, dateFormat })),
  on(InternationalizationActions.changeFirstWeekday, (state, { firstWeekday }) => ({ ...state, firstWeekday })),
  on(InternationalizationActions.changeDecimalFormat, (state, { decimalFormat }) => ({ ...state, decimalFormat })),
  on(InternationalizationActions.changeMeasurementSystem, (state, { measurementSystem }) => ({
    ...state,
    measurementSystem,
  })),
);
