export const doNotDisturbDeLangClient = {
  pausedInfinite: `Benachrichtigungen pausiert bis zur Reaktivierung`,
  pausedUntil: `Benachrichtigungen pausiert bis {{formattedDate}}`,
  pausingInfinite: `Benachrichtigungen pausiert bis zur Reaktivierung`,
  pausingUntil: `Benachrichtigungen pausieren bis {{formattedDate}}`,
  placeholder: `Bitte so lange nicht stören`,
  saveBtn: `Speichern`,
  title: `Bitte nicht stören`,
  until: {
    INFINITE: `Nicht löschen`,
    ONE_HOUR: '1 Stunde',
    THIRTY_MIN: '30 Minuten',
    UNTIL_NEXT_WEEK: 'Bis nächste Woche',
    UNTIL_TOMORROW: 'Bis Morgen',
  },
} as const;
