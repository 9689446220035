import { SetUserStatusUntilOptions } from '@t5s/web-client/value-object/user-status';

export const setUserStatusDeLangClient = {
  deleteAfter: `Löschen nach`,
  inputPlaceholder: `Wie lautet dein Status?`,
  saveBtn: `Speichern`,
  statusSuggestions: {
    commuting: `Unterwegs`,
    inMeeting: `In einer Besprechung`,
    remote: `Home-Office`,
    sick: `Krank`,
    vacation: `Im Urlaub`,
  },
  title: `Status einstellen`,
  until: {
    [SetUserStatusUntilOptions.THIRTY_MIN]: '30 Minuten',
    [SetUserStatusUntilOptions.ONE_HOUR]: '1 Stunde',
    [SetUserStatusUntilOptions.TODAY]: 'Heute',
    [SetUserStatusUntilOptions.THIS_WEEK]: 'In dieser Woche',
    custom: 'Benutzerdefiniert',
    doNotClear: 'Nicht löschen',
  },
} as const;
