import { DateFormat } from '@t5s/shared/gql';
import { getDateFromInputStr } from './date-input.utils';
import { Datetime } from './datetime.interface';
import { getTimeFromInputStr } from './time-input.utils';
import { setTime } from './time.utils';

export function getDatetimeFromInputStr(
  input: string | undefined,
  { dateFormat, referenceDate }: { dateFormat?: DateFormat; referenceDate?: Date } = {},
): Datetime | undefined {
  input = (input || '').trim();
  const [dateStr] = input.split(/[ ,\-:]+/);

  let date = getDateFromInputStr(dateStr, { dateFormat, referenceDate });

  if (!date) {
    return undefined;
  }

  const timeStr = input.slice(dateStr.length);
  const time = getTimeFromInputStr(timeStr);
  if (time) {
    date = setTime(date, { time });
  }

  return {
    date,
    time: !!time,
  };
}
