/* eslint-disable import/no-internal-modules */
/* eslint-disable @typescript-eslint/no-require-imports */
/* eslint-disable @typescript-eslint/no-var-requires */
import { fontFamily, globalStyles } from '@t5s/client/ui/style/common';
import { ionicCore } from './ionic-core-css';
import { ionicStructure } from './ionic-structure-css';
import { iosSafeAreaStyles } from './ios-safe-area.styles';
import { loadingStyles } from './loading.styles';

/** Ensures all angular components with `t5s-` are displayed as block.
 * Also excludes some ionic components (this led to issues when upgrading from ionic 5 to 6.) */
const customComponentStyles = `
  *:not(html):not(head):not(body):not(style):not(script):not(noscript):not(div):not(span):not(applet):not(object):not(iframe):not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(br):not(p):not(blockquote):not(pre):not(a):not(abbr):not(acronym):not(address):not(big):not(cite):not(code):not(del):not(dfn):not(em):not(img):not(ins):not(kbd):not(q):not(s):not(samp):not(small):not(strike):not(sub):not(sup):not(tt):not(var):not(b):not(u):not(i):not(center):not(dl):not(dt):not(dd):not(ol):not(ul):not(li):not(fieldset):not(form):not(label):not(legend):not(table):not(caption):not(tbody):not(tfoot):not(thead):not(tr):not(th):not(td):not(article):not(aside):not(canvas):not(details):not(embed):not(figure):not(figcaption):not(footer):not(header):not(hgroup):not(menu):not(nav):not(output):not(ruby):not(section):not(summary):not(time):not(mark):not(script):not(noscript):not(audio):not(em):not(strong):not(ion-backdrop):not(ion-modal) {
    display: block;
  }
`;

/**
    html.plt-mobile ion-app [contenteditable] {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
 */

const customIonicOverrides = `
  * {
    font-family: ${fontFamily.default};
  }
`;

export const mobileClientGlobalStyles = `
  ${globalStyles}
  ${iosSafeAreaStyles}
  ${customComponentStyles}
  ${ionicCore}
  ${ionicStructure}
  ${customIonicOverrides}
  ${loadingStyles}
`;
