export const userProfileEnLangClient = {
  activity: 'Posts',
  backBtnTooltip: 'Back',
  channels: 'Channels',
  confirmDeleteUserPost: {
    confirmButtonLabel: `Delete`,
    message: `Are you sure you want to delete this post? This can’t be undone and replies to this post will also be removed.`,
    title: `Delete post?`,
  },
  confirmDeleteUserPostReply: {
    confirmButtonLabel: `Delete`,
    message: `Are you sure you want to delete this reply? This cannot be undone.`,
    title: `Delete reply?`,
  },
  editProfileBtn: 'Edit profile',
  followers: 'Follower',
  following: 'Following',
  postPlaceholder: `What's happening?`,
  relatedItems: 'Relations',
  replyPlaceholder: 'Start a new reply',
  setAStatus: 'Set a status',
} as const;
