export const organizationProfileDeLangClient = {
  activity: 'Aktivitäten',
  channels: 'Channels',
  edit: {
    changeOrganizationUrl: {
      cancel: 'Abbrechen',
      changeLink: 'Organisations-URL bearbeiten',
      edit: 'Bearbeiten',
      inputNote: `Deine Organisations-URL darf nur Kleinbuchstaben, Zahlen und Bindestriche enthalten und muss mit einem Buchstaben oder einer Zahl beginnen.`,
      modalSubtitle: `Alle Links, die die aktuelle URL verwenden, funktionieren nicht mehr, wenn du die Organisations-URL änderst.`,
      modalTitle: 'Organisations-URL bearbeiten?',
      organizationUrl: 'Organisations-URL',
      organizationUrlPlaceholder: 'deine-organisations-url',
    },
    emailAddress: 'E-Mail-Adresse',
    emailAddressPlaceholder: 'E-Mail-Adresse hinzufügen',
    location: 'Ort',
    locationPlaceholder: 'Ort hinzufügen',
    organizationDescription: 'Beschreibung',
    organizationDescriptionPlaceholder: 'Beschreibung hinzufügen',
    organizationName: 'Organisations-Name',
    organizationNamePlaceholder: 'Organisations-Name hinzufügen',
    phoneNumber: 'Telefonnummer',
    phoneNumberPlaceholder: 'Telefonnummer hinzufügen',
    uploadImageBtnTooltip: 'Add photo',
    website: 'Webseite',
    websitePlaceholder: 'Webseite hinzufügen',
  },
} as const;
