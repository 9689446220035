import { createFeatureSelector, createSelector } from '@ngrx/store';
import { getActiveActorOrganizationId, getActiveUserId } from '@t5s/shared/util/active-actor-context';
import { ActiveActorState, activeActorStateKey } from './active-actor.reducer';

const selectActiveActorFeatureState = createFeatureSelector<ActiveActorState>(activeActorStateKey);

export const selectActiveActorContext = createSelector(selectActiveActorFeatureState, (state) => state.actor);
export const selectActiveUserId = createSelector(selectActiveActorFeatureState, (state) =>
  getActiveUserId(state.actor),
);

export const selectActiveActorOrganizationId = createSelector(selectActiveActorFeatureState, (state) =>
  getActiveActorOrganizationId(state.actor),
);
