import { Inject, Injectable } from '@angular/core';
import { LanguageService } from '@t5s/client/i18n/common';
import { I18n, RUNTIME_I18N } from '@t5s/client/util/i18n-token';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { translate } from './i18n-translate';
import { WebClientI18nKey, WebClientI18nString } from './i18n-types';

@Injectable()
export class WebClientLanguageService extends LanguageService {
  private i18n$$ = new BehaviorSubject<I18n | null>(null);
  constructor(@Inject(RUNTIME_I18N) private i18n$: Observable<I18n>) {
    super();
    i18n$.subscribe(this.i18n$$);
  }

  translateAsync(i18nInput: WebClientI18nString | WebClientI18nKey | undefined | null): Observable<string> {
    return this.i18n$.pipe(
      map(({ languagesDict, language }) => {
        if (!language) {
          return '';
        }
        return translate(i18nInput, languagesDict, language) ?? '';
      }),
    );
  }

  translateSync(i18nInput: WebClientI18nString | WebClientI18nKey | undefined | null): string {
    const i18n = this.i18n$$.getValue();

    if (!i18n) {
      return '';
    }
    const { language, languagesDict } = i18n;

    if (!language) {
      return '';
    }

    return translate(i18nInput, languagesDict, language) ?? '';
  }
}
