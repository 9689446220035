export const reminderDeLangClient = {
  closeBtnTooltip: 'Schließen',
  detail: {
    backBtnTooltip: 'Zurück',
    blabItemPreview: {
      removeBtnTooltip: 'Entfernen',
    },
    completeBtnTooltip: 'Als erledigt markieren',
    descPlaceholder: 'Details hinzufügen',
    dueAtBadge: {
      removeBtnTooltip: 'Entfernen',
    },
    dueAtPlaceholder: 'Fälligkeitsdatum hinzufügen',
    incompleteBtnTooltip: 'Als nicht erledigt markieren',
    itemPlaceholder: 'Item verknüpfen',
    removeBtnTooltip: 'Löschen',
    titlePlaceholder: 'Titel hinzufügen',
  },
  displaySettings: {
    APP: 'App',
    DUE_DATE: 'Fälligkeitsdatum',
    USER_ORDER: 'Meine Reihenfolge',
  },
  menu: {
    archiveAllCompleted: 'Alle erledigten archivieren',
    creationDate: 'Erstellungsdatum',
    dueAt: 'Fälligkeitsdatum',
    hideArchived: 'Archivierte ausblenden',
    myOrder: 'Meine Reihenfolge',
    relatedApp: 'Referenzierte app',
    showArchived: 'Archivierte einblenden',
    sortBy: 'Sortieren nach',
  },
  newReminderBtnTooltip: 'Neue Erinnerung',
  newReminderModal: {
    descriptionPlaceholder: 'Details hinzufügen',
    titlePlaceholder: 'Neue Erinnerung',
  },
  placeholder: {
    subline: `Du hast keine offenen Erinnerungen.`,
    title: 'Stark!',
  },
  preview: {
    archiveBtnTooltip: 'Archivieren',
    checkIconBtnTooltip: 'Als erledigt markieren',
    editBtnTooltip: 'Details bearbeiten',
    unarchiveBtnTooltip: 'Unarchivieren',
    uncheckIconBtnTooltip: 'Als nicht erledigt markieren',
  },
  sectionHeadlines: {
    archived: `Archiviert ({{count}})`,
    completed: `Erledigt ({{count}})`,
  },
  title: `Erinnerungen`,
} as const;
