export const loginDeLangClient = {
  email: 'E-Mail-Adresse',
  emailPlaceholder: 'du@beispiel.de',
  enterCredentials: 'Gib deine E-Mail-Adresse und dein Passwort ein.',
  error: {
    invalidCredentials: `Du hast leider eine falsche E-Mail-Adresse oder ein falsches Passwort eingegeben`,
    invalidEmail: `Bitte gib eine gültige E-Mail-Adresse ein`,
    invalidPassword: `Bitte gib dein Passwort ein`,
    userDeactivated: `Dieser Account ist deaktiviert.`,
  },
  forgotPassword: `Passwort vergessen?`,
  login: 'Anmelden',
  logInToYourOrganization: 'In {{orgName}} anmelden',
  password: 'Passwort',
  passwordPlaceholder: 'Dein Passwort',
  signinAnotherOrganization: 'In einer anderen Organisation anmelden',
  yourEmailAddress: 'Deine E-Mail-Adresse',
} as const;
