import { audioRoomNotificationLangEnClient } from './audio-room-notification/audio-room-notification.en';
import { blabDefNotificationLangEnClient } from './blab-def-notification/blab-def-notification.en';
import { blabItemNotificationLangEnClient } from './blab-item-notification/blab-item-notification.en';
import { userGroupNotificationLangEnClient } from './user-group-notification/user-group-notification.en';
import { userNotificationLangEnClient } from './user-notification/user-notification.en';
import { workflowDefNotificationLangEnClient } from './workflow-def-notification/workflow-def-notification.en';
import { workspaceNotificationLangEnClient } from './workspace-notification/workspace-notification.en';

export const notificationsEnLangClient = {
  audioRoom: audioRoomNotificationLangEnClient,
  workflowDef: workflowDefNotificationLangEnClient,
  blabDef: blabDefNotificationLangEnClient,
  userGroup: userGroupNotificationLangEnClient,
  blabItem: blabItemNotificationLangEnClient,
  filteredBy: 'Filtered by',
  filterToggleBtnTooltip: 'Filter',
  menu: {
    markAllAsRead: 'Mark all as read',
    notificationSettings: 'Settings',
  },
  notificationTabTitle: 'Notifications',
  placeholder: {
    noNotifications: {
      subtitle: `When you turn on notifications for a record or a member, you'll find anything that happens here.`,
      title: 'Nothing to see here yet',
    },
    noUnreadNotifications: {
      subtitle: `Please clear your filter.`,
      title: 'You don’t have unread notifications',
    },
    noYouNotifications: {
      subtitle: `When someone mentions you or adds you to a record, you'll find it here.`,
      title: 'Nothing to see here yet',
    },
  },
  tabs: {
    all: 'All',
    you: 'You',
  },
  toggleMarkReadBtnTooltip: 'Mark as read',
  toggleMarkUnreadBtnTooltip: 'Mark as unread',
  unreadFilter: 'Unread',
  user: userNotificationLangEnClient,
  workspace: workspaceNotificationLangEnClient,
} as const;
