export const relationInputEnLangClient = {
  multiRelationInput: {
    placeholder: 'Select records',
    searchPlaceholder: 'Search for records',
  },
  singleRelationInput: {
    placeholder: 'Select a record',
    searchPlaceholder: 'Search for a record',
  },
};
