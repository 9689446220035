export const userTrashDeLangClient = {
  emptyTrashModal: {
    confirmButtonLabel: 'Leeren',
    message:
      'Diese Einträge werden dadurch dauerhaft aus dem Papierkorb entfernt und können nicht mehr wiederhergestellt werden.',
    title: 'Papierkorb leeren?',
  },
  removeFromTrashModal: {
    confirmButtonLabel: 'Entfernen',
    message:
      'Dieser Eintrag wird dadurch dauerhaft aus dem Papierkorb entfernt und kann nicht mehr wiederhergestellt werden.',
    title: 'Aus dem Papierkorb entfernen?',
  },
} as const;
