export const blabDefEnLangClient = {
  create: {
    createButton: `Create App`,
  },
  createBtnTooltip: 'Add App',
  createMoreMenu: {
    createBlank: 'New app',
    templates: 'Choose from templates',
    import: 'Import Excel / CSV',
  },
  dialog: {
    deleteBlabDef: {
      confirmButtonLabel: `Delete`,
      lastBlabDefRemoveToast: `The last app in a workspace can’t be deleted.`,
      message: `Are you sure you want to delete this app? This can’t be undone.`,
      title: `Delete app?`,
    },
  },
  flimDefs: {
    categoryFlimDef: {
      layout: {
        DROPDOWN: `Dropdown`,
        INLINE: `In-line`,
        LIST: `List`,
      },
    },
  },
  moreMenu: {
    appPage: `App page`,
    archiveButton: `Archive app`,
    cloneButton: `Duplicate app`,
    permissionSettings: `Manage permissions`,
    deleteButton: `Delete app`,
    followButton: `Follow app`,
    unfollowButton: `Unfollow app`,
    editButton: `Customize fields`,
    settingsButton: `App settings`,
    emailToApp: 'Email to app',
    export: 'Export',
    import: 'Import Excel/ CSV',
    developerInfo: 'Developer',
    workflowCenterButton: `Automations`,
    new: `New`,
  },
  notifications: {
    createBlabDefError: `App could not be created.`,
    createBlabDefSuccess: `App {{blabName}} created.`,
    deleteBlabDefError: `App could not be deleted.`,
    deleteBlabDefSuccess: `App deleted.`,
    updateBlabDefSuccess: `App updated.`,
  },
};
