export const blabDefCreateDeLangClient = {
  backBtnTooltip: 'Zurück',
  closeBtnTooltip: 'Schließen',
  importBtnTooltip: 'Importieren',
  importV2BtnTooltip: 'Import V2',
  icon: {
    searchPlaceholder: 'Nach Icon suchen',
    title: 'App-Icon',
  },
  info: {
    appName: 'App-Name',
    appNamePlaceholder: 'z.B. Aufgaben',
    cancelBtnLabel: 'Abbrechen',
    createBtnLabel: 'Erstellen',
    icon: 'App-Icon',
    itemName: 'Eintrags-Name',
    itemNamePlaceholder: 'z.B. Aufgabe',
    title: 'Neue App erstellen',
  },
  method: {
    custom: {
      subtitle: 'Gehe zur App-Vorlage, um deine eigene App innerhalb weniger Minuten selbst zu erstellen.',
      title: 'Neue App erstellen',
    },
    import: {
      subtitle: 'Starte bis dahin einfach damit, deine eigene App innerhalb weniger Minuten selbst zu erstellen.',
      title: 'Daten-Import kommt bald!',
    },
    market: {
      subtitle: 'Starte bis dahin einfach damit, deine eigene App innerhalb weniger Minuten selbst zu erstellen.',
      title: 'Tape App-Markt kommt bald!',
    },
    title: 'App hinzufügen',
  },
};
