export const userInputEnLangClient = {
  multiUserInput: {
    placeholder: 'Select people',
    searchPlaceholder: 'Search for people',
    activeUser: 'Myself',
  },
  singleUserInput: {
    placeholder: 'Select a person',
    searchPlaceholder: 'Search for a person',
    activeUser: 'Myself',
  },
};
