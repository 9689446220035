export const colorDeLangClient = {
  BLUE: 'Blau',
  BROWN: 'Braun',
  DEFAULT: 'Standard',
  GRAY: 'Grau',
  GREEN: 'Grün',
  ORANGE: 'Orange',
  PINK: 'Pink',
  PURPLE: 'Lila',
  RED: 'Rot',
  YELLOW: 'Gelb',
};
