import { DateFormat } from '@t5s/shared/gql';
import { getDatetimeFromInputStr } from './datetime-input.utils';

export function getDatetimeRangeFromInputStr(
  input: string | undefined,
  { dateFormat, referenceDate }: { dateFormat?: DateFormat; referenceDate?: Date } = {},
): { startDate?: Date; hasTime?: boolean; endDate?: Date; endTime?: boolean } | undefined {
  input = input || '';
  const [datetimeStr] = input.split(/[\-]+/);

  const startDate = getDatetimeFromInputStr(datetimeStr.trim(), { dateFormat, referenceDate });

  if (!startDate) {
    return undefined;
  }

  const endDateStr = input.slice(datetimeStr.length + 1);
  const endDate = getDatetimeFromInputStr(endDateStr.trim());

  return {
    startDate: startDate?.date,
    hasTime: startDate?.time,
    endDate: endDate?.date,
    endTime: endDate?.time,
  };
}
