import { DOCUMENT } from '@angular/common';
import { Inject, Injectable } from '@angular/core';
import { fromEvent, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Injectable()
export class KeyboardService {
  private readonly _keydown$: Observable<KeyboardEvent>;

  constructor(@Inject(DOCUMENT) document: Document) {
    this._keydown$ = fromEvent<KeyboardEvent>(document, 'keydown');
  }

  /** Returns all keydown events for the given key, or all keydown events if not provided. */
  keydown$(forKey?: 'Enter' | 'Esc'): Observable<KeyboardEvent> {
    return this._keydown$.pipe(filter(({ key }) => !forKey || key === forKey));
  }
}
