import { TIPPY_KEYBOARD_SHORTCUT } from '@t5s/web-client/readonly-constant/tippy';

export const chatEnLangClient = {
  confirmDeleteChatMessage: {
    confirmButtonLabel: `Delete`,
    message: `Are you sure you want to delete this message? This cannot be undone.`,
    title: `Delete Message?`,
  },
  conversation: {
    conversationCreated: `Group was created.`,
    groupNameChanged: `{{userName}} changed the group name to {{groupName}}.`,
    groupThumbnailChanged: `{{userName}} changed the group photo.`,
    input: {
      placeholder: `Start a new message`,
    },
    messageOnlyAttachments: `{{userName}} added an attachment.`,
    searchUser: `Add people to new group`,
    userJoined: `{{userName}} added {{targetUser}}.`,
    userLeft: `{{userName}} left the group.`,
  },
  conversationInfo: {
    addMember: `Add person`,
    addMemberAria: `Add person to group`,
    confirmLeaveGroup: {
      confirmButton: `Leave`,
      message: `This group will be deleted from your inbox. Other people in the group will still be able to see it.`,
      title: `Leave group?`,
    },
    leaveGroup: `Leave group`,
    memberHeader: 'Member',
    muteDialogConversation: `Mute direct message`,
    muteGroupConversation: `Mute group`,
    settingsHeader: 'Settings',
    unmuteDialogConversation: `Unmute direct message`,
    unmuteGroupConversation: `Unmute group`,
  },
  conversationPreviewButton: `Open group element`,
  deletemessageMenuItem: 'Delete message',
  dropzoneLabel: 'Upload to Message',
  footer: {
    cancel: `Cancel current operation`,
    finish: `Finish process`,
    next: `Continue with the next step`,
  },
  groupAddUsers: {
    addBtn: 'Add',
    cancelBtn: 'Cancel',
  },
  header: {
    backBtn: `Go back to previous page`,
    closeRightSidebarAria: `Close right sidebar`,
    newMessageAria: `Create new message`,
    titles: {
      addMember: `Add person`,
      conversationInfoAria: `Enter group info`,
      directMessageInfo: `Direct message info`,
      groupInfo: `Group info`,
      main: `Messages`,
      newGroup: `New group`,
      newMessage: `New message`,
    },
  },
  inputAttachBtnTooltip: 'Attach file',
  inputEmojiBtnTooltip: 'Emoji',
  inputRemoveAttachmentBtnTooltip: 'Remove file',
  invalidFileSizeFileAddedToast: 'One or more files exceed the 100 MB limit.',
  invalidImageFileAddedToast: 'One or more files are no images and cannot be added to this field.',
  newDialog: {
    backBtnTooltip: 'Back',
    closeBtnTooltip: 'Close',
    placeholderText: `Direct messages are private conversations between you and other people on Tape.`,
    placeholderTitle: `Start a direct message with {{name}}`,
  },
  newGroup: {
    addUser: `Add this person to group`,
    backBtnTooltip: 'Back',
    closeBtnTooltip: 'Close',
    create: `Create`,
    groupNameDescription: `Group name`,
    groupNamePlaceholder: `Add group name`,
    next: `Next`,
    removeSelectedUser: `Remove this person from group`,
    searchUserGroupInput: `Add people to new group`,
  },
  newMessage: {
    backBtnTooltip: 'Back',
    closeBtnTooltip: 'Close',
    createNewGroupBtn: `New group`,
    newGroup: `New Group`,
    noOrgUsersPlaceholder: {
      buttonLabel: 'Invite people',
      subtitle: 'When you invite some, you can start a conversation here.',
      title: `You don't have any team members yet`,
    },
    searchUserGroupInput: `Search for people and groups`,
    searchUserGroupInputClearBtn: `Clear search input`,
    selectConversationDialog: `Start direct message with person`,
    selectConversationGroup: `Start group message`,
  },
  sendBtnTooltip: `Send <span class="${TIPPY_KEYBOARD_SHORTCUT}">Enter</span> <br> New line <span class="${TIPPY_KEYBOARD_SHORTCUT}">Shift + Enter</span>`,
  tooManyFilesAttachedToast: `You can only upload 4 files at a time.`,
} as const;
