export const forgotPasswordEnLangClient = {
  enterEmail: {
    btnLabel: 'Next',
    inputPlaceholder: 'you@example.com',
    invalidEmailError: 'Please enter a valid email address',
    subtitle: 'To reset your password, enter the email address you use to sign in to tapeapp.com/{{orgSlug}}.',
    title: 'Reset password',
  },
  enterNew: {
    btnLabel: 'Confirm',
    invalidNewPasswordConfirmError: `The passwords you entered don't match`,
    invalidNewPasswordError: {
      none: 'Please enter a new password',
      TOO_COMMON: 'Please enter a stronger password',
      TOO_SHORT: 'Your password must be at least 6 characters long',
    },
    newPassword: 'New password',
    newPasswordConfirm: 'Confirm new password',
    newPasswordConfirmPlaceholder: 'Minimum of 6 characters',
    newPasswordPlaceholder: 'Minimum of 6 characters',
    subtitle: 'Enter your new password for your Tape account.',
    title: 'Reset password',
  },
  linkInfo: {
    linkLabel: 'Try another email address',
    subtitle: `Click on the link to confirm your email address {{email}} and reset your password. If you didn't receive an email, please also check your spam folder.`,
    title: 'We sent you a password reset link!',
  },
};
