import { deLang } from '@t5s/shared/i18n/common';
import { activeUserDeLangClient } from './active-user/active-user.de';
import { activeUserEntityInfoDeLangClient } from './active-user/entity-info/active-user-entity-info.de';
import { activityDeLangClient } from './activity/activity.de';
import { billingDeLangClient } from './billing/billing.de';
import { blabDefActivityDeLangClient } from './blab-def-activity/blab-def-activity.de';
import { blabItemActivityDeLangClient } from './blab-item-activity/blab-item-activity.de';
import { coreDeLangClient } from './core/core.de';
import { flimDefDeClientLang } from './flim-def/flim-def.de';
import { ClientLangObjType } from './languages';
import { newsfeedDeLangClient } from './newsfeed/newsfeed.de';
import { reminderDeLangClient } from './reminder/reminder.de';
import { userActivityDeLangClient } from './user-activity/user-activity.de';
import { workspaceActivityDeLangClient } from './workspace-activity/workspace-activity.de';
import { yourContentLangDeClient } from './your-content/your-content.de';

export const deLangClient: ClientLangObjType = {
  ...deLang,
  core: coreDeLangClient,
  fm: {
    activeUser: activeUserDeLangClient,
    activeUserEntityInfo: activeUserEntityInfoDeLangClient,
    activity: activityDeLangClient,
    billing: billingDeLangClient,
    blabDefActivity: blabDefActivityDeLangClient,
    blabItemActivity: blabItemActivityDeLangClient,
    chat: {
      conversation: {
        addReactionBtnTooltip: 'Add reaction',
        backBtnTooltip: 'Zurück',
        callBtnTooltip: 'Anruf starten',
        closeBtnTooltip: 'Schließen',
        conversationCreated: `Konversation wurde erstellt.`,
        groupNameChanged: '{{userName}} hat den Gruppennamen auf {{groupName}} geändert.',
        groupThumbnailChanged: '{{userName}} hat das Gruppenfoto geändert.',
        infoBtnTooltip: 'Info',
        input: {
          placeholder: `Neue Nachricht beginnen`,
        },
        messageOnlyAttachments: `{{userName}} hat einen Anhang gesendet.`,
        messageOnlyLinkPreview: `{{userName}} hat einen Link geteilt.`,
        moreMenuTooltip: 'Weitere Aktionen',
        searchUser: `Füge Personen zur Gruppe hinzu`,
        userJoined: `{{userName}} hat {{targetUser}} hinzugefügt.`,
        userLeft: `{{userName}} hat die Gruppe verlassen.`,
        videoCallStarted: `{{userName}} hat einen Video-Anruf gestartet.`,
      },
      groupImageUploadBtnTooltip: 'Foto hinzufügen',
      startANewMessage: 'Neue Nachricht beginnen',
      videoActivity: {
        joinBtnLabel: 'Beitreten',
        started: 'Gestartet',
        title: 'Anruf mit {{ primaryName }}',
      },
      videoCall: {
        confirmConnect: {
          confirmButtonLabel: 'Verbinden',
          message: `Um Microsoft Teams Anrufe direkt aus Tape zu starten, musst du zunächst dein Microsoft-Konto verbinden.`,
          title: 'Microsoft-Konto verbinden?',
        },
        connectionFailedToast: 'Verbindung mit Microsoft-Konto fehlgeschlagen, bitte versuche es erneut.',
        connectionSuccessToast: 'Microsoft-Konto erfolgreich verbunden',
      },
    },
    flimDef: flimDefDeClientLang,
    newsfeed: newsfeedDeLangClient,
    reaction: {
      reactors: `{{reactorStr}} und {{finalReactorStr}}`,
      you: 'Du',
    },
    reminder: reminderDeLangClient,
    userActivity: userActivityDeLangClient,
    workspaceActivity: workspaceActivityDeLangClient,
    yourContent: yourContentLangDeClient,
  },
} as const;
