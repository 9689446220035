import gql from 'graphql-tag';
import { FullUserProfileDtoFragment } from '../user-profile';
import { UserSessionFragment } from '../user-session';

export const UserAuthenticationResultFragment = gql`
  fragment UserAuthenticationResultFragment on AuthenticateWithCredentialsResult {
    ... on UserSessionDto {
      ...UserSessionFragment
    }

    ... on AuthenticateWithCredentialsRejectionDto {
      reason
    }

    ... on AuthenticateWithCredentialsSuccessMfaRequiredDto {
      userId
      mfaMethodIds
      jwt

      user {
        ...FullUserProfileDto
      }
    }
  }
  ${UserSessionFragment}
  ${FullUserProfileDtoFragment}
`;
