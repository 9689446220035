import { TIPPY_KEYBOARD_SHORTCUT } from '@t5s/web-client/readonly-constant/tippy';

export const chatDeLangClient = {
  confirmDeleteChatMessage: {
    confirmButtonLabel: `Löschen`,
    message: `Bist du sicher, dass du diese Nachricht löschen möchtest? Dies kann nicht rückgängig gemacht werden.`,
    title: `Nachricht löschen?`,
  },
  conversation: {
    conversationCreated: `Direktnachricht wurde erstellt.`,
    groupNameChanged: '{{userName}} hat den Gruppennamen auf {{groupName}} geändert.',
    groupThumbnailChanged: '{{userName}} hat das Gruppenfoto geändert.',
    input: {
      placeholder: `Neue Nachricht beginnen`,
    },
    messageOnlyAttachments: `{{userName}} hat einen Anhang gesendet.`,
    searchUser: `Füge Personen zur Gruppe hinzu`,
    userJoined: `{{userName}} hat {{targetUser}} hinzugefügt.`,
    userLeft: `{{userName}} hat die Gruppe verlassen.`,
  },
  conversationInfo: {
    addMember: `Person hinzufügen`,
    addMemberAria: `Person zu Gruppe hinzufügen`,
    confirmLeaveGroup: {
      confirmButton: `Verlassen`,
      message: `Diese Gruppe wird aus deinem Posteingang gelöscht. Andere Personen in der Gruppe können sie nach wie vor sehen.`,
      title: `Gruppe verlassen?`,
    },
    leaveGroup: `Gruppe verlassen`,
    memberHeader: 'Personen',
    muteDialogConversation: `Direktnachricht stummschalten`,
    muteGroupConversation: `Gruppe stummschalten`,
    settingsHeader: 'Einstellungen',
    unmuteDialogConversation: `Direktnachricht lautschalten`,
    unmuteGroupConversation: `Gruppe lautschalten`,
  },
  conversationPreviewButton: `Öffne Nachricht`,
  deletemessageMenuItem: 'Nachricht löschen',
  dropzoneLabel: 'Hochladen in Nachricht',
  footer: {
    cancel: `Abbruch des aktuellen Prozesses`,
    finish: `Prozess abschließen`,
    next: `Fortfahren mit aktuellem Prozess`,
  },
  groupAddUsers: {
    addBtn: 'Hinzufügen',
    cancelBtn: 'Abbrechen',
  },
  header: {
    backBtn: `Gehe zurück`,
    closeRightSidebarAria: `Schließe rechte Seitenleiste`,
    newMessageAria: `Neue Nachricht beginnen`,
    titles: {
      addMember: `Person hinzufügen`,
      conversationInfoAria: `Zeige Nachrichten Info an`,
      directMessageInfo: `Direktnachricht Info`,
      groupInfo: `Gruppen Info`,
      main: `Nachrichten`,
      newGroup: `Neue Gruppe`,
      newMessage: `Neue Nachricht`,
    },
  },
  inputAttachBtnTooltip: 'Datei anhängen',
  inputEmojiBtnTooltip: 'Emojis',
  inputRemoveAttachmentBtnTooltip: 'Datei entfernen',
  invalidFileSizeFileAddedToast: 'Eine oder mehrere Dateien überschreiten das Limit von 100 MB.',
  invalidImageFileAddedToast:
    'Eine oder mehrere Dateien sind keine Bilder, und können diesem Feld nicht hinzugefügt werden.',
  newDialog: {
    backBtnTooltip: 'Zurück',
    closeBtnTooltip: 'Schließen',
    placeholderText: `Direktnachrichten sind private Unterhaltungen zwischen dir und anderen Personen auf Tape.`,
    placeholderTitle: `Starte eine Direktnachricht mit {{name}}`,
  },
  newGroup: {
    addUser: `Füge Personen zur Gruppe hinzu`,
    backBtnTooltip: 'Zurück',
    closeBtnTooltip: 'Schließen',
    create: `Erstellen`,
    groupNameDescription: `Gruppen Name`,
    groupNamePlaceholder: `Gruppen Name eingeben`,
    next: `Weiter`,
    removeSelectedUser: `Entferne Personen von Gruppe`,
    searchUserGroupInput: `Füge Personen der neuen Gruppe hinzu`,
  },
  newMessage: {
    backBtnTooltip: 'Zurück',
    closeBtnTooltip: 'Schließen',
    createNewGroupBtn: `Neue Gruppe`,
    newGroup: `Neue Gruppe`,
    noOrgUsersPlaceholder: {
      buttonLabel: 'Personen einladen',
      subtitle: 'Wenn du ein paar einlädst, kannst du hier eine Unterhaltung starten.',
      title: `Du hast bisher noch keine Team-Mitglieder`,
    },
    searchUserGroupInput: `Nach Personen und Gruppen suchen`,
    searchUserGroupInputClearBtn: `Eingabe leeren`,
    selectConversationDialog: `Direktnachricht mit Person starten`,
    selectConversationGroup: `Nachricht mit Gruppe starten`,
  },
  sendBtnTooltip: `Senden <span class="${TIPPY_KEYBOARD_SHORTCUT}">Enter</span> <br> Neue Zeile <span class="${TIPPY_KEYBOARD_SHORTCUT}">Shift + Enter</span>`,
  tooManyFilesAttachedToast: `Du kannst nur 4 Dateien auf einmal hochladen.`,
} as const;
