import { createAction, props } from '@ngrx/store';
import { DateFormat, DecimalFormat, FirstWeekday, Language, TimeFormat, MeasurementSystem } from '@t5s/shared/gql';

const I18N_PREFIX = '[client-store-internationalization]';

export const InternationalizationActions = {
  bootstrapLanguage: createAction(
    `${I18N_PREFIX} Bootstrap language via browser / local storage`,
    props<{ language: Language }>(),
  ),

  changeLanguage: createAction(`${I18N_PREFIX} Change language`, props<{ language: Language }>()),

  changeTimeFormat: createAction(`${I18N_PREFIX} Change timeFormat`, props<{ timeFormat: TimeFormat }>()),

  changeDateFormat: createAction(`${I18N_PREFIX} Change dateFormat`, props<{ dateFormat: DateFormat }>()),

  changeFirstWeekday: createAction(`${I18N_PREFIX} Change first weekday`, props<{ firstWeekday: FirstWeekday }>()),

  changeDecimalFormat: createAction(`${I18N_PREFIX} Change decimal format`, props<{ decimalFormat: DecimalFormat }>()),
  changeMeasurementSystem: createAction(
    `${I18N_PREFIX} Change measurement system`,
    props<{ measurementSystem: MeasurementSystem }>(),
  ),
};
