export const organizationInvitationDeLangClient = {
  accept: {
    invalidNameError: 'Bitte gib einen Namen ein',
    name: 'Name',
    namePlaceholder: 'Dein vollständiger Name',
    optInPromotionalEmails: 'Erhalte E-Mails über Tipps, Neuigkeiten und Angebote zu Tape.',
    password: 'Passwort',
    passwordError: {
      none: `Please enter a password.`,
      TOO_COMMON: `Bitte gib ein stärkeres Passwort ein`,
      TOO_SHORT: `Dein Passwort muss mindestens 6 Zeichen lang sein`,
    },
    passwordPlaceholder: 'Mindestens 6 Zeichen',
    signupBtnLabel: 'Account erstellen',
    termsAndConditionsInfo:
      'Indem du einen Account erstellst, stimmst du unseren Nutzungsbedingungen und unserer Datenschutzrichtlinie zu.',
    title: '{{orgName}} auf Tape beitreten',
  },
  create: {
    closeBtnTooltip: 'Schließen',
    headerTitle: 'Personen einladen',
    invitationCreatedToast: {
      messageMulti: `Einladungen erfolgreich gesendet. Du hast {{numInvitations}} Mitglieder in deine Organistation eingeladen.`,
      messageSingle: `Einladung erfolgreich gesendet. Du hast 1 Mitglied in deine Organistation eingeladen.`,
    },
    inviteAsDropdownLabel: 'Invite people as {{rolename}}',
    inviteBtnLabel: 'Einladen',
    placeholderSubtitle:
      'Gib die E-Mail-Adressen deiner Team-Mitglieder mit Kommas getrennt ein. Du kannst auch mehrere E-Mail-Adressen gleichzeitig einfügen.',
    placeholderTitle: 'Mit wem möchtest du Tape nutzen?',
    searchPlaceholder: 'Nach E-Mail-Adressen suchen',
    userAlreadyInvitedSubline: 'Bereits eingeladen',
  },
  manage: {
    acceptedAt: 'Beigetreten am {{acceptedAt}}',
    approvedBy: 'Genehmigt von {{approverName}}',
    autoApproval: 'Einladung erfolgreich gesendet',
    closeBtnTooltip: 'Schließen',
    deny: 'Ablehnen',
    invitation: 'Einladung',
    invitationSubline: {
      orgAdmin: '{{email}} als Admin',
      orgGuest: '{{email}} als Gast',
      orgMember: '{{email}} als Mitglied',
    },
    invitedBy: '{{createdAt}} von {{authorName}}',
    invitePeople: 'Personen einladen',
    noInvitationsPlaceholder: {
      subtitle: 'Wenn du es tust, wird das hier angezeigt.',
      title: 'Du hast bisher noch niemanden eingeladen',
    },
    pending: 'Ausstehend',
    sendInvitation: 'Einladung senden',
    status: 'Status',
    title: 'Einladungen',
  },
  settings: {
    invitationApproval: {
      requireApproval: 'Admin-Genehmigung verlangen',
      title: 'Einladungen',
    },
  },
};
