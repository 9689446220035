import gql from 'graphql-tag';
import { FindMyOrgUserHasPendingInvitationDtoNoNestingFragment } from '../../generated/graphql-fragments';
import { UserSessionFragment } from '../user-session';

export const AuthenticateWithFindMyOrgsTokenResultFragment = gql`
  fragment AuthenticateWithFindMyOrgsTokenResultFragment on AuthenticateWithFindMyOrgsTokenResult {
    ... on UserSessionDto {
      ...UserSessionFragment
    }

    ... on AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto {
      mfaMethodIds
      userId
      jwt
    }

    ... on FindMyOrgUserHasPendingInvitationDto {
      ...FindMyOrgUserHasPendingInvitationDtoNoNesting
    }
  }
  ${UserSessionFragment}
  ${FindMyOrgUserHasPendingInvitationDtoNoNestingFragment}
`;
