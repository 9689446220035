export const getStartedDeLangClient = {
  info: {
    subtitle:
      'Jedes Unternehmen oder Team, das Tape verwendet, verfügt über seine eigene Organisation. Hier werden alle ihre Datensätze, Dateien und Unterhaltungen aufbewahrt. Der Beitritt ist nur mit einer Einladung möglich.',
    title: 'Was ist eine Tape-Organisation? ',
  },
  signin: {
    btnLabel: 'Anmeldung in Tape',
    subtitle: 'Suche eine bestehende Organisation deines Teams und melde dich dort an.',
    title: 'Benutzt dein Team bereits Tape?',
  },
  signup: {
    btnLabel: 'Eine Tape-Organisation erstellen',
    subtitle: 'Erstelle eine komplett neue Organisation für dich und dein Team.',
    title: 'Du kannst Tape kostenlos testen',
  },
};
