export const yourContentLangDeClient = {
  explore: {
    newContentForYou: 'Neue Inhalte für dich',
    numBlabItemActivities: '{{numActivities}} Aktivitäten',
    oneBlabItemActivity: 'Eine Aktivität',
  },
  showLess: 'Weniger anzeigen',
  showMore: 'Mehr anzeigen',
  invite: 'Personen einladen',
  yourDay: {
    goodAfternoon: 'Guten Tag, {{username}}',
    goodEvening: 'Guten Abend, {{username}}',
    goodMorning: 'Guten Morgen, {{username}}',
  },
  yourTeam: 'Dein Team',
} as const;
