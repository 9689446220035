import { NgModule } from '@angular/core';
import { Store, StoreModule } from '@ngrx/store';
import { ActiveActorContextObservable, ActiveUserIdObservable } from '@t5s/client/provider-token/active-actor-context';
import { filterOnlyPresent } from '@t5s/shared/util/rxjs';
import { distinctUntilChanged, map } from 'rxjs/operators';
import { activeActorReducer, activeActorStateKey } from './active-actor.reducer';
import { selectActiveActorContext, selectActiveUserId } from './active-actor.selectors';

@NgModule({
  imports: [StoreModule.forFeature(activeActorStateKey, activeActorReducer)],
  providers: [
    {
      provide: ActiveActorContextObservable,
      useFactory: (store$: Store): ActiveActorContextObservable =>
        store$.select(selectActiveActorContext).pipe(
          filterOnlyPresent(),
          map((actor) => ({ actor })),
        ),
      deps: [Store],
    },
    {
      provide: ActiveUserIdObservable,
      useFactory: (store$: Store): ActiveUserIdObservable =>
        store$.select(selectActiveUserId).pipe(
          distinctUntilChanged(),
          filterOnlyPresent(),
          map((activeUserId) => ({ activeUserId })),
        ),
      deps: [Store],
    },
  ],
})
export class ActiveActorContextStoreModule {}
