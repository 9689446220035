export const organizationSettingsDeLangClient = {
  billingDetails: {
    saveBtn: 'Speichern',
    title: 'Abrechnungsdetails',
  },
  billingOverview: {
    title: `Übersicht`,
  },
  closeBtnTooltip: 'Schließen',
  generalSettings: {
    deleteInfoModal: {
      confirmButtonLabel: 'Kontaktieren',
      message:
        'Auf Grund userer hohen Anforderungen an den Datenschutz musst du für die Löschung deiner Organisation unseren Support kontaktieren.',
      title: 'Organisation löschen?',
    },
    title: `Allgemeine Einstellungen`,
  },
  invitations: {
    title: 'Einladungen',
  },
  members: {
    title: 'Mitglieder',
  },
  organizationProfile: {
    changeSlugModal: {
      confirmButtonLabel: 'Bearbeiten',
      message: `Alle Links, die die aktuelle URL verwenden, funktionieren nicht mehr, wenn du die Organisations-URL änderst.`,
      title: 'Organisations-URL bearbeiten?',
    },
    saveBtn: 'Speichern',
    title: 'Organisationsprofil bearbeiten',
  },
  paymentMethods: {
    title: `Zahlungsmethoden`,
  },
  sidemenu: {
    billing: {
      billingDetails: 'Abrechnungsdetails',
      invoiceHistory: 'Rechnungen',
      overview: 'Abrechnungsübersicht',
      paymentMethod: 'Zahlungsmethoden',
      sectionName: 'Abrechnung',
    },
    generalSettings: `Allgemeine Einstellungen`,
    invitations: `Einladungen`,
    manageMembers: `Mitglieder verwalten`,
    security: `Sicherheit`,
    orgProfile: 'Organisationsprofil',
    teams: `Teams`,
    workspaces: `Workspaces`,
  },
  teams: {
    title: 'Teams',
  },
  title: 'Einstellungen',
  workspaces: {
    title: 'Workspaces verwalten',
  },
} as const;
