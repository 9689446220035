export const organizationInvitationEnLangClient = {
  accept: {
    invalidNameError: 'Please enter a name',
    name: 'Name',
    namePlaceholder: 'Your full name',
    optInPromotionalEmails: 'Receive email with Tape tips, news, and offers.',
    password: 'Password',
    passwordError: {
      none: `Please enter a password`,
      TOO_COMMON: `Please enter a stronger password`,
      TOO_SHORT: `Your password must be at least 6 characters long`,
    },
    passwordPlaceholder: 'Minimum of 6 characters',
    signupBtnLabel: 'Create account',
    termsAndConditionsInfo: 'By creating an account, you’re agreeing to our Terms & Conditions and Privacy Policy.',
    title: 'Join {{orgName}} on Tape',
  },
  create: {
    closeBtnTooltip: 'Close',
    headerTitle: 'Invite people',
    invitationCreatedToast: {
      messageMulti: `Invitations sent successfully. You've invited {{numInvitations}} members to your organization.`,
      messageSingle: `Invitation sent successfully. You've invited 1 member to your organization.`,
    },
    inviteAsDropdownLabel: 'Invite people as {{rolename}}',
    inviteBtnLabel: 'Invite',
    placeholderSubtitle:
      'Enter the email of your teammates separated by commas. You can also paste multiple email addresses at once.',
    placeholderTitle: 'Who would you like to use Tape with?',
    searchPlaceholder: 'Search for email addresses',
    userAlreadyInvitedSubline: 'Already invited',
  },
  manage: {
    acceptedAt: 'Joined {{acceptedAt}}',
    approvedBy: 'Approved by {{approverName}}',
    autoApproval: 'Auto-approval',
    closeBtnTooltip: 'Close',
    deny: 'Deny',
    invitation: 'Invitation',
    invitationSubline: {
      orgAdmin: '{{email}} as Admin',
      orgGuest: '{{email}} as Guest',
      orgMember: '{{email}} as Member',
    },
    invitedBy: '{{createdAt}} by {{authorName}}',
    invitePeople: 'Invite people',
    noInvitationsPlaceholder: {
      subtitle: 'When you do, they will show up here.',
      title: 'You haven’t invited someone yet',
    },
    pending: 'Pending',
    sendInvitation: 'Send invitation',
    status: 'Status',
    title: 'Invitations',
  },
  settings: {
    invitationApproval: {
      requireApproval: 'Require organization admin approval',
      title: 'Invitation approval',
    },
  },
};
