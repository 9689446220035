export const yourContentLangEnClient = {
  explore: {
    newContentForYou: 'New content for you',
    numBlabItemActivities: '{{numActivities}} activities',
    oneBlabItemActivity: '1 activity',
  },
  showLess: 'Show less',
  showMore: 'Show more',
  invite: 'Invite people',
  yourDay: {
    goodAfternoon: 'Good afternoon, {{username}}',
    goodEvening: 'Good evening, {{username}}',
    goodMorning: 'Good morning, {{username}}',
  },
  yourTeam: 'Your Team',
} as const;
