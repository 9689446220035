import { createReducer, on } from '@ngrx/store';
import { ActiveActorContextUnion } from '@t5s/shared/value-object/active-actor-context';
import { ActiveActorActions } from './active-actor.actions';

export const activeActorStateKey = 'activeActor';
export interface ActiveActorState {
  actor: ActiveActorContextUnion | undefined;
}

const initialState: ActiveActorState = {
  actor: undefined,
};

export const activeActorReducer = createReducer(
  initialState,
  on(ActiveActorActions.setActiveActorContext, (state, { actor }) => ({
    ...state,
    actor,
  })),
);
