export const organizationSidebarEnLangClient = {
  addWorkspaceBtnTooltip: 'Add workspace',
  addWorkspaceMenu: {
    useTemplateBtn: 'Choose from templates',
    blankWorkspace: 'New workspace',
  },
  collapseSidebarBtnTooltip: 'Close sidebar',
  expandSidebarBtnTooltip: 'Open sidebar',
  organizationMenu: {
    add: `Add another organization`,
    admin: `Organization settings`,
    invite: `Invite people`,
    workflowCenterButton: `Automations`,
    new: `New`,
    orgPage: `Organization page`,
  },
};
