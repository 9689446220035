export const userSettingsDeLangClient = {
  account: {
    email: {
      confirmPassword: 'Dein Passwort zur Bestätigung',
      confirmPasswordErrorIncorrect: 'Dein Passwort ist falsch',
      confirmPasswordErrorNone: 'Dein Passwort ist erforderlich',
      confirmPasswordPlaceholder: 'Dein aktuelles Passwort',
      currentEmail: 'Aktuelle E-Mail-Adresse',
      forgotPassword: 'Passwort vergessen?',
      newEmail: 'Neue E-Mail-Adresse',
      newEmailErrorInvalid: `Neue E-Mail-Adresse muss eine gültige E-Mail-Adresse sein`,
      newEmailErrorUnavailable: `Neue E-Mail-Adresse wird von einem anderen Account benutzt`,
      newEmailPlaceholder: 'du@beispiel.de',
      next: 'Weiter',
      successToast: 'Deine E-Mail-Adresse wurde erfolgreich geändert.',
      title: 'E-Mail-Adresse ändern',
    },
    emailVerification: {
      title: 'Wir haben dir einen Code gesendet!',
      tryAnotherEmail: 'Andere E-Mail-Adresse versuchen',
      verificationCode: 'Verifizierungscode',
      verificationCodeErrorIncorrect: 'Verifizierungscode ist ungültig, versuche es einfach noch einmal',
      verificationCodeErrorNone: 'Verifizierungscode ist erforderlich',
      verificationCodeLabel: 'Gib ihn hier zur Verifizierung von {{email}} ein.',
      verificationCodePlaceholder: 'Verifizierungscode',
      verifyBtn: 'Weiter',
    },
    formats: {
      dateFormat: 'Datumsformat',
      decimalFormat: 'Dezimalsymbol',
      decimalFormats: {
        DECIMAL_SEPARATOR_COMMA: ',',
        DECIMAL_SEPARATOR_DOT: '.',
      },
      measurementSystem: 'Maßeinheiten',
      measurementSystems: {
        METRIC: 'Metrisch',
        IMPERIAL_US: 'USA',
      },
      firstWeekday: 'Erster Wochentag',
      language: 'Sprache',
      languagePlaceholder: 'Sprache auswählen',
      setAutomatically: 'Zeitzone automatisch einstellen',
      timeFormat: 'Uhrzeitformat',
      timeFormat12h: '12-Stunden',
      timeFormat24h: '24-Stunden',
      timeZone: 'Zeitzone',
      autoApplyTimezoneLabel: 'Zeitzone automatisch festlegen',
      timezoneDescription:
        'Tape benutzt deine Zeitzone, um Zusammenfassungen und E-Mail-Benachrichtigungen zu senden und als Standard für neue Automatisierungen.',
      title: 'Sprache und Formate',
      weekday: {
        MONDAY: 'Montag',
        SATURDAY: 'Samstag',
        SUNDAY: 'Sonntag',
      },
    },
    password: {
      currentPassword: 'Aktuelles Passwort',
      currentPasswordError: 'Aktuelles Passwort ist falsch',
      currentPasswordErrorNone: 'Aktuelles Passwort ist erforderlich',
      currentPasswordPlaceholder: 'Dein aktuelles Passwort',
      forgotPassword: 'Passwort vergessen?',
      newPassword: 'Neues Passwort',
      newPasswordConfirm: 'Neues Passwort bestätigen',
      newPasswordConfirmMatchError: `Die von dir eingegebenen Passwörter stimmen nicht überein`,
      newPasswordConfirmPlaceholder: 'Mindestens 6 Zeichen',
      newPasswordErrorCommon: 'Neues Passwort muss stärker sein',
      newPasswordErrorNone: 'Neues Passwort ist erforderlich',
      newPasswordErrorShort: 'Neues Passwort muss mindestens 6 Zeichen lang sein',
      newPasswordPlaceholder: 'Mindestens 6 Zeichen',
      save: 'Speichern',
      successToast: 'Dein Passwort wurde erfolgreich geändert.',
      title: 'Passwort ändern',
    },

    accountSecurity: {
      headline: 'Kontosicherheit',

      mfaLabel: 'Zweistufige Verifizierung',
      mfaDisabledSublabel:
        'Sichere dein Konto zusätzlich, indem du deine Identität mit einem einmaligen Code verifizierst.',
      mfaEnabledSublabel: 'Du hast 2 Verifizierungsmethoden aktiviert.',
      mfaDisabledButtonLabel: 'Verifizierungsmethode hinzufügen',
      mfaEnabledButtonLabel: 'Verifizierungsmethoden ändern',
    },
  },
  closeBtnTooltip: 'Schließen',
  connectedAccounts: {
    title: 'Verbundene Konten',
  },
  notifications: {
    browserNotifications: 'Desktop-Benachrichtigungen',
    browserNotificationsBlocked: 'Desktop-Benachrichtigungen wurden in deinem Browser deaktiviert',
    browserNotificationsInlineLabel: 'Desktop-Benachrichtigungen aktivieren',
    browserNotificationsNote: `Mit Benachrichtigungen bleibst du auf dem Laufenden darüber, was passiert, wenn du gerade nicht auf Tape bist.`,
    browserNotificationsNoteBlocked:
      'Um Desktop-Benachrichtigungen von Tape zu erhalten, musst du sie zunächst in den Browseeinstellungen zulassen.',

    emailNotificationsTitle: 'E-Mail-Benachrichtigungen',

    userEmailNotificationsWhenOffline: {
      title: 'Wichtige Aktivität in deiner Organisation',
      note: `Erhalte E-Mails bei Erwähnungen, zugewiesenen Einträgen und zugewiesenen Checklisten-Einträgen.`,
    },

    userEmailNotificationsWhenOnline: {
      title: 'Immer E-Mail-Benachrichtigungen senden',
      note: `Erhalte E-Mails zu Wichtigen Aktivitäten in deine Organisation, selbst wenn du Tape aktiv nutzt.`,
    },

    emailNotifications: 'Updates bei Abwesenheit',
    emailNotificationsNote: `Erhalte alle zwei Stunden eine E-Mail bei neuen Benachrichtigungen und Direktnachrichten wenn du abwesend bist.`,

    placeholderText: `Bevor du Benachrichtigungen von Tape erhältst, musst du diese in den Browsereinstellungen zulassen.`,
    placeholderTitle: `Benachrichtigungen aktivieren?`,
    title: 'Benachrichtigungen',
  },
  sidemenu: {
    about: 'Über Tape',
    account: {
      email: 'E-Mail-Adresse',
      language: 'Sprachen und Formate',
      password: 'Passwort',
      sectionName: 'Account',
      security: 'Sicherheit',
    },
    api: 'API',
    connectedAccounts: 'Verbundene Accounts',
    notifications: 'Benachrichtigungen',
    profile: 'Profil',
  },
  title: 'Einstellungen',
} as const;
