export const getStartedEnLangClient = {
  info: {
    subtitle:
      'Each team that uses Tape has an organization of their own. It’s where all of their records, files and conversations are kept, and it can only be joined by invitation.',
    title: 'What’s a Tape organization? ',
  },
  signin: {
    btnLabel: 'Sign in to Tape',
    subtitle: 'Find and sign in to your team’s existing organization.',
    title: 'Is your team already using Tape?',
  },
  signup: {
    btnLabel: 'Create a Tape organization',
    subtitle: 'Create a brand-new organization for you and your team.',
    title: 'Try Tape with your team, for free',
  },
};
