export const blabItemEnLangClient = {
  dropzoneLabel: 'Upload to {{itemName}}',
  flimVal: {
    add: 'Add {{flimDefName}}',
    attachment: {
      add: 'Add',
      attachmentMetaInfo: 'Added by {{user}}',
      delete: 'Delete',
      download: 'Download',
      dropFilesHere: 'Drop files here',
      rename: 'Rename',
    },
    multiLink: {},
    multiText: {
      add: `Add {{flimDefName}}`,
      format: `Type '/' or select text for formatting`,
    },
    relation: {
      addBlabItemReferenceMultiRelation: 'Add',
      addBlabItemReferenceSingleRelation: 'Add',
      chooseBlabDef: `Choose an app`,
      overlay: {
        addBtnLabel: `Add`,
        createBtnLabel: `Create new`,
        placeholderText: `Unfortunately no content with this name could be found in related apps.`,
        placeholderTitle: `No content found in related apps!`,
      },
    },
    user: {
      addUserReferenceMultiUser: 'Add',
      addUserReferenceSingleUser: 'Add',
      overlay: {
        addBtnLabel: `Add`,
        placeholderText: `Unfortunately no member with this name could be found in this workspace.`,
        placeholderTitle: `Member not found!`,
      },
    },
    validationError: {
      END_DATE_REQUIRED: 'Stard and end date are required',
      END_DATE_TIME_REQUIRED: `Start date, start time, end date and end time are required`,
      IS_REQUIRED: '{{flimDefName}} is required',
      TIME_REQUIRED: 'Time is required',
      MUST_BE_NUMBER: '{{flimDefName}} must be a number',
      NUMBER_TOO_SMALL: '{{flimDefName}} subceeds the minimum value of the number field',
      NUMBER_TOO_LARGE: '{{flimDefName}} exceeds the maximum value of the number field',
    },
  },
  notifications: {
    creationFailed: 'Creation failed.',
  },
} as const;
