export const userTrashEnLangClient = {
  emptyTrashModal: {
    confirmButtonLabel: 'Empty',
    message: 'This will permanently remove these records from the trash and they will no longer be restorable.',
    title: 'Empty trash?',
  },
  removeFromTrashModal: {
    confirmButtonLabel: 'Remove',
    message: 'This will permanently remove this record from the trash and it will no longer be restorable.',
    title: 'Remove from trash?',
  },
} as const;
