import { AuthenticateWithFindMyOrgsTokenRejectionDto } from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isAuthenticateWithFindMyOrgsTokenRejectionDto(
  candidate: any,
): candidate is AuthenticateWithFindMyOrgsTokenRejectionDto {
  return isDto<AuthenticateWithFindMyOrgsTokenRejectionDto>(candidate, 'AuthenticateWithFindMyOrgsTokenRejectionDto');
}

export function isAuthenticateWithFindMyOrgsTokenRejectionDtoOrFail(
  candidate: any,
): AuthenticateWithFindMyOrgsTokenRejectionDto {
  const result = isAuthenticateWithFindMyOrgsTokenRejectionDto(candidate);

  if (!result) {
    throw new Error(`Candidate ${candidate} is not of type AuthenticateWithFindMyOrgsTokenRejectionDto`);
  }

  return candidate;
}
