export const organizationSettingsGeneralEnLangClient = {
  delete: 'Delete',
  deleteOrganization: 'Delete organization',
  deleteOrganizationInfo:
    'Deleting a Tape organization cannot be undone. All content and files will be irretrievable deleted. Please use caution before deletion.',
  invitations: 'Invitations',
  invitationsInfo:
    'By default, any member can invite new people to your workspace. If you’d like, you can change this so invitations require an organization admin approval.',
  invitationsLabel: 'Require organization admin approval',
  nameDisplay: 'Name display',
  nameDisplayLabel: 'Show full names instead of display names',
  organizationLanguage: 'Organization language',
  organizationLanguageInfo: 'This affects system notifications and sign-up emails.',
  organizationLanguagePlaceholder: `Select organization language`,
};
