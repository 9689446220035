export const newsfeedDeLangClient = {
  blabDef: {
    breadcrumb: 'App in {{workspace}}',
  },
  blabItem: {
    breadcrumb: '{{blabDef}} in {{workspace}}',
  },
  blabItemActivity: {
    breadcrumb: '{{blabItem}} · {{blabDef}} in {{workspace}}',
  },
  loadMoreReplies: '{{numReplies}} more replies',
  loadMoreReply: '1 more reply',
  organization: {
    breadcrumb: 'Organisation',
  },
  toast: {
    followBlabItemSuccess: 'Du folgst jetzt {{entityName}}',
    followUserSuccess: 'Du folgst jetzt {{entityName}}',
    unfollowBlabItemSuccess: 'Du folgst {{entityName}} nicht mehr',
    unfollowUserSuccess: 'Du folgst {{entityName}} nicht mehr',
  },
  user: {
    breadcrumb: 'Mitglied von {{organization}}',
  },
  workspace: {
    breadcrumb: 'Workspace in {{organization}}',
  },
} as const;
