import { ActiveActorContextUnion, ActiveActorType } from '@t5s/shared/value-object/active-actor-context';

/**
 * Get the active organization ID from the active actor context or undefined if the active actor context is not defined
 * @param actor Active actor context
 * @returns Active organization ID or undefined
 */
export function getActiveActorOrganizationId(actor: ActiveActorContextUnion | null | undefined): number | undefined {
  return actor?.organizationId;
}

export function getActiveActorId(actor: ActiveActorContextUnion): string;
export function getActiveActorId(actor: ActiveActorContextUnion | null | undefined): string | undefined;
export function getActiveActorId(actor: ActiveActorContextUnion | null | undefined): string | undefined {
  if (!actor) return undefined;
  switch (actor.type) {
    case ActiveActorType.USER: {
      return `${ActiveActorType.USER}-${actor.activeUserId}`;
    }

    case ActiveActorType.ORGANIZATION_SHARELINK: {
      return `${ActiveActorType.ORGANIZATION_SHARELINK}-${actor.organizationId}`;
    }

    case ActiveActorType.WORKSPACE_SHARELINK: {
      return `${ActiveActorType.WORKSPACE_SHARELINK}-${actor.workspaceId}`;
    }

    case ActiveActorType.BLAB_DEF_SHARELINK: {
      return `${ActiveActorType.BLAB_DEF_SHARELINK}-${actor.blabDefId}`;
    }

    case ActiveActorType.BLAB_ITEM_SHARELINK: {
      return `${ActiveActorType.BLAB_ITEM_SHARELINK}-${actor.blabItemId}`;
    }
  }
}

export function isActiveActorId(str: unknown): str is string {
  if (typeof str !== 'string') return false;

  const [type, id] = str.split('-');
  if (!Object.values(ActiveActorType).includes(type as ActiveActorType)) {
    return false;
  }

  if (parseInt(id, 10)?.toString() !== id) {
    return false;
  }

  return true;
}
