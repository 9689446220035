const APPROVED = true;

const BREAKING_CHANGES = [
  1669729237528, 1608038913591, 1649326173532, 1642513448535, 1646728584726, 1647874933652, 1694862994125,
  1619953089865, 1661350794560, 1670581698772, 1613322153019, 1642967168799, 1673609677008, 1652460349879,
  1628775089982, 1607173782004, 1635238897861, 1656335594539, 1613300029094, 1634636422265, 1649099328934,
  1615913332874, 1657020003473, 1615316753730, 1736247066536, 1619956214741, 1657525368164, 1615812230655,
  1642413510016, 1649426537695, 1616083886924, 1634902215151, 1670412413977, 1647199362610, 1643030873581,
  1620385393095, 1677095190387, 1625839039858, 1649404935522, 1668168227723, 1649239622680, 1608302727640,
  1668513672450, 1670505848696, 1606911040383, 1635679135246, 1636542801916, 1634630028282, 1620056876106,
  1677090775258, 1649164300378, 1677002907703, 1608302100985, 1634556490388, 1643122063167, 1620322446393,
  1606652914361, 1634640710738, 1656439829212, 1635246936794, 1634897459400, 1619359133027, 1607183467305,
  1607259092138, 1636113667486, 1649075938248, 1605103094550, 1620491572038, 1605547197447, 1643191149811,
  1636127127913, 1673972302816, 1618572663283, 1621181299928, 1607379930720, 1608126414493, 1636109608561,
  1615894386796, 1610457662755, 1624366933821, 1664887648457, 1616573926614, 1639747684891, 1620328647575,
  1667222501498, 1654520218246, 1614281667644, 1634564501626, 1607191823250, 1624796773819, 1609673151068,
  1656692407337, 1657471750392, 1624992874820, 1652873520584, 1611483908033, 1635674446033, 1656860837118,
  1649258170696, 1652625279023, 1649237148086, 1663076091067, 1667320413615, 1635694068887, 1638184785400,
  1612532171847, 1636912353241, 1654510718720, 1607187957821, 1662213009802, 1637671176387, 1670427004407,
  1657272636566, 1656847588849, 1677063349634, 1656690271064, 1652886178676, 1616083050801, 1656682553994,
  1661419134802, 1639389936385, 1637767279166, 1670509975870, 1657449812413, 1649269486191, 1625846336668,
  1657448551319, 1668533183224, 1634550487775, 1662221124237, 1605103094550, 1646671117495, 1621165864836,
  1605103094550, 1671039266971, 1615978328168, 1654272298625, 1605103094550, 1637857824641, 1649885334611,
  1621762579111, 1657455148168, 1607175465973, 1609671817414, 1656340879530, 1676107034411, 1634914118096,
  1649168468152, 1624276534424, 1619181044993, 1649333821668, 1619943674134, 1605103094550, 1634657305493,
  1619719587569, 1670507061317, 1652882426755, 1649175377982, 1619518774601, 1635586972268, 1637765557261,
  1710928653377, 1649184491166, 1670430700253, 1605266558319, 1679080743781, 1656235048182, 1670602266069,
  1639675730373, 1611500805357, 1605103094550, 1661438184295, 1659706605719, 1676911617540, 1657194163083,
  1676903977709, 1670513151744, 1662989350177, 1635594216068, 1637778994324, 1634387965372, 1637607979743,
  1657284495129, 1659709387733, 1677084127542, 1657473014055, 1605278895659, 1652630215753, 1670428466343,
  1637575908705, 1607180224497, 1670409622873, 1608290394297, 1622366694353, 1615890511859, 1621668281435,
  1653033844479, 1661444972342, 1665408650695, 1670353700290, 1616775249583, 1631197559556, 1673534980370,
  1639481233642, 1671043828963, 1736262823037, 1631692050911, 1663061868074, 1637416449022, 1653029474457,
  1649322858020, 1670416231991, 1656946289092, 1671030789854, 1605103094550, 1676999511580, 1654266917614,
  1619172199231, 1657445787182, 1619883437032, 1607189412657, 1657018649395, 1661530987055, 1639390678314,
  1682066020598, 1670595203183, 1619280886970, 1605103094550, 1615750740535, 1616066657086, 1607258251182,
  1677086456071, 1605103094550, 1643108740674, 1643018106026, 1619278233988, 1642501232626, 1619688102420,
  1669801422364, 1657196950194, 1667991253866, 1605273603015, 1637577965936, 1665151617971, 1697721816966,
  1668538176488, 1613310790806, 1615821728636, 1636543917051, 1617809792711, 1656256797792, 1620224466846,
  1635688207952, 1636558583607, 1656695317199, 1645889419543, 1636204227868, 1682001565738, 1657219412769,
  1671445943063, 1682088811221, 1633788402028, 1665845457157, 1636114738902, 1642500287667, 1679221060941,
  1659874103882, 1605289184380, 1659711817067, 1626954272059, 1611925794547, 1655999512053, 1659874282861,
  1623423698427, 1628791056009, 1671471506022, 1618563317161, 1673526821237, 1637002911459, 1646574974989,
  1619954287077, 1659609603831, 1612622522603, 1607180305224, 1670526729334, 1656327561798, 1666795476869,
  1671027366056, 1637666561470, 1668519906687, 1649238289974, 1606654722676, 1657525057833, 1611504048953,
  1620289947215, 1605103094550, 1671042305717, 1634985592614, 1662218245418, 1661589286642, 1678641335215,
  1657276573190, 1639403142532, 1616082949857, 1639746395235, 1637680105465, 1670508513340, 1613210265643,
  1656846161815, 1663010319177, 1653667752662, 1667574273651, 1652870598042, 1710866341655, 1658749557428,
  1656336822622, 1639399274670, 1639228804086, 1619881649736, 1606760414369, 1677955764668, 1637490677747,
  1607182909630, 1634911847013, 1646315999132, 1661352908488, 1664901400445, 1616071215920, 1605103319084,
  1615811794294, 1647196366230, 1677084927190, 1657538159963, 1682081294911, 1662284254201, 1637510893681,
  1652462184173, 1612199187311, 1616078448621, 1615828288883, 1637407118743, 1643561525692, 1611926519198,
  1606918576723, 1637843631377, 1606568146557, 1636126309485, 1657465008738, 1619955527170, 1657102981552,
  1665221927280, 1662285007594, 1619525975372, 1620118983302, 1634561393945, 1616170763763, 1626955150733,
  1607369187094, 1621192998082, 1607862534515, 1635520882481, 1691421419853, 1637424382548, 1615904882087,
  1657472771834, 1607860876829, 1669728909332, 1638459169357, 1656690091917, 1643194346572, 1619524564835,
  1649694145158, 1611853291101, 1677003201368, 1615752705596, 1637608935565, 1634555458978, 1673521125514,
  1658421370032, 1637503065908, 1646660883676, 1635263567780, 1669751435271, 1664982726946, 1682004879895,
  1606918133700, 1677171209428, 1670528825373, 1620122942720, 1665408340280, 1618653031206, 1668255589961,
  1642345519256, 1671039927415, 1649234496975, 1606410047192, 1605103094550, 1616774532506, 1637746638265,
  1614330989779, 1639408641472, 1622296437420, 1682005098450, 1698763191990, 1610459007549, 1635248900892,
  1637059411268, 1615304816959, 1659635232131, 1649082630840, 1661347731097, 1618589252981, 1637406073768,
  1661422493157, 1661591878443, 1631096341561, 1642344409277, 1649343398317, 1658747774612, 1605549769580,
  1656949132785, 1670515636098, 1646382684836, 1679061820677, 1612613827672, 1643027745003, 1673984273458,
  1636196436779, 1634570317690, 1673979912620, 1682083586427, 1615305567611, 1618565856036, 1673524558175,
  1625839839898, 1642417923204, 1622120894250, 1628589363080, 1656853412122, 1637681097711, 1657473154357,
  1656436541705, 1691144447779, 1692951852914, 1636731496559, 1605273998962, 1673869846205, 1679239641921,
  1607532595831, 1608117733892, 1615916300815, 1631095557049, 1624970870662, 1656509103106, 1649097797327,
  1660483109290, 1634739126588, 1621190746360, 1613330848724, 1646576637996, 1605184599888, 1637587524272,
  1661346118665, 1736257593780, 1673517242663, 1624365796174, 1673892441657, 1673964995014, 1662287018657,
  1642505581439, 1671044970764, 1606920216408, 1682012486942, 1673867777839, 1645805938070, 1677094046776,
  1616067839145, 1620117472876, 1605544813338, 1659714007710, 1622452202255, 1664903073284, 1677173345661,
  1649241316540, 1647194830610, 1634581231595, 1673966591994, 1606911886454, 1628766207899, 1607861928830,
  1614327499442, 1647100641414, 1619168218948, 1673973444432, 1643115747423, 1605103094550, 1634480860149,
  1657459384772, 1682060005298, 1670590053714, 1605103094550, 1620394702029, 1677955778764, 1670265538472,
  1615889233402, 1612205242132, 1616077917850, 1634996142913, 1611835182062, 1653216971533, 1637005161690,
  1657287085438, 1677231559470, 1621193580202, 1615909506968, 1677077552472, 1637588591126, 1606046761835,
  1643019687504, 1656941244295, 1649153039813, 1635593715291, 1610464258993, 1677143862644, 1656580577926,
  1634655236396, 1637750587505, 1643280096391, 1657278722162, 1649081957489, 1681994722060, 1606919440156,
  1656708262792, 1646065450224, 1608116716251, 1613125300659, 1605103094550, 1642424481262, 1635524592353,
  1620493673161, 1635680154141, 1605103094550, 1681988948570, 1642961874793, 1619279608584, 1652551284744,
  1605281112503, 1657463111534, 1659085228712, 1621176642502, 1615316120177,
];

export const LAST_BREAKING_CHANGE = Math.max(...BREAKING_CHANGES);