import { AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto } from '@t5s/shared/gql';
import { isDto } from './dto.type-guard';

export function isAuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto(
  candidate: any,
): candidate is AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto {
  return isDto<AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto>(
    candidate,
    'AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto',
  );
}

export function isAuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDtoOrFail(
  candidate: any,
): AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto {
  const result = isAuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto(candidate);

  if (!result) {
    throw new Error(`Candidate ${candidate} is not of type AuthenticateWithFindMyOrgsTokenSuccessMfaRequiredDto`);
  }

  return candidate;
}
