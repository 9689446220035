export const flimDefSettingsDeLangClient = {
  addButtonLabel: 'Erstellen',
  attachment: {
    onlyAllowSingle: 'Hinzufügen von nur einer Datei erlauben',
  },
  backBtnTooltip: 'Zurück',
  category: {
    layoutSettings: 'Layout-Einstellungen',
    addBtnTooltip: 'Option hinzufügen',
    dropdown: 'Dropdown-Liste',
    inline: 'Reihe',
    list: 'Liste',
    newOptionLabel: 'Option',
    optionDefLabelPlaceholder: 'Option hinzufügen',
    optionDefsPlaceholder: 'Noch keine Option hinzugefügt.',
    removeBtnTooltip: 'Option entfernen',
    selectColorBtnTooltip: 'Optionsfarbe wählen',
    moveTooltip: 'Bewegen',
    conversionRemoveBtnDisabledTooltip:
      'Option kann nicht entfernt werden bevor die Feld Konvertierung abgeschlossen ist',
  },
  checklist: {
    showCompletedDefault: `Erledigte Checklisten-Einträge ausblenden`,
    showInFocus: 'Checklisten-Einträge im Fokus anzeigen',
  },
  closeBtnTooltip: 'Schließen',
  createdAt: {
    showInRecord: 'In Eintrag anzeigen',
    showTime: 'Zeit anzeigen',
  },
  createdBy: {
    showInRecord: 'In Eintrag anzeigen',
  },
  lastModifiedAt: {
    showInRecord: 'In Eintrag anzeigen',
    showTime: 'Zeit anzeigen',
  },
  lastModifiedBy: {
    showInRecord: 'In Eintrag anzeigen',
  },
  date: {
    calendarSettings: {
      showInCalendar: 'Im Kalender anzeigen',
      title: 'Kalender Einstellungen',
    },
    includeTime: 'Zeit anzeigen',
    isDueDateOfStatusField: 'Als Fälligkeitsdatum für Status Feld setzen',
    isDueDateOfStatusFieldDisabledTooltip: 'Status Feld wird benötigt',
    onlyAllowSingle: 'Enddatum anzeigen',
    requireEndDate: 'Enddatum benötigt',
    requireTime: 'Zeit benötigt',
    showInCalendar: 'Im Kalender anzeigen',
  },
  number: {
    format: 'Format Einstellungen',
    showUnitPrefix: 'Einheit als Präfix anzeigen',
    showUnitSuffix: 'Einheit als Suffix anzeigen',
    hideThousandSeparator: 'Tausendertrennzeichen anzeigen',
    formatInput: {
      placeholderPrefix: 'Einheit Präfix',
      placeholderSuffix: 'Einheit Suffix',
    },
  },
  displaySettings: {
    hiddenIfEmpty: 'Verborgen wenn leer',
    alwaysHidden: 'Immer verborgen',
    title: 'Anzeigeeinstellungen',
  },
  doneButtonLabel: 'Speichern',
  fieldName: 'Name',
  fieldNamePlaceholder: 'Name hinzufügen',
  fieldType: 'Feldtyp',
  fieldTypeSettings: {
    defaultValue: 'Standardwert festlegen',
    title: 'Feldtyp Einstellungen',
  },
  generalSettings: {
    helpText: 'Hilfetext',
    helpTextPlaceholder: 'Hilfetext hinzufügen',
    requiredField: 'Erforderliches Feld',
    showHelpText: 'Hilfetext anzeigen',
    title: 'Allgemeine Einstellungen',
  },
  location: {
    showMap: 'Karte anzeigen',
  },
  multiText: {
    disableRichTextFormatting: 'Rich-Text-Formatierung deaktivieren',
  },
  overlayTitle: 'Feld bearbeiten',
  overlayTitleAdd: 'Feld erstellen',
  overlayTitleConversion: 'Feldtyp ändern',
  phone: {
    callLinkProtocolHeadline: 'Verbindungsart',
    callLinkProtocolOptions: {
      callto: 'Callto',
      tel: 'Tel',
    },
    allowOnlySingleEntry: 'Hinzufügen von nur einer Telefonnummer erlauben',
  },
  email: {
    allowOnlySingleEntry: 'Hinzufügen von nur einer E-Mail-Adresse erlauben',
  },
  relation: {
    addBtnTooltip: 'Verbindung erstellen',
    blabReferencePlaceholder: 'Noch keine Verbindungen zu anderen Apps erstellt',
    blabViewNameFallback: {
      LIST: 'Liste',
      TABLE: 'Tabelle',
      BADGES: 'Gallerie',
      BOARD: 'Board',
    },
    blabViewNoneLabel: 'Keine Ansicht',
    defaultBlabView: 'Alle {{blabName}}',
    onlyAllowSingle: 'Verknüpfen mit nur einem Eintrag erlauben',
    removeBtnTooltip: 'App entfernen',
  },
  text: {
    defaultValue: 'Standardwert',
    defaultValuePlaceholder: 'Standardwert hinzufügen',
  },
  user: {
    doNotNotify: `Mitglied bei Hinzufügen nicht benachrichtigen`,
    onlyOneUser: 'Hinzufügen von nur einem Mitglied erlauben',
    showInFocus: 'In Fokus zeigen',
    showInFocusDisabledTooltip: 'Status Feld wird benötigt',
  },
  status: {
    requiredTooltip: 'Status Feld muss erforderlich sein',
    layoutSettings: 'Layout-Einstellungen',
    addBtnTooltip: 'Option hinzufügen',
    dropdown: 'Dropdown-Liste',
    inline: 'Reihe',
    list: 'Liste',
    optionDefLabelPlaceholder: 'Option hinzufügen',
    optionDefsPlaceholder: 'Noch keine Option hinzugefügt.',
    removeBtnTooltip: 'Option entfernen',
    lastMeansCompletedNotRemovableBtnTooltip: 'Letzter erledigt Status kann nicht gelöscht werden',
    lastMeansIncompleteNotRemovableBtnTooltip: 'Letzter unerledigt Status kann nicht gelöscht werden',
    conversionRemoveBtnDisabledTooltip:
      'Status kann nicht entfernt werden bevor die Feld Konvertierung abgeschlossen ist',
    selectColorBtnTooltip: 'Optionsfarbe wählen',
    moveTooltip: 'Bewegen',
    newOptionMeansCompletedLabel: 'Erledigt',
    newOptionMeansIncompleteLabel: 'Unerledigt',
    completed: 'Erledigt',
    incomplete: 'Unerledigt',
    completedLastOfItsKindTooltip: 'Letzter erledigt Status kann nicht geändert werden',
    incompleteLastOfItsKindTooltip: 'Letzter unerledigt Status kann nicht geändert werden',
  },
  uniqueId: {
    uniqueIdPrefixPlaceholder: 'Einmalige ID Präfix',
  },
  image: {
    layoutSettings: 'Layout-Einstellungen',
    layoutSizeSmall: 'Klein',
    layoutSizeMedium: 'Mittel',
    layoutSizeLarge: 'Groß',
  },
};
