/**Encode */
export type UserDndSetting = boolean | Date;

export enum SetUserDnDUntilOptions {
  THIRTY_MIN = 'THIRTY_MIN',
  ONE_HOUR = 'ONE_HOUR',
  UNTIL_TOMORROW = 'UNTIL_TOMORROW',
  UNTIL_NEXT_WEEK = 'UNTIL_NEXT_WEEK',
  INFINITE = 'INFINITE',
}

export enum SetUserStatusUntilOptions {
  'THIRTY_MIN' = 'THIRTY_MIN',
  'ONE_HOUR' = 'ONE_HOUR',
  'TODAY' = 'TODAY',
  'THIS_WEEK' = 'THIS_WEEK',
}

export type SetUserStatusUntil = null | SetUserStatusUntilOptions | Date;
