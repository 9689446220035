export const blabItemIncomingRelationEnLangClient = {
  addItem: 'Add {{itemName}}',
  goToRelatedItemsTooltip: 'Go to {{blabName}} app, filtered by related records',
  placeholder: {
    subtitle: 'When someone relates a record, it will show up here.',
    title: 'Nothing to see here yet',
  },
  relatedActivity: 'Related activity',
  relatedItems: 'Relations',
  showMore: 'Show more',
} as const;
