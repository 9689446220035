export const userToolbarDeLangClient = {
  activeUserMenu: {
    dnd: `Bitte nicht stören`,
    dndInfinite: `Pausiert bis Reaktivierung`,
    dndUntil: `Pausiert bis {{until}}`,
    editStatus: 'Status bearbeiten',
    logout: `Abmelden`,
    profile: `Profil`,
    set_incognito_off: `Setze dich auf aktiv`,
    set_incognito_on: `Setze dich auf abwesend`,
    settings: `Persönliche Einstellungen`,
    status: `Status einstellen`,
    trash: `Papierkorb`,
    mobileApp: 'Hol dir die mobile App',
  },
  buttonTooltips: {
    calendar: 'Kalender',
    chat: 'Chat',
    notifications: 'Benachrichtigungen',
    reminders: 'Erinnerungen',
  },
  help: {
    feedback: 'Funktionswunsch',
    helpCenter: 'Hilfe & Dokumentation',
    community: 'Frag die Community',
    sendMessage: 'Abrechnungssupport',
    whatsNew: `Neue Funktionen`,
    tour: 'Tour starten',
    roadmap: 'Roadmap',
    bugReport: 'Fehler melden',
  },
  helpBtnTooltip: 'Hilfe & Support',
};
