export const blabItemTableEnLangClient = {
  selectionMenu: {
    buttons: {
      delete: 'Delete',
    },
    confirmDeleteSelectedBlabItems: {
      confirmButtonLabel: `Delete`,
      message: `Are you sure you want to delete the selected records?`,
      title: `Delete selected records?`,
    },
    itemSelected: '{{itemName}} selected',
    itemsSelected: '{{blabName}} selected',
  },
  toolbar: {
    sort: {
      labelNoSort: 'Sort',
      label: '{{ numSorts }} Sort',
      clearBtnTooltip: 'Clear sort',
    },
    filters: {
      addBtnTooltip: 'Add filter',
      clearBtnTooltip: 'Clear filter',
      flimFilter: {
        MULTI_ATTACHMENT: {
          CONTAINS: 'Contains',
          EMPTY: 'Is empty',
          ONE: 'Includes',
          NONE: 'Does not include',
          NOT_CONTAINS: 'Does not contain',
          NOT_EMPTY: 'Is not empty',
          STARTS_WITH: 'Starts with',
          ENDS_WITH: 'Ends with',

          placeholder: 'Add value',
        },
        MULTI_CATEGORY: {
          ALL: 'Has all of',
          EMPTY: 'Is empty',
          EXACT: 'Is exactly',
          NONE: 'Has none of',
          NOT_EMPTY: 'Is not empty',
          ONE: 'Has any of',
        },
        MULTI_RELATION: {
          ALL: 'Has all of',
          EMPTY: 'Is empty',
          EXACT: 'Is exactly',
          NONE: 'Has none of',
          NOT_EMPTY: 'Is not empty',
          ONE: 'Has any of',
        },
        MULTI_TEXT: {
          CONTAINS: 'Contains',
          EMPTY: 'Is empty',
          IS: 'Is',
          IS_NOT: 'Is not',
          NOT_CONTAINS: 'Does not contain',
          NOT_EMPTY: 'Is not empty',
          STARTS_WITH: 'Starts with',
          ENDS_WITH: 'Ends with',

          placeholder: 'Add value',
        },
        MULTI_USER: {
          ALL: 'Has all of',
          EMPTY: 'Is empty',
          EXACT: 'Is exactly',
          NONE: 'Has none of',
          NOT_EMPTY: 'Is not empty',
          ONE: 'Has any of',
        },
        SINGLE_ATTACHMENT: {
          CONTAINS: 'Contains',
          EMPTY: 'Is empty',
          IS: 'Is',
          IS_NOT: 'Is not',
          NOT_CONTAINS: 'Does not contain',
          NOT_EMPTY: 'Is not empty',
          STARTS_WITH: 'Starts with',
          ENDS_WITH: 'Ends with',

          placeholder: 'Add value',
        },
        SINGLE_CATEGORY: {
          EMPTY: 'Is empty',
          IS: 'Is',
          IS_ANY: 'Is any',
          IS_NONE: 'Is none',
          IS_NOT: 'Is not',
          NOT_EMPTY: 'Is not empty',
        },
        SINGLE_RELATION: {
          EMPTY: 'Is empty',
          IS: 'Is',
          IS_ANY: 'Is any',
          IS_NONE: 'Is none',
          IS_NOT: 'Is not',
          NOT_EMPTY: 'Is not empty',
        },
        SINGLE_TEXT: {
          CONTAINS: 'Contains',
          EMPTY: 'Is empty',
          IS: 'Is',
          IS_NOT: 'Is not',
          NOT_CONTAINS: 'Does not contain',
          NOT_EMPTY: 'Is not empty',
          STARTS_WITH: 'Starts with',
          ENDS_WITH: 'Ends with',
          placeholder: 'Add value',
        },
        SINGLE_USER: {
          EMPTY: 'Is empty',
          IS: 'Is',
          IS_ANY: 'Is any',
          IS_NONE: 'Is none',
          IS_NOT: 'Is not',
          NOT_EMPTY: 'Is not empty',
        },
        STATUS: {
          EMPTY: 'Is empty',
          IS: 'Is',
          IS_ANY: 'Is any',
          IS_NONE: 'Is none',
          IS_NOT: 'Is not',
          NOT_EMPTY: 'Is not empty',
          COMPLETED: 'Complete',
          INCOMPLETE: 'Incomplete',
        },
        CALCULATION: {
          CONTAINS: 'Contains',
          NOT_CONTAINS: 'Does not contain',
          EQUAL: 'Is',
          NOT_EQUAL: 'Is not',
          NOT_EMPTY: 'Is not empty',
          EMPTY: 'Is empty',
          STARTS_WITH: 'Starts with',
          ENDS_WITH: 'Ends with',

          // number: TODO:
          SMALLER: '',
          SMALLER_OR_EQUAL: '',
          LARGER: '',
          LARGER_OR_EQUAL: '',

          // date: TODO:
          IS_BEFORE: '',
          IS_AFTER: '',
          IS_WITHIN: '',
          IS_ON_OR_BEFORE: '',
          IS_ON_OR_AFTER: '',

          placeholder: 'Add value',
        },
      },
      inputDisabledPlaceholder: 'Disabled',
      many: '{{numFilters}} filters / {{numResults}} of {{numTotal}}',
      one: '1 filter / {{numResults}} of {{numTotal}}',
      placeholder: {
        noFilterableFlimDefs: 'No fields found to filter by',
        text: 'No filters applied to this view',
      },
      prefix: {
        and: 'And',
        where: 'Where',
      },
      removeFilterBtnTooltip: 'Remove filter',
      title: 'Filter',
      zero: 'Filter / {{numResults}} of {{numTotal}}',
    },
    hiddenColumns: {
      all: 'All columns',
      clearBtnTooltip: 'Clear customize',
      many: '{{numHiddenColumns}} hidden columns',
      menuTitle: 'Columns',
      one: '1 hidden column',
      zero: 'Columns',
    },
    options: {
      many: '{{numUnsaved}} unsaved changes',
      one: '1 unsaved change',
      optionsBtnTooltip: 'View options',
      saveCurrentBtnTooltip: 'Save to this view',
      saveNewBtnTooltip: 'Save as new view',
    },
    rowHeight: {
      EXTRA_TALL: `Extra tall`,
      MEDIUM: `Medium`,
      SHORT: `Small`,
      TALL: `Tall`,
    },
    sortings: {
      many: '{{numSorts}} sortings',
      one: '1 sorting',
      zero: 'Sort',
    },
  },
} as const;
