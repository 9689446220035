export const blabItemNotificationLangEnClient = {
  // BlabItemActivityReply
  blabItemActivityReplyToActiveUserComment: '<b>{{authorName}}</b> replied to your comment on {{blabItemTitle}}',
  blabItemActivityReplyToActiveUserCommentByAutomation:
    '<b>Automation</b> replied to your comment on {{blabItemTitle}}',
  blabItemActivityReplyToActiveUserCommentMention:
    '<b>{{authorName}}</b> mentioned you in a reply to your comment on {{blabItemTitle}}',
  blabItemActivityReplyToActiveUserCommentMentionByAutomation:
    '<b>Automation</b> mentioned you in a reply to your comment on {{blabItemTitle}}',
  blabItemActivityReplyToActiveUserUpdate: '<b>{{authorName}}</b> replied to your update on {{blabItemTitle}}',
  blabItemActivityReplyToActiveUserUpdateByAutomation: '<b>Automation</b> replied to your update on {{blabItemTitle}}',
  blabItemActivityReplyToActiveUserUpdateMention:
    '<b>{{authorName}}</b> mentioned you in a reply to your update on {{blabItemTitle}}',
  blabItemActivityReplyToActiveUserUpdateMentionByAutomation:
    '<b>Automation</b> mentioned you in a reply to your update on {{blabItemTitle}}',
  blabItemActivityReplyToComment: '<b>{{authorName}}</b> replied to a comment on {{blabItemTitle}}',
  blabItemActivityReplyToCommentByAutomation: '<b>Automation</b> replied to a comment on {{blabItemTitle}}',
  blabItemActivityReplyToCommentMention:
    '<b>{{authorName}}</b> mentioned you in a reply to a comment on {{blabItemTitle}}',
  blabItemActivityReplyToCommentMentionByAutomation:
    '<b>Automation</b> mentioned you in a reply to a comment on {{blabItemTitle}}',
  blabItemActivityReplyToUpdate: '<b>{{authorName}}</b> replied to an update on {{blabItemTitle}}',
  blabItemActivityReplyToUpdateByAutomation: '<b>Automation</b> replied to an update on {{blabItemTitle}}',
  blabItemActivityReplyToUpdateMention:
    '<b>{{authorName}}</b> mentioned you in a reply to an update on {{blabItemTitle}}',
  blabItemActivityReplyToUpdateMentionByAutomation:
    '<b>Automation</b> mentioned you in a reply to an update on {{blabItemTitle}}',

  // BlabItemCommentActivity
  blabItemComment: '<b>{{authorName}}</b> commented on {{blabItemTitle}}',
  blabItemCommentByAutomation: '<b>Automation</b> commented on {{blabItemTitle}}',
  blabItemCommentMention: '<b>{{authorName}}</b> mentioned you in a comment on {{blabItemTitle}}',
  blabItemCommentMentionByAutomation: '<b>Automation</b> mentioned you in a comment on {{blabItemTitle}}',
  // BlabItemCreationActivity
  blabItemCreated: '<b>{{authorName}}</b> created {{blabItemTitle}}',
  blabItemCreatedViaPublicInboundEmail: '<b>Email</b> created {{blabItemTitle}}',
  blabItemCreatedByAutomation: '<b>Automation</b> created {{blabItemTitle}}',
  // BlabItemDeletionActivity
  blabItemDeleted: '<b>{{authorName}}</b> deleted {{blabItemTitle}}',
  blabItemDeletedByAutomation: '<b>Automation</b> deleted {{blabItemTitle}}',
  // BlabItemDeletionActivity
  blabItemRestored: '<b>{{authorName}}</b> restored {{blabItemTitle}}',
  blabItemRestoredByAutomation: '<b>Automation</b> restored {{blabItemTitle}}',
  // BlabItemInvitationCreatedActivity
  blabItemInvitation: '<b>{{authorName}}</b> invited you to {{blabItemTitle}}',
  blabItemInvitationByAutomation: '<b>Automation</b> invited you to {{blabItemTitle}}',
  // BlabItemRevisionActivity
  blabItemRevision: '<b>{{authorName}}</b> updated {{blabItemTitle}}',
  blabItemRevisionByAutomation: '<b>Automation</b> updated {{blabItemTitle}}',
  // Title calculation
  blabItemTitle: '{{blabDefName}} <b>{{blabItemTitle}}</b>',
  // ## FlimValAttachment
  flimValAttachmentCreated: '<b>{{authorName}}</b> added file "{{attachmentName}}" on {{blabItemTitle}}',
  flimValAttachmentDeleted: '<b>{{authorName}}</b> removed file "{{prevAttachmentName}}" on {{blabItemTitle}}',
  flimValAttachmentReplaced:
    '<b>{{authorName}}</b> replaced file "{{prevAttachmentName}}" with "{{attachmentName}}" on {{blabItemTitle}}',
  flimValAttachmentsCreated: '<b>{{authorName}}</b> added files "{{attachmentNames}}" on {{blabItemTitle}}',
  // ## Checklist FlimVal
  flimValChecklistActiveUserAdded:
    '<b>{{authorName}}</b> assigned <b>{{checklistEntryName}}</b> to you in {{flimDefName}} on {{blabItemTitle}}',
  flimValChecklistActiveUserRemoved:
    '<b>{{authorName}}</b> unassigned <b>{{checklistEntryName}}</b> from you in {{flimDefName}} on {{blabItemTitle}}',
  flimValChecklistCompleted:
    '<b>{{authorName}}</b> marked <b>{{checklistEntryName}}</b> as completed on {{blabItemTitle}}',
  flimValChecklistIncompleted:
    '<b>{{authorName}}</b> marked <b>{{checklistEntryName}}</b> as incomplete on {{blabItemTitle}}',

  flimValChecklistTitlePlaceholder: 'Untitled',
  // ## User FlimVal
  flimValUserActiveUserAdded: '<b>{{authorName}}</b> added you as {{flimDefName}} on {{blabItemTitle}}',
  flimValUserActiveUserRemoved: '<b>{{authorName}}</b> removed you as {{flimDefName}} on {{blabItemTitle}}',
  untitledBlabItemTitle: '<b>New {{blabDef}}</b>',
} as const;
