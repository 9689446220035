export const workspaceDeLangClient = {
  confirmDeleteWorkspace: {
    confirmButtonLabel: `Löschen`,
    message: `Bist du sicher, dass du diesen Workspace löschen möchtest? Dies kann nicht rückgängig gemacht werden.`,
    title: `Workspace löschen?`,
  },
  confirmLeaveWorkspace: {
    confirmButtonLabel: `Verlassen`,
    message: `Bist du sicher, dass du diesen Workspace verlassen möchtest?`,
    title: `Workspace verlassen?`,
  },
  leaveWorkspacRejectionToast: 'Bitte gib einer anderen Person Vollzugriff, bevor du diesen Workspace verlässt.',
  workspaceMenu: {
    addPeople: `Personen hinzufügen`,
    archive: `Archivieren`,
    clone: `Workspace duplizieren`,
    delete: `Workspace löschen`,
    leave: `Workspace verlassen`,
    manageBlabDefs: `Apps verwalten`,
    manageMembers: `Mitglieder verwalten`,
    publishTemplate: `In Vorlagen veröffentlichen`,
    publishUpdateTemplate: `Update veröffentlichen`,
    removeTemplatePublication: `Aus Vorlagen entfernen`,
    viewMembers: `Mitglieder anzeigen`,
    workspacePage: `Workspace Seite`,
    workspaceSettings: `Workspace-Einstellungen`,
    workflowCenterButton: `Automatisierungen`,
    new: `Neu`,
    addDescription: 'Beschreibung hinzufügen',
    editDescription: 'Beschreibung bearbeiten',
  },
} as const;
