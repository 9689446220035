export const userProfileDeLangClient = {
  activity: 'Beiträge',
  backBtnTooltip: 'Zurück',
  channels: 'Channels',
  confirmDeleteUserPost: {
    confirmButtonLabel: `Löschen`,
    message: `Dies kann nicht rückgängig gemacht werden und Antworten auf diesen Beitrag werden ebenfalls entfernt.`,
    title: `Beitrag löschen?`,
  },
  confirmDeleteUserPostReply: {
    confirmButtonLabel: `Löschen`,
    message: `Bist du sicher, dass du diese Antwort löschen möchtest? Dies kann nicht rückgängig gemacht werden.`,
    title: `Antwort löschen?`,
  },
  editProfileBtn: 'Profil bearbeiten',
  followers: 'Follower',
  following: 'Folge ich',
  postPlaceholder: 'Was gibt’s Neues?',
  relatedItems: 'Verbindungen',
  replyPlaceholder: 'Neue Antwort beginnen',
  setAStatus: 'Status',
} as const;
