export const userInputDeLangClient = {
  multiUserInput: {
    placeholder: 'Personen auswählen',
    searchPlaceholder: 'Nach Personen suchen',
    activeUser: 'Ich selbst',
  },
  singleUserInput: {
    placeholder: 'Person auswählen',
    searchPlaceholder: 'Nach Person suchen',
    activeUser: 'Ich selbst',
  },
};
