import { createFeatureSelector, createSelector } from '@ngrx/store';
import { I18nSettings } from '@t5s/shared/gql';
import { internationalizationStateKey } from './internationalization.app-state';
import { InternationalizationState } from './internationalization.reducer';

const selectInternationalizationFeatureState =
  createFeatureSelector<InternationalizationState>(internationalizationStateKey);

export const selectI18n = createSelector(selectInternationalizationFeatureState, (state) => state as I18nSettings);
export const selectDecimalFormat = createSelector(
  selectInternationalizationFeatureState,
  (state) => state.decimalFormat,
);
