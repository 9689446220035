export const signupDeLangClient = {
  continue: 'Weiter',
  enterYourOrgUrl: 'Gib die URL deiner Organisation ein',
  errors: {
    orgSlugNoExist: 'Wir konnten deine Organisation nicht finden',
    orgSlugNone: 'Bitte gib eine Organisation-URL ein',
  },
  forgotOrganizationUrl: 'Organisations-URL vergessen?',
  register: 'Neue Tape-Organisation erstellen',
  signInToYourOrganization: 'In deiner Tape-Organisation anmelden',
  yourOrganizationUrl: 'deine-organisations-url',
} as const;
