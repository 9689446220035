export const editProfileEnLangClient = {
  birthdate: `Birth date`,
  changeEmailLink: `Change email address`,
  email: `Email address`,
  fullName: 'Full name',
  fullNameErrorNone: 'Full name is required',
  jobDescription: `Your job`,
  location: `Location`,
  phoneNumber: `Phone number`,
  placeholder: {
    birthdate: `Add birth date`,
    email: `Add email address`,
    fullName: `Add full name`,
    jobDescription: `Add your job description`,
    location: `Add location`,
    phoneNumber: `Add phone number`,
    shortname: `Add display name`,
    timezone: `Add time zone`,
    website: `Add your website`,
  },
  saveBtn: `Save`,
  shortname: `Display name`,
  timezone: `Time zone`,
  title: `Edit profile`,
  uploadImageBtnTooltip: 'Add photo',
  website: `Website`,
} as const;
