import { DateFormat } from '@t5s/shared/gql';
import { getDateFromInputStr } from './date-input.utils';

export function getDaterangeFromInputStr(
  input: string | undefined,
  { dateFormat, referenceDate }: { dateFormat?: DateFormat; referenceDate?: Date } = {},
): { startDate?: Date; endDate?: Date } | undefined {
  input = input || '';
  const [dateStr] = input.split('-');

  const startDate = getDateFromInputStr(dateStr, { dateFormat, referenceDate });

  if (!startDate) {
    return undefined;
  }

  const endDateStr = input.slice(dateStr.length);
  const endDate = getDateFromInputStr(endDateStr, { dateFormat });

  return {
    startDate,
    endDate,
  };
}
