export const organizationSetupDeLangClient = {
  intentions: {
    continue: 'Weiter',
    intentions: {
      TASK_MANAGEMENT: 'Aufgabenmanagement',
      MARKETING: 'Marketing',
      PROJECT_MANAGEMENT: 'Projektmanagement',
      DEVELOPMENT: 'Software-Entwicklung',
      STARTUP: 'Startup',
      SALES: 'Vertrieb',
      CUSTOMER_DEVELOPMENT: 'Kundenentwicklung',
    },
    section: {
      crm: 'Kundenbeziehungsmanagement',
      finance: 'Finanzwesen',
      hr: 'Personalmanagement',
      inventory: 'Inventarverwaltung',
      knowledge: 'Wissensmanagement',
      pm: 'Projektmanagement',
    },
    skip: 'Vorerst überspringen',
    subtitle:
      'Wir verwenden deine Auswahl, um dir den Start in Tape zu erleichtern. Du kannst die Auswahl jederzeit leicht wieder ändern.',
    title: 'Wofür möchtest du Tape zuerst nutzen?',
  },
  invitation: {
    continue: 'Einladen',
    label: 'Personen einladen',
    description:
      'Gib oder füge eine oder mehrere E-Mail-Adressen ein, getrennt durch Kommas, Leerzeichen oder Zeilenumbrüche.',
    searchPlaceholder: 'paula@meinteam.de, jan@firma.de, …',
    skip: 'Vorerst überspringen',
    subtitle: 'Tape funktioniert am besten mit deinen Teammitgliedern',
    title: 'Starte mit deinem Team',
  },
};
