export const userSetupDeLangClient = {
  notification: {
    allow: 'Jetzt loslegen',
    skip: 'Vorerst überspringen',
    subtitle: `Wir freuen uns, dass du hier bist`,
    title: 'Willkommen bei Tape',
  },
  profile: {
    continue: 'Weiter',
    skip: 'Vorerst überspringen',
    subtitle: 'So wirst du in Tape angezeigt',
    title: 'Wähle dein Profilbild aus',
  },
};
