export enum ActiveActorType {
  // Active user
  USER = 'USER',

  // Active sharelink
  ORGANIZATION_SHARELINK = 'ORGANIZATION_SHARELINK',
  WORKSPACE_SHARELINK = 'WORKSPACE_SHARELINK',
  BLAB_DEF_SHARELINK = 'BLAB_DEF_SHARELINK',
  BLAB_ITEM_SHARELINK = 'BLAB_ITEM_SHARELINK',
}
