export const signupEnLangClient = {
  continue: 'Next',
  enterYourOrgUrl: `Enter your organization's URL`,
  errors: {
    orgSlugNoExist: 'We couldn’t find your organization',
    orgSlugNone: 'Please enter an organization URL',
  },
  forgotOrganizationUrl: `Don't know your organization URL?`,
  register: 'Create a new Tape organization',
  signInToYourOrganization: 'Sign in to your Tape organization',
  yourOrganizationUrl: 'your-organization-url',
} as const;
