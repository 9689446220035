import { NgModule, Provider } from '@angular/core';
import { ClientProviderWindowModule } from '@t5s/client/provider/window';
import { KeyboardService } from './keyboard/keyboard.service';

const browserServices: Provider[] = [KeyboardService];

@NgModule({
  imports: [ClientProviderWindowModule],
  providers: [...browserServices],
})
export class ClientServiceBrowserModule {}
