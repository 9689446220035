export const editProfileDeLangClient = {
  birthdate: `Geburtsdatum`,
  changeEmailLink: `E-Mail-Adresse ändern`,
  email: `E-Mail-Adresse`,
  fullName: 'Vollständiger Name',
  fullNameErrorNone: 'Vollständiger Name ist erforderlich',
  jobDescription: `Mein Job`,
  location: `Standort`,
  phoneNumber: `Telefonnummer`,
  placeholder: {
    birthdate: `Geburtsdatum hinzufügen`,
    email: `E-Mail-Adresse hinzufügen`,
    fullName: `Vollständigen Namen hinzufügen`,
    jobDescription: `Jobbeschreibung hinzufügen`,
    location: `Standort hinzufügen`,
    phoneNumber: `Telefonnummer hinzufügen`,
    shortname: `Anzeigename hinzufügen`,
    timezone: `Zeitzone hinzufügen`,
    website: `Webseite hinzufügen`,
  },
  saveBtn: `Speichern`,
  shortname: `Anzeigename`,
  timezone: `Zeitzone`,
  title: `Profil bearbeiten`,
  uploadImageBtnTooltip: 'Foto hinzufügen',
  website: `Webseite`,
} as const;
